import angular from 'angular';
import app from '../../app';
import initializeAnalytics from '../../utilities/analytics/initialize';

angular.module(app).config(function ($analyticsProvider, $windowProvider, LoggerProvider) {
  const $window = $windowProvider.$get();
  const Logger = LoggerProvider.$get();

  // Exclude any search routes from tracking since we don't want to track who a provider is searching for
  $analyticsProvider.excludeRoutes(['/search']);

  const analytics = initializeAnalytics($window, Logger);

  $analyticsProvider.registerPageTrack(analytics.page);
  $analyticsProvider.registerEventTrack(analytics.track);
  $analyticsProvider.registerSetUserProperties(analytics.identify);
  $analyticsProvider.registerSetUserPropertiesOnce(analytics.setUserId);
  $analyticsProvider.registerSetAlias(analytics.setUserId);
  $analyticsProvider.registerSetSuperProperties(analytics.setSuperProperties);
  $analyticsProvider.registerExceptionTrack(analytics.trackException);
  $analyticsProvider.registerClearCookies(function () {
    Logger.debug('Analytics: clearCookies');
  });
});
