import angular from 'angular';
import find from 'lodash/find';

import app from '../../app';

import './settings-password-and-account.less';

angular
  .module(app)
  .controller('SettingsPasswordAndAccountCtrl', function(
    $scope,
    $translatePartialLoader,
    User,
    $timeout
  ) {
    $translatePartialLoader.addPart('views/settings-password-and-account');

    $scope.newPassword = '';
    $scope.confirmNewPassword = '';
    $scope.passwordStrength = -1;
    $scope.passwordStrengthMessage = '';
    $scope.validNewPassword = false;
    $scope.newPasswordErrors = false;
    $scope.res = '';
    $scope.resMsg = '';
    $scope.confirmNewPasswordDoesNotMatchNewPassword = false;
    $scope.minEightCharacters = false;
    $scope.number = false;
    $scope.upperCase = false;
    $scope.lowerCase = false;

    $scope.checkNewPasswordStrength = function() {
      if ($scope.newPassword.length === 0 || $scope.newPassword === '') {
        $scope.passwordStrength = -1;
        $scope.passwordStrengthMessage = '';
      } else {
        // calculate strength of password
        // more information about zxcvbn: https://blogs.dropbox.com/tech/2012/04/zxcvbn-realistic-password-strength-estimation/
        import('zxcvbn')
          .then(({ default: zxcvbn }) => {
            $scope.passwordStrength = zxcvbn($scope.newPassword).score;

            switch ($scope.passwordStrength) {
              case 0: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.WEAK';
                break;
              }
              case 1: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.SO-SO';
                break;
              }
              case 2: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.GOOD';
                break;
              }
              case 3: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.STRONG';
                break;
              }
              case 4: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.VERY-STRONG';
                break;
              }
              default: {
                $scope.passwordStrengthMessage =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-STRENGTH.WEAK';
                break;
              }
            }
          });
      }
    };

    $scope.submitNewPassword = function() {
      $scope.validNewPassword = false;
      $scope.newPasswordErrors = false;
      $scope.resMsg = '';

      // our requirements:
      $scope.minEightCharacters = false;
      $scope.number = false;
      $scope.upperCase = false;
      $scope.lowerCase = false;
      $scope.confirmNewPasswordDoesNotMatchNewPassword = false;

      // - min 8 characters long
      if ($scope.newPassword.length >= 8) {
        $scope.minEightCharacters = true;
      }
      // - number
      if ($scope.newPassword.match(/\d/)) {
        $scope.number = true;
      }

      // - upper case
      if ($scope.newPassword.match(/[A-Z]/)) {
        $scope.upperCase = true;
      }

      // - lower case
      if ($scope.newPassword.match(/[a-z]/)) {
        $scope.lowerCase = true;
      }

      if ($scope.minEightCharacters && $scope.number && $scope.upperCase && $scope.lowerCase) {
        $scope.validNewPassword = true;

        if ($scope.newPassword !== $scope.confirmNewPassword) {
          // verify that the two passwords are the same
          $scope.newPasswordErrors = true;
          $scope.confirmNewPasswordDoesNotMatchNewPassword = true;
        }
      } else {
        $scope.newPasswordErrors = true;
      }

      if ($scope.validNewPassword && !$scope.confirmNewPasswordDoesNotMatchNewPassword) {
        // if valid password and if both new password and confirm password matches then send
        User.updatePassword({
          password: $scope.newPassword
        })
          .then(function(res) {
            $scope.newPassword = '';
            $scope.confirmNewPassword = '';
            $scope.passwordStrength = -1;
            $scope.passwordStrengthMessage = '';

            $scope.res = 'success';

            $scope.resMsg =
              'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-PASSWORD-CHANGE-SUCCESSFUL';
          })
          .then(function() {
            if ($scope.res === 'success') {
              return $timeout(User.signOut, 1500);
            }

            return null;
          })
          .catch(function(res) {
            $scope.res = 'error';

            switch (res.status) {
              case 400:
                // very very ugly
                $scope.resMsg =
                  // eslint-disable-next-line prefer-template
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-PASSWORD-CHANGE-ERROR-DESCRIPTION.' +
                    find(res.data.elements, 'id').id ||
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-PASSWORD-CHANGE-ERROR-500';
                break;
              case 500:
              default:
                $scope.resMsg =
                  'VIEW-SETTINGS-PASSWORD-AND-ACCOUNT-PASSWORD-PASSWORD-CHANGE-ERROR-500';
                break;
            }
          });
      }
    };

    const input = document.getElementById('password-and-account-settings-new-password');

    if (input) {
      input.focus();
    }
  });
