import angular from 'angular';
import app from '../../app';

import template from './row-header.html';
import './styles.less';

angular.module(app).component('rowHeader', {
  template,
  transclude: {
    subtitle: '?subTitle'
  },
  bindings: {
    value: '@',
    mainTitle: '@',
    condensedTitle: '@',
    subtitleText: '@',
    sortText: '@',
    currentSort: '<',
    sortOrder: '<',
    analyticsView: '@',
    onClick: '&',
    onHover: '&',
    showSortTooltip: '<',
    sortable: '<'
  },
  controller: function RowHeaderController() {
    const ctrl = this;

    ctrl.showSortTooltip = false;

    ctrl.setSort = function (option) {
      if (!ctrl.sortable) return;

      ctrl.onClick({ value: option });
    };

    ctrl.toggleSortTooltip = function (bool) {
      if (!ctrl.sortable) return;

      ctrl.showSortTooltip = bool;
    };

    ctrl.$onInit = function init() {
      // this will default to `true` if not explicitly `false`
      ctrl.sortable = !(ctrl.sortable === false);
    };
  }
});
