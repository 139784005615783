import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import Pseudo from 'i18next-pseudo';
import { defaultLocale, options, LOCALE_FALLBACKS } from './services/locales';

const isDev = process.env.NODE_ENV === 'development';

// i18next uses a different fallback strategy than what we use within angular
const fallbacks = Object.keys(LOCALE_FALLBACKS).reduce(
  (obj, key) => {
    obj[key] = [LOCALE_FALLBACKS[key], defaultLocale];

    return obj;
  },
  {
    default: [defaultLocale]
  }
);

// No language detection right now, because the react code is only available when
// someone is signed in, so we have their locale preference
i18n
  .use(Backend)
  .use(
    new Pseudo({
      languageToPseudo: defaultLocale,
      enabled: false /* isDev */
    })
  )
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    lng: defaultLocale,
    fallbackLng: fallbacks,
    supportedLngs: Object.keys(options),
    load: 'currentOnly',
    ns: 'common',
    defaultNS: 'common',
    debug: isDev,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ['b', 'br', 'strong', 'i', 'p']
    },
    postProcess: ['pseudo']
  });

export default i18n;
