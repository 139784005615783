/* eslint-disable import/prefer-default-export */
// implementation based on https://github.com/onury/custom-error-test/blob/master/the-one/CustomError.js
import { setProto, defineDefaultProperties, definePrototypeChain } from './errorUtilities';
import { isFetchResponse, isResponseObject, responseForLog } from '../index';

function formatResponse(aResponse) {
  if (isResponseObject(aResponse) || isFetchResponse(aResponse)) {
    return responseForLog(aResponse);
  }

  return null;
}

export function HttpRequestError(message, response) {
  let err;

  if (setProto) {
    err = new Error(message);
    setProto(err, HttpRequestError.prototype);
  } else {
    err = this;
  }

  Object.defineProperty(err, 'response', {
    enumerable: false,
    writable: false,
    value:  formatResponse(response)
  });

  return defineDefaultProperties(err, message, HttpRequestError, 'HttpRequestError');
}

definePrototypeChain(HttpRequestError);
