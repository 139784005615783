import { func, node, string } from 'prop-types';
import React, { useCallback } from 'react';
import analytics from '../../utilities/analytics';

function Link({ href, role, onClick, analyticsPage, analyticsLabel, children, ...rest }) {
  if (!href) {
    href = '#';
    role = role || 'button';
  }

  const handleClick = useCallback(
    (e) => {
      // TODO inspect attributes to see if we need to prevent default?
      // eg. if offsite and not target = '_blank'

      if (analyticsPage && analyticsLabel) {
        analytics.track('click', { pageId: analyticsPage, labelId: analyticsLabel });
      }

      if (onClick) {
        onClick(e);
      }
    },
    [analyticsPage, analyticsLabel, onClick]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={href} onClick={handleClick} role={role} {...rest}>
      {children}
    </a>
  );
}

Link.propTypes = {
  href: string,
  role: string,
  onClick: func,
  analyticsPage: string.isRequired,
  analyticsLabel: string.isRequired,
  children: node.isRequired
};

Link.defaultProps = {
  href: null,
  role: null,
  onClick: null
};

export default Link;
