/* eslint-disable default-param-last */
import {
  RESET_ENROLLMENT_DATA,
  RESET_ENROLLMENT_PATIENT,
  UPDATE_CAT_RESPONSE,
  UPDATE_ADULT_ACT_RESPONSE,
  UPDATE_CHILD_ACT_RESPONSE,
  RESET_QUIZ
} from '../../actions';

function updateAnswer(state, value, index) {
  state[index] = value;

  return state;
}

export function copdAssessment(state = [], action) {
  switch (action.type) {
    case UPDATE_CAT_RESPONSE:
      return [].concat(updateAnswer(state, action.value, action.index));

    case RESET_QUIZ:
    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return [];

    default:
      return state;
  }
}

export function adultAsthmaAssessment(state = [], action) {
  switch (action.type) {
    case UPDATE_ADULT_ACT_RESPONSE:
      return [].concat(updateAnswer(state, action.value, action.index));

    case RESET_QUIZ:
    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return [];

    default:
      return state;
  }
}

// could we use the birthDate state to add another case here in asthmaAssessment?
export function childAsthmaAssessment(state = [], action) {
  switch (action.type) {
    case UPDATE_CHILD_ACT_RESPONSE:
      return [].concat(updateAnswer(state, action.value, action.index));

    case RESET_QUIZ:
    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return [];

    default:
      return state;
  }
}
