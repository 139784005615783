import angular from 'angular';
import app from '../../app';
import moment from '../../services/moment';

import template from './notification-item.html';
import './notification-item.less';

angular
  .module(app)
  .directive('notificationItem', function(
    $rootScope,
    $timeout,
    Notifications,
    $translatePartialLoader
  ) {
    return {
      template,
      restrict: 'EA',
      link(scope, $el, attrs) {
        $translatePartialLoader.addPart('directives/notification-item');
        const notification = scope.notification || { alert: {}, user: {} };
        const extraProperties = Notifications.extraNotificationProperties(notification);

        const defaultSuccess = function() {
          $el.addClass('remove');

          $timeout(() => {
            if (scope.notification.arrivedToday) {
              $rootScope.$broadcast('updateNewNotificationNumber');
            }

            $rootScope.$broadcast('updateNotificationNumber');
            $rootScope.$broadcast('removeNotificationFromIndex', {
              id: attrs.id
            });
          }, 300);
        };

        const defaultError = function() {
          $el.addClass('shake');

          $timeout(() => {
            $el.removeClass('shake');
          }, 600);
        };

        if (extraProperties.arrivedToday) {
          scope.notification.arrivedToday = extraProperties.arrivedToday; // --> For ring around today's notifications
          scope.notification.date = extraProperties.dateArrivedMessage;
          scope.notification.notificationTimeDiff = extraProperties.notificationTimeDiff;
        } else if (extraProperties.dateArrivedMessage) {
          scope.notification.date = extraProperties.dateArrivedMessage;
        } else {
          const date = moment(notification.createD, 'MM/DD/YYYY');
          const today = moment();

          scope.notification.date =
            date.year() === today.year() ? date.format('DD MMM') : date.format('DD MMM YYYY');
        }

        if (!notification.alert.deleted && !notification.alert.acknowledged) {
          scope.notification.alertStatus = 'active';
        } else if (notification.alert.deleted && !notification.alert.acknowledged) {
          scope.notification.alertStatus = 'resolved';
        } else if (!notification.alert.deleted && notification.alert.acknowledged) {
          scope.notification.alertStatus = 'dismissed';
        }

        scope.patient = notification.user;
        scope.patient.patientId = notification.alert.userId; // hack to gracefully load patient detail
        scope.notification.user.phon = notification.user.phon || false;
        scope.notification.user.email = notification.user.email || false;
        scope.imageUrl = extraProperties.imageUrl;
        scope.notification.formattedMessage = extraProperties.message;
        scope.notification.custom = extraProperties.custom;

        // Moves alert from resolved to active
        scope.activateAlert = function(alertId) {
          Notifications.activateAlert({ alertId })
            .then(defaultSuccess)
            .catch(defaultError);
        };

        // Moves alert from active to resolved.
        scope.resolveAlert = function(alertId) {
          if (!alertId) {
            return;
          }
          // var alertId = [alertId]; //the api expects an array of ids
          Notifications.deleteAlert({ alertId })
            .then(defaultSuccess)
            .catch(defaultError);
        };
      }
    };
  });
