import injectAngularService from '../../utilities/injectAngularService';
import logger from '../../utilities/logger';
import { makeActionCreator } from '../utilities';
import { normalizeGroupConfig } from '../../services/Group/formatGroupConfigParams';

export const SET_GROUP_CONFIG = 'SET_GROUP_CONFIG';
export const SET_GROUP_CONFIG_4X = 'SET_GROUP_CONFIG_4X';
export const SET_GROUP_CLINICIANS = 'SET_GROUP_CLINICIANS';
export const SET_SUBGROUPS = 'GROUPS:SET_SUBGROUPS';

export const setGroupConfig = makeActionCreator(SET_GROUP_CONFIG, 'groupConfig');
export const setGroupConfig4x = makeActionCreator(SET_GROUP_CONFIG_4X, 'config');
export const setGroupClinicians = makeActionCreator(SET_GROUP_CLINICIANS, 'clinicians');
export const setSubgroups = makeActionCreator(SET_SUBGROUPS, 'subgroups');

// could take groupService as an arg, and call the groupService method internally
// then we could pass something in in order to test (because injectAngularService
// isn't testable & is here called internally); see getNotificationsStatistics
export function loadGroupConfig(groupId) {
  return function (dispatch) {
    return injectAngularService('Group')
      .setDefaultGroup(groupId)
      .then((config) => {
        dispatch(setGroupConfig(config));
        return config;
      })
      .catch((e) => logger.error('Error loading 3x group config', e));
  };
}

export function loadGroupConfig4x(groupId, locale, queryParams) {
  return function (dispatch) {
    return injectAngularService('Group')
      .getGroup4x(groupId, locale, queryParams)
      .then((config) => {
        const groupConfig = normalizeGroupConfig(queryParams, config);
        dispatch(setGroupConfig4x(groupConfig));
        return groupConfig;
      })
      .catch((e) => logger.error('Error loading 4x group config', e));
  };
}

export function loadSubGroups4x(groupId) {
  return function (dispatch) {
    return injectAngularService('Group')
      .getSubGroups4x(groupId)
      .then((subgroups) => {
        dispatch(setSubgroups(subgroups));
        return subgroups;
      })
      .catch((e) => {
        logger.error('Error loading subgroups', e);
      });
  };
}

export function loadGroupClinicians(group, GroupService) {
  return function (dispatch) {
    const Group = GroupService || injectAngularService('Group');

    return Group.getGroupPhysicians(group)
      .then((clinicians) => {
        dispatch(setGroupClinicians(clinicians));
        return clinicians;
      })
      .catch((e) => logger.error('Errr loading group clinicians', e));
  };
}
