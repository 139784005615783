import angular from 'angular';
import app from '../../app';
import { keyName } from '../../utilities/storage/initStorage';

// const THIRTY_MINUTES = 1000 * 60 * 30;
const FIFTEEN_MINUTES = 1000 * 60 * 15;

// this should be initialized at the root of any app space,
// eg. the main controller or anything else cordoned off from it.
// Here we set up timers and how we want to handle session expiration.
// In the 'main' app we sign the user out of keycloak upon expiration.
// In EHR implementations we want to refresh their session to keep them
// signed in (to both API 3 and API 4)
angular.module(app).service('SessionManager', [
  '$rootScope',
  '$timeout',
  '$window',
  'AuthState',
  'Logger',
  function($rootScope, $timeout, $window, AuthState, Logger) {
    let initialized = false;
    let timer;

    // we need to track this in memory to know whether this window
    // was the source of the storage event and prevent an infinite loop
    let sessionExpiration;

    function createLogoutTimeout(logoutAt) {
      const now = Date.now();

      // logoutAt comes from the storage listener.
      // If we have it, then it's already been set in storage and we don't need to store it.
      // If we don't have it, calculate it and store it.
      if (typeof logoutAt !== 'number') {
        // eslint-disable-next-line no-underscore-dangle
        logoutAt = now + ($window.__DEBUG_TIMEOUT__ || FIFTEEN_MINUTES);
        AuthState.expiration = logoutAt;
      }

      sessionExpiration = logoutAt;

      // if the logoutAt is before now, 0, otherwise the number of MS from now
      // this basically keeps us from having a negative delay if 0 gets passed in
      const delay = Math.max(0, logoutAt - now);

      Logger.debug('SessionManager: Create logout timeout', { delay, logoutAt });

      return $timeout(function handleLogoutTimeout() {
        Logger.debug('SessionManager: Session expired');
        AuthState.expiration = 0;
        $timeout(() => {
          $rootScope.$broadcast('sessionInvalid');
        });
      }, delay);
    }

    $timeout(() => {
      Logger.debug('SessionManager: Checking SessionManager for initialization');
      if (!initialized) {
        Logger.error(
          'SessionManager: Has not been initialized within 30 seconds of Angular bootstrapping. Either something is very slow or you have forgotten this important step.'
        );
      }
    }, 1000 * 30);

    return {
      initialize(onExpire) {
        Logger.debug('SessionManager: Initialize');

        if (initialized) {
          Logger.warn('SessionManager: already initialized');
          return;
        }

        if (typeof onExpire !== 'function') {
          throw new Error(
            'SessionManager.initialize requires a function to be called upon expiration'
          );
        }

        initialized = true;
        timer = createLogoutTimeout();

        $rootScope.$on('sessionInvalid', onExpire);

        $rootScope.$on('resetSessionExpiration', (_, newTime) => {
          Logger.debug('SessionManager: resetSessionExpiration', { newTime, sessionExpiration });
          // newTime comes from the storage listener
          // we track what we previously set to avoid duplicates,
          // since IE will trigger a storage event on the same window
          // that triggered it, whereas other browsers don't.
          if (typeof newTime === 'number' && newTime === sessionExpiration) {
            return;
          }

          $timeout.cancel(timer);
          timer = createLogoutTimeout(newTime);
        });

        $window.addEventListener('storage', function(event) {
          const { key, newValue } = event;

          if (key === keyName(AuthState.storageKey)) {
            Logger.debug('SessionManager: Update logout time from storage event', { newValue });
            $rootScope.$broadcast('resetSessionExpiration', Number(newValue));
          }
        });
      }
    };
  }
]);
