import angular from 'angular';
import app from '../../app';
import moment from '../../services/moment';

import template from './last-week-rescue.html';
import './styles.less';

angular
  .module(app)
  .component('lastWeekRescue', {
    template,
    bindings: {
      week: '<',
    }
  })
  .filter('dayOfWeek', function () {
    // Placing in a closure to keep the function a bit more contained
    // Used by the rescue use row to generate the weekday bubbles for the last 7 days
    const cachedWeekdayOrders = {};

    const daysOfTheWeekIso = moment().isoWeekday(7);
    const standardWeekOrder = [daysOfTheWeekIso.format('dddd')[0]];

    // Need to use moment to generate a standard day of the week letter array
    // due to language differences
    for (let i = 1; i <= 6; i += 1) {
      daysOfTheWeekIso.isoWeekday(i);
      standardWeekOrder.push(daysOfTheWeekIso.format('dddd')[0]);
    }

    return function (histogram, dateIndex) {
      const sortedHistogram = histogram.sort((left, right) =>
        moment.utc(left.date).diff(moment.utc(right.date))
      );

      let weekdaysStartIndex = 0;
      const histogramStartDate = sortedHistogram[0].date;

      // Have we already generated a weekday array based on the first date in this date's histogram of dates?
      // If not, generate a new week array
      if (!cachedWeekdayOrders[histogramStartDate]) {
        weekdaysStartIndex = moment(histogramStartDate).day();
        const order = standardWeekOrder
          .slice(weekdaysStartIndex, 7)
          .concat(standardWeekOrder.slice(0, weekdaysStartIndex));

        cachedWeekdayOrders[histogramStartDate] = order;
      }

      return cachedWeekdayOrders[histogramStartDate][dateIndex];
    };
  });
