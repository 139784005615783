import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useIsActive, useSref, useSrefActive } from '@uirouter/react';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import CircularButton from '../CircularButton';
import Link from '../Link';
import PhDropdown from '../PhDropdown';
import PhTooltip from '../PhTooltip';
import NotificationCount from '../NotificationCount';
import NavDropdownLink from './NavDropdownLink';
import NavLink from './NavLink';
import SearchInput from './SearchInput';

import AngularInjectorContext from '../../hooks/AngularInjectorContext';
import useIsOffline from '../../hooks/useIsOffline';
import { useSession } from '../../hooks/useSession';
import useGroupConfig4x from '../../hooks/useGroupConfig4x';

import { displayInitials, displayName } from '../../utilities';
import { canAccessBilling } from '../../utilities/rtmBilling';
import { supportLocale } from '../../services/locales';

import logo from '../../../assets/images/propeller-logo.svg';
import condensedLogo from '../../../assets/images/propeller-circles.svg';
import patientNavImg from '../../../assets/images/patient_list.svg';
import reportImg from '../../../assets/images/patient_report.svg';
import plusImg from '../../../assets/images/plus-sign-black.svg';
import activePlusImg from '../../../assets/images/plus-sign.svg';

import './styles.less';
import { PRIVACY_POLICY_URL } from '../../utilities/constants';

const supportURLBase = 'https://cliniciansupport.propellerhealth.com/s';

const ANALYTICS_PAGE = 'navbar';

const SUPPORT_ARTICLES = {
  GET_STARTED: [
    {
      text: 'INTRO_GUIDE',
      path: 'article/Provider-Portal-Introductory-Guide',
      label: 'help_dropdown_introductory_guide'
    },
    {
      text: 'VIDEOS_WEBINARS',
      path: 'topic/0TO0P0000001FPHWA2/videos',
      label: 'help_dropdown_videos_and_webinars'
    }
  ],
  SUPPORT_RESOURCES: [
    {
      text: 'SUPPORT_CENTER',
      path: '',
      label: 'help_dropdown_support_center'
    },
    {
      text: 'FAQS',
      path: 'article/FAQs',
      label: 'help_dropdown_faqs'
    },
    {
      text: 'LIST_OF_MEDICATIONS',
      path: 'article/What-medications-can-patients-use-with-Propeller-United-States-only',
      label: 'help_dropdown_list_of_medications'
    },
    {
      text: 'ATTACHING_SENSORS',
      path: 'article/article/Attaching-sensors',
      label: 'help_dropdown_attaching_sensors'
    }
  ]
};

function NavBar() {
  const inject = useContext(AngularInjectorContext);
  const { t } = useTranslation(['common']);
  const groupConfig = useSelector((state) => state.groupConfig);
  const groupConfig4x = useGroupConfig4x();
  const undownloadedReports = useSelector((state) => state.unreadRtmReports);
  const notificationsStats = useSelector((state) => state.notificationsStatistics);
  const isOffline = useIsOffline();

  // currently we're not mounting this until the session is resolved,
  // but as we further migrate we may be able to incrementally load more easily
  const { session } = useSession();

  const groupLoaded = Boolean(groupConfig);
  const group4xLoaded = Boolean(groupConfig4x);

  const filter = groupLoaded ? groupConfig.diseases[0] : null;

  const showReportsTab = group4xLoaded ? canAccessBilling(groupConfig4x, session) : false;

  const patientsLink = useSref('patients', { filter, careteam: undefined }, 'selected');
  const isPatientsActive = useIsActive('patients');

  const billingLink = useSref('billing', null, 'selected');
  const isBillingActive = useIsActive('billing');

  const notificationsLink = useSrefActive('notifications', null, 'selected');
  const enrollmentLink = useSref('enrollment.patientInfo');
  const openNotifications = notificationsStats?.active || 0;

  // when we first enabled `providerEnrollmentEnabled`, we had cases where
  // some browsers (IE) weren't automatically loading fresh config due to caching
  // hence the explicit check so that if the flag is absent we don't hide the button.
  // Eventually it may make sense to handle this via a proxy, where groupConfig is not the
  // raw data but a service object that simplifies some of the interfacing
  const enrollmentOpen = groupLoaded && groupConfig.providerEnrollmentEnabled !== false;

  // wrap in useRef to avoid rerunning on render changes
  const version = useRef(inject('env').get('version'));
  const locale = supportLocale(session.language);

  const openPrivacyPolicy = () => {
    window.open(PRIVACY_POLICY_URL, '_blank');
  };

  return (
    <Navbar fluid className="ph-navbar">
      <Navbar.Header>
        <Navbar.Brand>
          <Link
            href={patientsLink.href}
            onClick={patientsLink.onClick}
            analyticsPage={ANALYTICS_PAGE}
            analyticsLabel="propeller_logo"
          >
            <img src={logo} className="logo-wide" alt="" height="40" />
            <img src={condensedLogo} className="logo-condensed" alt="" height="40" />
            <span className="sr-only">Propeller Health</span>
          </Link>
        </Navbar.Brand>
      </Navbar.Header>

      {Boolean(session) && (
        <div className="nav-wrapper clearfix">
          <div className="vertical-spacer" />
          <Nav className="primary-nav">
            <NavLink
              href={patientsLink.href}
              onClick={patientsLink.onClick}
              selected={isPatientsActive}
              analyticsLabel="go_to_my_patients"
            >
              <span className="ph has-transition normal-title">{t('NAVBAR_MY_PATIENTS')}</span>
              <span className="condensed-title">
                <img src={patientNavImg} alt={t('NAVBAR_MY_PATIENTS')} height="30" />
              </span>
            </NavLink>

            {showReportsTab && (
              <NavLink
                href={billingLink.href}
                className="navbar-tab-link"
                onClick={billingLink.onClick}
                selected={isBillingActive}
                analyticsLabel="go_to_rtm_reports"
              >
                <NotificationCount
                  count={undownloadedReports}
                  labelText={t('NUMBER_OF_OPEN_NOTIFICATIONS', { count: undownloadedReports })}
                />
                <span className="ph has-transition normal-title">{t('MY_REPORTS')}</span>
                <span className="condensed-title">
                  <img src={reportImg} alt={t('MY_REPORTS')} height="30" />
                </span>
              </NavLink>
            )}
          </Nav>

          {isOffline ? (
            <Navbar.Text className="offline-text">
              <strong>({t('YOU_ARE_OFFLINE')})</strong>
            </Navbar.Text>
          ) : null}

          <div className="navbar-right">
            <Navbar.Form pullLeft>
              <SearchInput />
            </Navbar.Form>

            <Nav pullLeft className="secondary-nav">
              {enrollmentOpen && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<PhTooltip id="add-patient--tooltip">{t('ADD_PATIENT')}</PhTooltip>}
                >
                  <NavLink
                    href={enrollmentLink.href}
                    onClick={enrollmentLink.onClick}
                    className="add-patient-button circular-link"
                    aria-describedby="add-patient--tooltip"
                    analyticsLabel="add_patient"
                  >
                    <img className="default-state" alt="" src={plusImg} />
                    <img className="hover-state" alt="" src={activePlusImg} />
                    <span className="sr-only">{t('ADD_PATIENT')}</span>
                  </NavLink>
                </OverlayTrigger>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <PhTooltip id="notifications--tooltip">{t('NAVBAR_NOTIFICATIONS')}</PhTooltip>
                }
              >
                <NavLink
                  href={notificationsLink.href}
                  onClick={notificationsLink.onClick}
                  className={classNames(
                    'notifications-button',
                    'circular-link',
                    notificationsLink.className
                  )}
                  aria-describedby="notifications--tooltip"
                  analyticsLabel="go_to_notifications"
                >
                  <NotificationCount
                    labelText={t('NUMBER_OF_OPEN_NOTIFICATIONS', { count: openNotifications })}
                    count={openNotifications}
                  />
                  <i className="fa icon-bell-alt" />
                  <span className="sr-only">{t('NAVBAR_NOTIFICATIONS')}</span>
                </NavLink>
              </OverlayTrigger>

              <PhDropdown
                id="support-menu"
                className="navbar--dropdown support-dropdown"
                toggleComponent={CircularButton}
                toggle={
                  <>
                    ?<span className="sr-only">{t('HELP_AND_SUPPORT')}</span>
                  </>
                }
                tooltip={t('NAVBAR_HELP')}
                analyticsPage="navbar"
                analyticsLabel="show_help_options"
              >
                <MenuItem header>{t('GET_STARTED')}</MenuItem>

                {SUPPORT_ARTICLES.GET_STARTED.map((article) => (
                  <NavDropdownLink
                    key={article.text}
                    href={`${supportURLBase}/${article.path}?language=${locale}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    analyticsPage={ANALYTICS_PAGE}
                    analyticsLabel={article.label}
                  >
                    {t(article.text)}
                  </NavDropdownLink>
                ))}

                <MenuItem divider />

                <MenuItem header>{t('SUPPORT_RESOURCES')}</MenuItem>

                {SUPPORT_ARTICLES.SUPPORT_RESOURCES.map((article) => (
                  <NavDropdownLink
                    key={article.text}
                    href={`${supportURLBase}/${article.path}?language=${locale}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    analyticsPage={ANALYTICS_PAGE}
                    analyticsLabel={article.label}
                  >
                    {t(article.text)}
                  </NavDropdownLink>
                ))}

                <MenuItem divider />

                <MenuItem header>{t('HELP_AND_SUPPORT')}</MenuItem>

                <NavDropdownLink
                  href={`${supportURLBase}/contactsupport?language=${locale}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="help_dropdown_contact_support"
                >
                  {t('CONTACT_SUPPORT')}
                </NavDropdownLink>

                <div className="app-version">v{version.current}</div>
              </PhDropdown>

              <PhDropdown
                id="account-settings"
                className="navbar--dropdown account-dropdown"
                toggleComponent={CircularButton}
                toggle={
                  <>
                    {displayInitials(session)}
                    <span className="sr-only">{t('ACCOUNT_SETTINGS')}</span>
                  </>
                }
                analyticsPage={ANALYTICS_PAGE}
                analyticsLabel="show_account_settings_options"
              >
                <MenuItem header className="username">
                  <strong>{displayName(session)}</strong>
                </MenuItem>

                <MenuItem divider />

                <MenuItem header>{t('ACCOUNT_SETTINGS')}</MenuItem>

                <NavDropdownLink
                  to="settings-password-and-account"
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="account_settings_dropdown_password_and_account"
                >
                  {t('PASSWORD_ACCOUNT')}
                </NavDropdownLink>

                <NavDropdownLink
                  to="settings-profile"
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="account_settings_dropdown_profile_settings"
                >
                  {t('PROFILE_SETTINGS')}
                </NavDropdownLink>

                <NavDropdownLink
                  to="settings-notifications"
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="account_settings_dropdown_notification_settings"
                >
                  {t('NOTIFICATION_SETTINGS')}
                </NavDropdownLink>

                <NavDropdownLink
                  onClick= { openPrivacyPolicy }
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="account_settings_dropdown_privacy-policy"
                >
                  {t('PRIVACY_LINK')}
                </NavDropdownLink>

                <MenuItem divider />

                <NavDropdownLink
                  onClick={() => inject('User').signOut()}
                  analyticsPage={ANALYTICS_PAGE}
                  analyticsLabel="sign_out"
                >
                  {t('SIGN_OUT')}
                </NavDropdownLink>
              </PhDropdown>
            </Nav>
          </div>
        </div>
      )}
    </Navbar>
  );
}

export default NavBar;
