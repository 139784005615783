import React from 'react';
import { shape, string } from 'prop-types';

import spinner from '../../../assets/images/spinner-propeller-logo.svg';
import gifSpinner from '../../../assets/images/spinner-propeller-logo-gif.gif';
import './styles.less';

function Loading({ style, message }) {
  return (
    <div className="loading-container" style={style}>
      <div className="ph vertically-centered-container">
        <div className="vertically-centered-element">
          <img className="loading-spinner smil" src={spinner} alt="" />
          <img className="loading-spinner no-smil" src={gifSpinner} alt="" />
          {Boolean(message) && <div className="loading-message">{message}</div>}
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  style: shape({}),
  message: string
};

Loading.defaultProps = {
  style: {},
  message: null
};

export default Loading;
