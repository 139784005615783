// eslint-disable-next-line import/prefer-default-export
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type };

    argNames.forEach((keyName, index) => {
      action[keyName] = args[index];
    });

    return action;
  };
}
