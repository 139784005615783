/* eslint-disable import/prefer-default-export */
// implementation based on https://github.com/onury/custom-error-test/blob/master/the-one/CustomError.js
import { setProto, defineDefaultProperties, definePrototypeChain } from './errorUtilities';
import { isResponseObject, responseForLog } from '..';

export function AccountCreationError(message, response) {
  let err;

  if (setProto) {
    err = new Error(message);
    setProto(err, AccountCreationError.prototype);
  } else {
    err = this;
  }

  Object.defineProperty(err, 'response', {
    enumerable: false,
    writable: false,
    value: isResponseObject(response) ? responseForLog(response) : null
  });

  return defineDefaultProperties(err, message, AccountCreationError, 'AccountCreationError');
}

definePrototypeChain(AccountCreationError);
