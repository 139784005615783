/* eslint-disable camelcase */

import d3_caES from 'd3-time-format/locale/ca-ES.json';
import d3_csCZ from 'd3-time-format/locale/cs-CZ.json';
import d3_daDK from 'd3-time-format/locale/da-DK.json';
import d3_deCH from 'd3-time-format/locale/de-CH.json';
import d3_deDE from 'd3-time-format/locale/de-DE.json';
import d3_enGB from 'd3-time-format/locale/en-GB.json';
import d3_enUS from 'd3-time-format/locale/en-US.json';
import d3_esES from 'd3-time-format/locale/es-ES.json';
import d3_esMX from 'd3-time-format/locale/es-MX.json';
import d3_fiFI from 'd3-time-format/locale/fi-FI.json';
import d3_frCA from 'd3-time-format/locale/fr-CA.json';
import d3_frFR from 'd3-time-format/locale/fr-FR.json';
import d3_itIT from 'd3-time-format/locale/it-IT.json';
import d3_jaJP from 'd3-time-format/locale/ja-JP.json';
import d3_koKR from 'd3-time-format/locale/ko-KR.json';
import d3_nbNO from 'd3-time-format/locale/nb-NO.json';
import d3_nlNL from 'd3-time-format/locale/nl-NL.json';
import d3_ruRU from 'd3-time-format/locale/ru-RU.json';
import d3_svSE from 'd3-time-format/locale/sv-SE.json';

const d3_enCA = d3_enUS;

// adapted from d3's pt-BR and moment's pt
const d3_ptPT = {
  dateTime: '%A, %e de %B de %Y, %X',
  date: '%d/%m/%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
  ],
  shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
};

const d3_elGR = {
  dateTime: '%A %e %B %Y, %X',
  date: '%d/%m/%Y',
  time: '%I:%M:%S %p',
  periods: ['π.μ.', 'μ.μ.'],
  days: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
  shortDays: ['Κυρ', 'Δευ', 'Τρί', 'Τετ', 'Πέμ', 'Παρ', 'Σάβ'],
  months: [
    'Ιανουαρίου',
    'Φεβρουαρίου',
    'Μαρτίου',
    'Απριλίου',
    'Μαΐου',
    'Ιουνίου',
    'Ιουλίου',
    'Αυγούστου',
    'Σεπτεμβρίου',
    'Οκτωβρίου',
    'Νοεμβρίου',
    'Δεκεμβρίου'
  ],
  shortMonths: [
    'Ιαν',
    'Φεβ',
    'Μαρ',
    'Απρ',
    'Μαΐ',
    'Ιουν',
    'Ιουλ',
    'Αυγ',
    'Σεπ',
    'Οκτ',
    'Νοε',
    'Δεκ'
  ]
};

const d3_frBE = {
  dateTime: '%A, le %e %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
  ],
  shortMonths: [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.'
  ]
};

const d3_svFI = {
  dateTime: '%A, %-d. %Bta %Y klo %X',
  date: '%-d.%-m.%Y',
  time: '%H:%M:%S',
  periods: ['fm', 'em'],
  days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
  shortDays: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  months: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
};

export const LOCALE_FALLBACKS = {
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'en-AU': 'en-GB',
  'en-CA': 'en-US',
  'en-IE': 'en-GB',
  'fr-CH': 'fr-FR',
  'fr-BE': 'fr-FR',
  'it-CH': 'it-IT',
  'nl-BE': 'nl-NL',
  'sv-FI': 'sv-SE'
};

const DEFAULT_LOCALE = 'en-US';

const LOCALES = [
  ['ca-ES', 'Català', d3_caES],
  ['cs-CZ', 'čeština', d3_csCZ],
  ['da-DK', 'Dansk', d3_daDK],
  ['de-CH', 'Deutsch (CH)', d3_deCH],
  ['de-AT', 'Deutsch (AT)', d3_deDE],
  ['de-DE', 'Deutsch (DE)', d3_deDE],
  ['el-GR', 'Ελληνικά', d3_elGR],
  ['en-AU', 'English (AU)', d3_enGB],
  ['en-CA', 'English (CA)', d3_enCA],
  ['en-GB', 'English (GB)', d3_enGB],
  ['en-IE', 'English (IE)', d3_enGB],
  ['en-US', 'English (US)', d3_enUS],
  ['es-AR', 'Español (AR)', d3_esMX],
  ['es-ES', 'Español (ES)', d3_esES],
  ['es-MX', 'Español (MX)', d3_esMX],
  ['es-US', 'Español (US)', d3_esMX],
  ['fi-FI', 'Suomi (FI)', d3_fiFI],
  ['fr-BE', 'Français (BE)', d3_frBE],
  ['fr-CA', 'Français (CA)', d3_frCA],
  ['fr-CH', 'Français (CH)', d3_frFR],
  ['fr-FR', 'Français (FR)', d3_frFR],
  ['it-CH', 'Italiano (CH)', d3_itIT],
  ['it-IT', 'Italiano (IT)', d3_itIT],
  ['ja-JP', '日本語', d3_jaJP],
  ['ko-KR', '한국어', d3_koKR],
  ['nb-NO', 'norsk bokmål', d3_nbNO],
  ['nl-BE', 'Nederlands (BE)', d3_nlNL],
  ['nl-NL', 'Nederlands (NL)', d3_nlNL],
  ['pt-PT', 'Português (PT)', d3_ptPT],
  ['ru-RU', 'Pусский', d3_ruRU],
  ['sv-FI', 'Svenska(FI)', d3_svSE],
  ['sv-SE', 'Svenska(SE)', d3_svFI]
];

export const defaultLocale = DEFAULT_LOCALE;

export const options = LOCALES.reduce((accumulator, locale) => {
  accumulator[locale[0]] = {
    name: locale[1],
    d3Locale: locale[2]
  };

  return accumulator;
}, {});

export function currentLocale(lng) {
  return options[lng] || options[defaultLocale];
}

export function d3Locale(lng) {
  const locale = currentLocale(lng);

  if (locale && locale.d3Locale) {
    return locale.d3Locale;
  }

  return options[defaultLocale].d3Locale;
}

export function fallbacks(lng) {
  const fallback = LOCALE_FALLBACKS[lng];
  const result = fallback ? [fallback, defaultLocale] : defaultLocale;

  return result;
}

export function optionsForCountry(aCountry = 'US') {
  return Object.keys(options)
    .filter((locale) => locale.split('-')[1] === aCountry)
    .map((locale) => ({
      locale,
      name: options[locale].name
    }));
}

/**
 * Locale codes used in salesforce support site
 * en_US
 * ca
 * da
 * de
 * en_AU
 * en_GB
 * es
 * es_MX
 * es_US
 * fr
 * fr_CA
 * it
 * ja
 * nl_NL
 * pt_PT
 * sv
 */
const FULL_CODES = ['en-AU', 'en-GB', 'en-US', 'es-MX', 'es-US', 'fr-CA', 'nl-NL', 'pt-PT'];

export function supportLocale(locale) {
  if (FULL_CODES.includes(locale)) {
    return locale.replace('-', '_');
  }

  return locale.slice(0, 2);
}

export const shortenedLongDateFormat = {
  'ca-ES': 'ddd D MMM',
  'cs-CZ': 'ddd D. MMM',
  'da-DK': 'ddd [d.] D. MMM',
  'de-AT': 'ddd, D. MMM',
  'de-CH': 'ddd, D. MMM',
  'de-DE': 'ddd, D. MMM',
  'el-GR': 'ddd, D MMM',
  'en-AU': 'ddd, D MMM',
  'en-CA': 'ddd, MMM D',
  'en-GB': 'ddd, D MMM',
  'en-IE': 'ddd D MMM',
  'en-US': 'ddd, MMM D',
  'es-AR': 'ddd, D [de] MMM',
  'es-ES': 'ddd, D [de] MMM',
  'es-MX': 'ddd, D [de] MMM',
  'es-US': 'ddd, D [de] MMM',
  'fi-FI': 'ddd, Do MMM',
  'fr-BE': 'ddd D MMM',
  'fr-CA': 'ddd D MMM',
  'fr-CH': 'ddd D MMM',
  'fr-FR': 'ddd D MMM',
  'it-CH': 'ddd D MMM',
  'it-IT': 'ddd D MMM',
  'ja-JP': 'M月D日(ddd)',
  'ko-KR': 'MMMM D일 dddd',
  'nb-NO': 'ddd D. MMM',
  'nl-BE': 'ddd D MMM',
  'nl-NL': 'ddd D MMM',
  'pt-PT': 'ddd, D [de] MMM',
  'ru-RU': 'ddd, D MMM',
  'sv-FI': 'ddd D MMM',
  'sv-SE': 'ddd D MMM',
  ca: 'ddd D MMM',
  cs: 'ddd D. MMM',
  da: 'ddd [d.] D. MMM',
  de: 'ddd, D. MMM',
  el: 'ddd, D MMM',
  en: 'ddd, MMM D',
  es: 'ddd, D [de] MMM',
  fi: 'ddd, Do MMM',
  fr: 'ddd D MMM',
  it: 'ddd D MMM',
  ja: 'M月D日(ddd)',
  ko: 'MMMM D일 dddd',
  nb: 'ddd D. MMM',
  nl: 'ddd D MMM',
  pt: 'ddd, D [de] MMM',
  ru: 'ddd, D MMM',
  sv: 'ddd D MMM'
};

export function shortenDateFormat(locale) {
  return shortenedLongDateFormat[locale] || 'ddd, MMM D';
}

export default {
  defaultLocale,
  options,
  currentLocale,
  d3Locale,
  fallbacks
};
