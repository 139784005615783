import { combineReducers } from 'redux';
import {
  groupConfig,
  groupConfig4x,
  groupClinicians,
  groupSubGroups,
  medicationsDictionary
} from './groups';
import { enrollmentData, enrollmentSubGroups, hubId } from './enrollment';
import enrollmentMedications from './enrollmentMedications';
import { copdAssessment, adultAsthmaAssessment, childAsthmaAssessment } from './assessments';
import { currentPatient } from './patient';
import { notificationsStatistics, unreadRtmReports } from './notifications';

export default combineReducers({
  adultAsthmaAssessment,
  childAsthmaAssessment,
  copdAssessment,
  currentPatient,
  enrollmentData,
  enrollmentMedications,
  enrollmentSubGroups,
  groupConfig,
  groupConfig4x,
  groupClinicians,
  groupSubGroups,
  hubId,
  medicationsDictionary,
  notificationsStatistics,
  unreadRtmReports
});
