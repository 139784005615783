import angular from 'angular';
import app from '../../app';
import moment from '../../services/moment';
import template from './display-date.html';

function isDateValid(date) {
  return typeof date === 'string' && moment(date).isValid();
}

angular.module(app).component('displayDate', {
  template,
  bindings: {
    date: '<',
    timeZone: '<'
  },
  controller() {
    const ctrl = this;

    ctrl.$onInit = function init() {
      this.dateIsValid = isDateValid(ctrl.date);
    };
  }
});
