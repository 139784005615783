import angular from 'angular';
import app from '../../app';

import './settings-notifications.less';

angular
  .module(app)
  .controller('SettingsNotificationsCtrl', function($scope, $translatePartialLoader, User, $q) {
    $translatePartialLoader.addPart('views/settings-notifications');

    $scope.user = {};
    $scope.loading = true;

    // reason for this pattern is to simulate a visual for failing to save a setting /////

    // enable/disable send daily digest
    $scope.enableDisableSendDailyDigest = function() {
      $scope.sendDailyDigest = !$scope.sendDailyDigest;

      const patchUser = {
        notifications: {
          digest: {
            email: $scope.sendDailyDigest
          }
        }
      };

      // turn off send weekend emails if it is on
      if ($scope.sendNotificationsOnWeekends && !$scope.sendDailyDigest) {
        $scope.sendNotificationsOnWeekends = !$scope.sendNotificationsOnWeekends;

        patchUser.preferences = {
          weekendDigests: $scope.sendNotificationsOnWeekends
        };
      }

      User.updateUser(patchUser).catch(() => {
        $scope.sendDailyDigest = !$scope.sendDailyDigest; // revert back to previous state if it failed
      });
    };

    // enable/disable send notifications on weekends
    $scope.enableDisableSendNotificationsOnWeekends = function() {
      if (!$scope.sendDailyDigest) {
        return $q.reject('Send daily digest is turned off');
      }

      $scope.sendNotificationsOnWeekends = !$scope.sendNotificationsOnWeekends;

      return User.updateUser({
        preferences: {
          weekendDigests: $scope.sendNotificationsOnWeekends
        }
      }).catch(res => {
        $scope.sendNotificationsOnWeekends = !$scope.sendNotificationsOnWeekends; // revert back to previous state if it failed
      });
    };

    // initialize code here
    User.getUser().then(function(res) {
      $scope.user = res;
      // init email settings here
      $scope.sendDailyDigest = $scope.user.notifications.digest.email;

      // init nights and weekends setting here
      $scope.sendNotificationsOnWeekends = $scope.user.preferences.weekendDigests;

      // if sendDailyDigest is off and sendNotificationsOnWeekends is on, turn off sendNotificationsOnWeekends
      if (!$scope.sendDailyDigest && $scope.sendNotificationsOnWeekends) {
        $scope.sendNotificationsOnWeekends = !$scope.sendNotificationsOnWeekends;

        User.updateUser({
          preferences: {
            weekendDigests: $scope.sendNotificationsOnWeekends
          }
        }).catch(() => {
          $scope.sendNotificationsOnWeekends = !$scope.sendNotificationsOnWeekends; // revert back to previous state if it failed
        });
      }

      $scope.loading = false;
    });
  });
