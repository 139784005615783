import angular from 'angular';
import app from '../../app';

import template from './care-team-options.html';
import './care-team-options.less';

angular.module(app).component('careTeamOptions', {
  template,
  bindings: {
    currentSelection: '<',
    options: '<',
    analyticsView: '@',
    onChange: '&'
  },
  controller: function CareTeamOptionsController() {
    const ctrl = this;

    ctrl.changed = function(selection) {
      ctrl.onChange({ value: selection });
    };
  }
});
