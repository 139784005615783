import { hasItems, hasOwn } from '../index';

import ble1MAC from '../../../assets/images/sensors/SMII_locating-sensor-id_RGB.png';

import dolphinMAC from '../../../assets/images/sensors/2017-B_locating-sensor-id_RGB.png';
// import dolphinSensor from '../../../assets/images/sensors/2017-B_circled-sensor-only_RGB.png';
import dolphinPress from '../../../assets/images/sensors/2017-B_circled-button-pressing-sensor-only_RGB.png';
import dolphinAttach from '../../../assets/images/sensors/2017-B_circled-sensor-attachment_RGB_nosnap.png';

import jellyfishMAC from '../../../assets/images/sensors/2016-M_locating-sensor-id_RGB.png';
// import jellyfishSensor from '../../../assets/images/sensors/2016-M_circled-sensor-only_RGB.png';
import jellyfishPress from '../../../assets/images/sensors/2016-M_circled-button-pressing-sensor-only_RGB.png';
import jellyfishAttach from '../../../assets/images/sensors/2016-M_circled-sensor-attachment_RGB.png';

import mantaMAC from '../../../assets/images/sensors/2015-E_locating-sensor-id_RGB.png';
// import mantaSenor from '../../../assets/images/sensors/2015-E_circled-sensor-only_RGB.png';
import mantaPress from '../../../assets/images/sensors/2015-E_circled-button-pressing-sensor-only_RGB.png';
import mantaAttach from '../../../assets/images/sensors/2015-E_circled-sensor-attachment_RGB_nosnap.png';

// import remoraSensor from '../../../assets/images/sensors/remora-sensor.png';
import remoraMAC from '../../../assets/images/sensors/remora-locating-sensor-id.png';

import squidMAC from '../../../assets/images/sensors/2014-R_locating-sensor-id_RGB.png';
// import squidSensor from '../../../assets/images/sensors/2014-R_circled-sensor-only_RGB.png';
import squidPress from '../../../assets/images/sensors/2014-R_circled-button-pressing-sensor-only_RGB.png';
import squidAttach from '../../../assets/images/sensors/2014-R_circled-sensor-attachment_RGB.png';

import sunfishMAC from '../../../assets/images/sensors/2014-D_locating-sensor-id_RGB-01.png';
// import sunfishSensor from '../../../assets/images/sensors/2014-D_circled-sensor-only_RGB.png';
import sunfishPress from '../../../assets/images/sensors/2014-D_circled-button-pressing-sensor-only_RGB.png';
import sunfishAttach from '../../../assets/images/sensors/2014-D_circled-sensor-attachment_RGB.png';

import limulusMAC from '../../../assets/images/sensors/limulus-locating-sensor-id.png';
// import limulusSensor from '../../../assets/images/sensors/limulus_sensor.png';
import limulusPress from '../../../assets/images/sensors/limulus_press.png';
import limulusAttach from '../../../assets/images/sensors/limulus_attachment.png';

import waterbearMAC from '../../../assets/images/sensors/waterbear-locating-sensor-id.png';
// import waterbearSensor from '../../../assets/images/sensors/waterbear_sensor.png';
import waterbearPullTab from '../../../assets/images/sensors/waterbear-pull-tab.png';
import waterbearPress from '../../../assets/images/sensors/waterbear_press_darker.png';
import waterbearAttach from '../../../assets/images/sensors/waterbear_attachment.png';

const MAC_PREFIX = 'F8:FE:5C';

const BLE1 = 'ble1';
const DOLPHIN = 'dolphin';
const JELLYFISH87 = 'jellyfish87';
const JELLYFISH92 = 'jellyfish92';
const LIMULUS = 'limulus';
const MANTA = 'manta';
const REMORA = 'remora';
const SQUID = 'squid';
const STINGRAY = 'stingray';
const SUNFISH = 'sunfish';
const WATERBEAR = 'waterbear';
const MOSSPIGLET = 'mosspiglet';

const ATTACH = 'attach';
const PRESS = 'press';

const PRESS_STEP = { step: PRESS, alt: 'PRESS_SENSOR_BUTTON', text: 'PRESS_SENSOR_BUTTON' };
const ATTACH_STEP = { step: ATTACH, alt: 'SENSOR_ATTACHMENT', text: 'ATTACH_SENSOR' };

// jellyfish doesn't need the press step...
const SENSORS = [
  [
    BLE1,
    ble1MAC,
    jellyfishAttach,
    jellyfishPress,
    [
      { src: jellyfishPress, ...PRESS_STEP },
      { src: jellyfishAttach, ...ATTACH_STEP }
    ]
  ],
  [
    DOLPHIN,
    dolphinMAC,
    dolphinAttach,
    dolphinPress,
    [
      { src: dolphinPress, ...PRESS_STEP },
      { src: dolphinAttach, ...ATTACH_STEP }
    ]
  ],
  [
    JELLYFISH87,
    jellyfishMAC,
    jellyfishAttach,
    jellyfishPress,
    [
      { src: jellyfishPress, ...PRESS_STEP },
      { src: jellyfishAttach, ...ATTACH_STEP }
    ]
  ],
  [
    JELLYFISH92,
    jellyfishMAC,
    jellyfishAttach,
    jellyfishPress,
    [
      { src: jellyfishPress, ...PRESS_STEP },
      { src: jellyfishAttach, ...ATTACH_STEP }
    ]
  ],
  [
    LIMULUS,
    limulusMAC,
    limulusAttach,
    limulusPress,
    [
      { src: limulusAttach, ...ATTACH_STEP },
      { src: limulusPress, ...PRESS_STEP }
    ]
  ],
  [
    MANTA,
    mantaMAC,
    mantaAttach,
    mantaPress,
    [
      { src: mantaPress, ...PRESS_STEP },
      { src: mantaAttach, ...ATTACH_STEP }
    ]
  ],
  [REMORA, remoraMAC],
  [
    SQUID,
    squidMAC,
    squidAttach,
    squidPress,
    [
      { src: squidPress, ...PRESS_STEP },
      { src: squidAttach, ...ATTACH_STEP }
    ]
  ],
  [
    STINGRAY,
    mantaMAC,
    mantaAttach,
    mantaPress,
    [
      { src: mantaPress, ...PRESS_STEP },
      { src: mantaAttach, ...ATTACH_STEP }
    ]
  ],
  [
    SUNFISH,
    sunfishMAC,
    sunfishAttach,
    sunfishPress,
    [
      { src: sunfishPress, ...PRESS_STEP },
      { src: sunfishAttach, ...ATTACH_STEP }
    ]
  ],
  [
    WATERBEAR,
    waterbearMAC,
    waterbearAttach,
    waterbearPress,
    [
      { src: waterbearPullTab, step: 'remove', alt: 'REMOVE_SENSOR_TAB', text: 'REMOVE_SENSOR_TAB' },
      { src: waterbearAttach, ...ATTACH_STEP },
      { src: waterbearPress, ...PRESS_STEP }
    ]
  ],
  [
    MOSSPIGLET,
    waterbearMAC,
    waterbearAttach,
    waterbearPress,
    [
      { src: waterbearPullTab, step: 'remove', alt: 'REMOVE_SENSOR_TAB', text: 'REMOVE_SENSOR_TAB' },
      { src: waterbearAttach, ...ATTACH_STEP },
      { src: waterbearPress, ...PRESS_STEP }
    ]
  ]
];

const SENSORS_WITH_SOUNDS = [
  DOLPHIN,
  JELLYFISH87,
  JELLYFISH92,
  LIMULUS,
  MANTA,
  SQUID,
  STINGRAY,
  SUNFISH,
  WATERBEAR,
  MOSSPIGLET
];

const models = SENSORS.reduce((acc, sensorConfig) => {
  const [modelName, macImg, attachImg, pressImg, setupSteps] = sensorConfig;

  acc[modelName] = {
    macImg,
    attachImg,
    pressImg,
    setupSteps,
    hasSetup: hasItems(setupSteps)
  };

  return acc;
}, {});

function sensorCodeForMedication(medication) {
  const sensors = medication.sensors || [];

  if (sensors.length === 1) {
    return sensors[0]; // most cases
  }

  // ble1 vs jellyfishXX. Prioritize jellyfish.
  if (sensors.includes(BLE1)) {
    return sensors.find((s) => s !== BLE1);
  }

  // ellipta could have both stingray and manta. Prioritized manta.
  if (sensors.includes(MANTA)) {
    return MANTA;
  }

  // just to be safe
  return sensors[0];
}

export function hasSounds(sensor) {
  return SENSORS_WITH_SOUNDS.includes(sensor.model);
}

export function sensorForMed(medication) {
  const code = sensorCodeForMedication(medication);

  return models[code];
}

export function soundsEnabled(sensor) {
  if (!hasSounds(sensor)) {
    return false;
  }

  return hasOwn(sensor, 'silent') && sensor.silent === false;
}

export function formatMac(aMac) {
  return aMac
    .toUpperCase()
    .replace(/O/g, 0)
    .replace(/[^A-F0-9]/g, '')
    .slice(-6)
    .replace(/([A-F0-9]{2})([A-F0-9]{2})([A-F0-9]{2})/, function (match, p1, p2, p3) {
      return [p1, p2, p3].join(':');
    });
}

export const prefixMac = (aMac) => `${MAC_PREFIX}:${aMac}`;

export default {
  formatMac,
  hasSounds,
  prefixMac,
  sensorForMed,
  soundsEnabled
};
