/* eslint-disable no-console */
export const storagePrefix = 'ph-prov';

export function keyName(key) {
  return `${storagePrefix}-${key}`;
}

export function init(storageType) {
  let storage;

  // Some installations of IE, for an unknown reason, throw "SCRIPT5: Error: Access is denied" when you try to access it
  try {
    storage = window[storageType];
  } catch (err) {
    storage = false;
  }

  // sessionStorage is available on Safari in private mode, but throws when you try to interact with it
  if (storage) {
    const key = `__${Math.round(Math.random() * 1e7)}`;
    try {
      storage.setItem(key, key);
      storage.removeItem(key);
    } catch (err) {
      storage = false;
    }
  }

  const warning = `${storageType} not supported`;

  return {
    set(key, value) {
      if (storage) {
        storage.setItem(keyName(key), JSON.stringify(value));
      } else {
        console.warn(`${warning}, could not set`);
      }
    },
    get(key) {
      if (storage) {
        return JSON.parse(storage.getItem(keyName(key)));
      }
      console.warn(`${warning}, could not get`);

      return null;
    },
    remove(key) {
      if (storage) {
        storage.removeItem(keyName(key));
      } else {
        console.warn(`${warning}, could not remove`);
      }
    },
    clear() {
      if (storage) {
        storage.clear();
      } else {
        console.warn(`${warning}, could not clear`);
      }
    }
  };
}
