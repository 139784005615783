/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
import { SET_CURRENT_PATIENT, UNSET_CURRENT_PATIENT } from '../../actions';

export function currentPatient(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_PATIENT:
      return action.patient;

    case UNSET_CURRENT_PATIENT:
      return null;

    default:
      return state;
  }
}
