import angular from 'angular';
import React from 'react';
import ReactDOM from 'react-dom';
import { UIRouterContextComponent } from '@uirouter/react-hybrid';

import app from '../../../../app';
import AngularToReactBridge from '../../../../react-components/AngularToReactBridge';
import NavBar from '../../../../react-components/NavBar';
import { ProvideSession } from '../../../../hooks/useSession';

import {
  getNotificationsStatistics,
  loadGroupClinicians,
  loadGroupConfig,
  loadGroupConfig4x,
  loadSubGroups4x
} from '../../../../actions';

import template from './main-app.html';
import './main.less';

angular.module(app).component('mainApp', {
  template,
  bindings: {
    session: '<'
  },
  controller: [
    '$location',
    '$rootScope',
    '$uibModal',
    '$ngRedux',
    'Announcements',
    'SessionManager',
    'User',
    'Notifications',
    'Logger',
    function (
      $location,
      $rootScope,
      $uibModal,
      $ngRedux,
      Announcements,
      SessionManager,
      User,
      Notifications,
      Logger
    ) {
      Logger.debug('Main app: enter component');
      const ctrl = this;
      const navbarID = 'main-navbar';
      ctrl.loading = true;

      // session is resolved from the route in ./main.js
      $rootScope.invalidSession = false;

      SessionManager.initialize(function onExpire() {
        $rootScope.invalidSession = true;
        User.signOut($location.absUrl());
      });

      ctrl.$onInit = function init() {
        Logger.debug('Main app: init component');
        const { session } = ctrl;

        // dispatch these, but don't wait for them.
        $ngRedux.dispatch(getNotificationsStatistics(Notifications));
        $ngRedux.dispatch(
          loadGroupConfig4x(session.group.id, session.language, {
            include: 'parameters',
            tag: ['rtm']
          })
        );
        $ngRedux.dispatch(loadGroupClinicians(session.group));
        $ngRedux.dispatch(loadSubGroups4x(session.group.name));

        $ngRedux
          .dispatch(loadGroupConfig(session.group.id))
          .then((groupConfig) => {
            Logger.debug('Main app: set component as loaded');
            ctrl.loading = false;
            $rootScope.loading = false;
            const announcement = Announcements.getCurrentAnnouncement(ctrl.session, groupConfig);

            if (announcement) {
              const modal = $uibModal.open({
                component: 'announcementModal',
                controllerAs: '$ctrl',
                windowClass: 'modal-wrapper announcement-modal-wrapper',
                size: announcement.size || 'md',
                resolve: {
                  announcement: () => announcement,
                  locale: () => ctrl.session.language
                }
              });

              modal.closed.then(() => Announcements.dismiss(announcement));
            }
          })
          .catch((err) => {
            Logger.error('Error initializing main app', err);
          });

        ReactDOM.render(
          <AngularToReactBridge>
            <ProvideSession session={session}>
              <UIRouterContextComponent>
                <NavBar />
              </UIRouterContextComponent>
            </ProvideSession>
          </AngularToReactBridge>,
          document.getElementById(navbarID)
        );
      };

      ctrl.$onDestroy = function destroy() {
        ReactDOM.unmountComponentAtNode(document.getElementById(navbarID));
      };
    }
  ]
});
