import { makeActionCreator } from '../utilities';

export const RTM_REPORT_DOWNLOADED = 'RTM_REPORT::DOWNLOADED';
export const SET_NOTIFICATIONS_STATISTICS = 'NOTIFICATIONS::SET_STATISTICS';
export const NOTIFICATION_UNREAD_REPORTS = 'RTM_REPORTS::UNREAD_REPORTS';
export const setNotificationsStatistics = makeActionCreator(
  SET_NOTIFICATIONS_STATISTICS,
  'statistics'
);

// In some other cases, eg. groupConfig, we inject the service manually.
// That makes testing tricky, particularly across framework boundaries,
// so in this case I want us to take the service as an argument,
// which effectively shifts the DI to the function call instead of
// magic Angular internals
export function getNotificationsStatistics(Notifications) {
  return function (dispatch) {
    return Notifications.getNotificationStats()
      .then((response) => {
        const { undeleted, deleted, acknowledged } = response.data;

        const stats = {
          active: undeleted.count,
          resolved: deleted.count,
          dismissed: acknowledged.count
        };

        dispatch(setNotificationsStatistics(stats));
      })
      .catch(() => {
        dispatch(
          setNotificationsStatistics({
            active: 0,
            resolved: 0,
            dismissed: 0
          })
        );
      });
  };
}

export function rtmReportDownloaded(report) {
  return {
    type: RTM_REPORT_DOWNLOADED,
    payload: report
  };
}
