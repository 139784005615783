import angular from 'angular';
import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    name: 'main',
    component: 'mainApp',
    resolve: {
      accessToken: [
        '$transition$',
        '$location',
        'Auth',
        'Logger',
        function ($transition$, $location, Auth, Logger) {
          /*
            Calling Auth.login() first redirects to keycloak, which unloads the app
            then we get redirected back with a fragment containing params
            that the next call to Auth.login() will use to get an access token.
            After the POST ajax call for the access token, the KC params are removed from the URL.
            This URL change triggers a new transition which calls again all these methods.
            If we don't cancel the transition, we will produce an error saying
            "The transition has been superseded by a different transition".
            So here we are comparing the URL pre and post Auth.login(). If they are different,
            that tells us it's the first of the 2 transitions and so we cancel it rather than
            having it produce an error.
          */
          const url = $location.url();

          Logger.debug('Main route: retrieve token', { url });

          return Auth.login().then((token) => {
            if ($location.url() !== url) {
              $transition$.abort();
            }

            return token;
          });
        }
      ],
      session: [
        '$q',
        'User',
        'Logger',
        'accessToken',
        ($q, User, Logger, accessToken) => {
          Logger.debug('Main route: load session');
          
          return accessToken ? User.signIn({ accessToken }) : $q.reject();
        }
      ]
    }
  });
});
