import angular from 'angular';
import app from '../../app';

import template from './settings-notifications.html';

angular.module(app).config(function($stateProvider) {
  $stateProvider.state('settings-notifications', {
    parent: 'main',
    url: '/settings/notifications',
    template,
    controller: 'SettingsNotificationsCtrl'
  });
});
