import angular from 'angular';
import app from '../../app';

import template from './on-off-label.html';
import './on-off-label.less';

angular.module(app).component('onOffLabel', {
  template,
  bindings: {
    setting: '<'
  },
  controller: [
    '$translatePartialLoader',
    function OnOffLabelController($translatePartialLoader) {
      $translatePartialLoader.addPart('components/on-off-label');
    }
  ]
});
