/* eslint-disable default-param-last */
import {
  SET_NOTIFICATIONS_STATISTICS,
  SET_GROUP_CONFIG_4X,
  RTM_REPORT_DOWNLOADED
} from '../../actions';

import { isEmptyObject } from '../../utilities';

const initialValue = {
  active: 0,
  resolved: 0,
  dismissed: 0
};

// eslint-disable-next-line import/prefer-default-export
export function notificationsStatistics(state = initialValue, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS_STATISTICS:
      return action.statistics;

    default:
      return state;
  }
}

export function unreadRtmReports(state = 0, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG_4X: {
      const reportCount = action.config?.rtmUndownloadedReportCount;
      if (typeof reportCount === 'number') {
        return reportCount;
      }
      return state;
    }

    case RTM_REPORT_DOWNLOADED:
      if (action.payload.lastDownloadedDate || !isEmptyObject(action.payload.lastDownloadedBy)) {
        return state;
      }
      return state > 0 ? state - 1 : 0;

    default:
      return state;
  }
}
