import angular from 'angular';
import app from '../../app';
import $sessionStorage from '../../utilities/storage/sessionStorage';
import tableTemplate from './patient-table.html';
import headerTemplate from './patient-table-header.html';
import rowTemplate from './patient-table-row.html';

import './styles.less';

angular
  .module(app)
  .component('patientTable', {
    template: tableTemplate,
    transclude: {
      header: '?tableHeader',
      body: '?tableBody'
    },
    bindings: {
      loadNextPage: '&'
    },
    controller: [
      'User',
      function patientTableController(User) {
        const ctrl = this;

        function setAnonymization(anonymize) {
          $sessionStorage.set('anonymizeName', anonymize);
          ctrl.anonymize = anonymize;
        }

        ctrl.anonymize = false;

        ctrl.toggleAnonymization = function toggleAnonymization() {
          const newValue = !ctrl.anonymize;

          User.updateUser({
            preferences: { patientsHidden: newValue }
          }).finally(function () {
            setAnonymization(newValue);
          });
        };

        ctrl.$onInit = function init() {
          ctrl.anonymize = $sessionStorage.get('anonymizeName') || false;

          User.getUser().then(function (user) {
            setAnonymization(user.preferences.patientsHidden);
          });

          // ???????
          // This is moved from the patients-list directive. I'm not sure why it's there
          // or even if it does what it claims. It may be vestigial to some of the since-removed
          // forced hiding of scrollbar on webkit browsers. I'm wrapping it in a try/catch for safety.
          // Git history links it back to a commit tagged with this story:
          // https://asthmapolis.jira.com/browse/SE-340
          // correct the patient list header width in browsers/platforms that do not hide the scroll bar
          try {
            const element = document.querySelector('patient-table');
            if (element.scrollWidth !== element.offsetWidth) {
              const header = element.querySelector('.patient-list-header');

              const headerRightPadding =
                parseFloat(header.css('paddingRight')) + element.offsetWidth - element.scrollWidth;

              header.css('paddingRight', `${headerRightPadding}px`);
            }
          } catch (e) {
            // do nothing
          }
        };
      }
    ]
  })
  .component('patientTableHeader', {
    require: {
      tableCtrl: '^patientTable'
    },
    template: headerTemplate,
    transclude: true,
    bindings: {
      queryOptions: '<',
      setSort: '&',
      setFilter: '&',
      analyticsView: '@'
    },
    controller: [
      '$timeout',
      function patientTableHeaderController($timeout) {
        const ctrl = this;

        let tooltipTimeout;
        let blockTimeout;
        let blockToolTips = false;
        const DELAY = 2500;

        ctrl.showBookmarkTooltip = false;
        ctrl.showEyeTooltip = false;
        ctrl.showSortTooltip = false;

        ctrl.toggleFavorites = function toggleFavorites() {
          return ctrl.setFilter({ value: 'favorites' });
        };

        function blockToolTipsTemporarily() {
          blockToolTips = true;
          $timeout.cancel(blockTimeout);

          blockTimeout = $timeout(function () {
            blockToolTips = false;
          }, DELAY);
        }

        function setupToggle(tooltip) {
          return function toggleTooltip(shouldShow) {
            if (blockToolTips) return;

            $timeout.cancel(tooltipTimeout);

            if (!shouldShow) {
              ctrl[tooltip] = false;
            } else {
              ctrl[tooltip] = true;
              tooltipTimeout = $timeout(function () {
                ctrl[tooltip] = false;
                blockToolTipsTemporarily();
              }, DELAY);
            }
          };
        }

        ctrl.toggleBookmarkTooltip = setupToggle('showBookmarkTooltip');
        ctrl.toggleEyeTooltip = setupToggle('showEyeTooltip');
        ctrl.toggleSortTooltip = setupToggle('showSortTooltip');
      }
    ]
  })
  .component('patientTableRow', {
    require: {
      tableCtrl: '^patientTable'
    },
    template: rowTemplate,
    transclude: true,
    bindings: {
      patient: '<'
    },
    controller: [
      'User',
      function patientTableRowController(User) {
        const ctrl = this;

        ctrl.setFavorite = function setFavorite() {
          const newValue = !ctrl.patient.favorite;

          return User.toggleFavorite(ctrl.patient.patientId, newValue).then(function () {
            ctrl.patient.favorite = newValue;
          });
        };
      }
    ]
  });
