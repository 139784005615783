import angular from 'angular';

import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    name: 'redox.**',
    url: '/redox',
    lazyLoad($transition$) {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

      return import('../../modules/redox/redox.module').then((mod) =>
        $ocLazyLoad.load(mod.REDOX_MODULE)
      );
    }
  });
});
