/* eslint-disable default-param-last */
import {
  RESET_ENROLLMENT_DATA,
  RESET_ENROLLMENT_PATIENT,
  ADD_PLAN_MEDICATION,
  REMOVE_PLAN_MEDICATION,
  SET_MEDICATION_SCHEDULE,
  SET_MEDICATION_DOSES,
  ADD_MEDICATION_SENSOR,
  REMOVE_MEDICATION_SENSOR,
  RESET_MEDICATIONS,
  MARK_MEDICATION_ADDED
} from '../../actions';

function updateMedSchedule(state, medId, schedule) {
  const medication = state.find(med => med.id === medId);

  if (!medication) {
    return state;
  }

  medication.schedule = schedule;
  medication.changed = medication.added;

  // state triggers on identity changes
  return state.slice(0);
}

function updateMedDoses(state, medId, doses) {
  const medication = state.find(med => med.id === medId);

  if (!medication) {
    return state;
  }

  medication.doses = doses;
  medication.changed = medication.added;

  // state triggers on identity changes
  return state.slice(0);
}

export default function enrollmentMedications(state = [], action) {
  const { medId } = action;

  switch (action.type) {
    case RESET_MEDICATIONS:
    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return [];

    case ADD_PLAN_MEDICATION: {
      const { plan = {} } = action;
      return state.concat({ id: medId, sensors: [], ...plan, doses: 1 });
    }

    case REMOVE_PLAN_MEDICATION:
      return state.filter((med) => med.id !== medId);

    case SET_MEDICATION_SCHEDULE:
      return updateMedSchedule(state, medId, action.schedule);

    case SET_MEDICATION_DOSES:
      return updateMedDoses(state, medId, action.doses);

    case ADD_MEDICATION_SENSOR: {
      const med = state.find((m) => m.id === medId);
      med.sensors = med.sensors ? med.sensors.concat(action.sensor) : [action.sensor];
      med.changed = med.added;

      return [].concat(state);
    }

    case REMOVE_MEDICATION_SENSOR: {
      const med = state.find((m) => m.id === medId);
      med.sensors = med.sensors.filter((s) => s !== action.sensor);
      med.changed = med.added;

      return [].concat(state);
    }

    case MARK_MEDICATION_ADDED: {
      const med = state.find((m) => m.id === medId);
      med.added = true;
      med.changed = false;

      return [].concat(state);
    }

    default:
      return state;
  }
}
