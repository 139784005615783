import angular from 'angular';

import app from '../../app';

/*
To use multiple views, a state should target named uiviews with a component.
Add a views: property to your state definition instead of a component...
The keys of the object are the names of the views being targeted and the values
are the component to render.
*/
angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    parent: 'main',
    name: 'billing.**',
    url: '/billing',
    lazyLoad($transition$) {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

      return import('../../modules/billing/billing.module').then((mod) =>
        $ocLazyLoad.load(mod.RTM_BILLING_MODULE)
      );
    }
  });
});

// https://ui-router.github.io/ng2/docs/2.0.1/classes/directives.uiview.html
// https://ui-router.github.io/guide/views#multiple-named-uiviews

/*
In AngularJS, each <ui-view> provides the state context to its children elements, such
as ui-sref or ui-view. The state context allows a ui-sref to use relative links, for example.
AngularJS provides this context by setting hidden data on its DOM element, using
angular.element(el).data('$uiView'). Any nested ui-view or ui-sref fetches the context
by asking for angular.element(childel).inheritedData('$uiView').

In React, each UIView provides the state context to its children elements using React
context. The nested UIView or UISref fetches the state context using the React context API.
There is some glue provided by @uirouter/react-hybrid which bridges these two context
mechanisms. When a React UIView component is rendered, it is wrapped in a UIRouterReactContext
component. The component finds the state context by looking first via React props, and second
via AngularJS DOM data. It then provides the state context to its children using React props.
The <react-ui-view-adapter> wraps a React UIView component. When the react UIView is filled by
a state's react component, the react-ui-view-adapter gets the state context for the newly
filled UIView. It then provides that context to AngularJS components using AngularJS DOM data.
https://github.com/ui-router/react-hybrid
*/
