import { useEffect, useRef } from 'react';

export default function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!(element && element.addEventListener)) {
      return;
    }

    // shadow the handler to ensure we have a correct reference
    // for removeEventListener (the one inside this scope)
    const listener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, listener);

    // returned function is run on every update (eg. when eventName of element changes)
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(eventName, listener);
    };
  }, [eventName, element]);
}
