import angular from 'angular';
import app from '../../app';
import { supportLocale } from '../../services/locales';

import template from './announcement-modal.html';
import './announcement-modal.less';

angular.module(app).component('announcementModal', {
  template,
  restrict: 'E',
  bindings: {
    resolve: '<',
    close: '&',
    modalInstance: '&'
  },
  controller: [
    '$window',
    function AnnouncementModalController($window) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.announcement = ctrl.resolve.announcement;
        ctrl.content = [].concat(ctrl.announcement.content); // convert to array

        const locale = ctrl.resolve.locale ? ctrl.resolve.locale : 'en-US';

        if (ctrl.announcement.href) {
          ctrl.announcement.href = ctrl.announcement.href.replace('{{LOCALE}}', supportLocale(locale));
        }
      };

      ctrl.closeModal = function closeModal() {
        ctrl.close();
      };

      ctrl.goToInfo = function goToInfo() {
        if (ctrl.announcement.href) {
          const opened = $window.open(ctrl.announcement.href, '_blank');

          if (!opened /* window instance */) {
            return;
          }
        }

        ctrl.close();
      };
    }
  ]
});
