import angular from 'angular';
import app from '../../app';

import template from './session-timeout.html';
import './session-timeout.less';

angular.module(app).directive('sessionTimeout', function($translatePartialLoader) {
  return {
    template,
    restrict: 'E',
    link() {
      $translatePartialLoader.addPart('directives/session-timeout');
    }
  };
});
