import angular from 'angular';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import app from '../../app';
import { fallbacks } from '../locales';
import $sessionStorage from '../../utilities/storage/sessionStorage';
import i18n from '../../i18n';

import { noop, uuidV4 } from '../../utilities';

const validClinicianRoles = ['admin', 'physician'];

angular
  .module(app)
  .factory('User', function(
    $http,
    $window,
    $location,
    $timeout,
    $translate,
    $q,
    $analytics,
    urls,
    Auth,
    AuthState,
    Logger,
    callOnce
  ) {
    let user = {};
    let signingOut = false;
    let updatingUser = false;

    if (!$sessionStorage.get('anonymousId')) {
      $sessionStorage.set('anonymousId', uuidV4());
    };

    function clearSession() {
      return $q(function(resolve) {
        user = {};
        AuthState.expiration = 0;
        $sessionStorage.clear();

        $timeout(() => {
          resolve();
        });
      });
    }

    function api2xRequest(request) {
      return merge(request, urls.get2xRequestDefaults());
    }

    function api3xRequest(request) {
      return merge(request, urls.getRequestDefaults());
    }

    // alters properties of the user object for better use on the front end
    function adaptUser(aUser) {
      if (!aUser || typeof aUser !== 'object') return undefined;

      return aUser;
      /*
      return  merge(user,{
        dob: moment(user.dob).format('MM/DD/YYYY')
      });
      */
    }

    function setLanguage(language) {
      $translate.fallbackLanguage(fallbacks(language));
      $translate
        .use(language)
        .then($translate.refresh)
        .then(() => {
          $sessionStorage.set('preferredLanguage', $translate.use());
        });

      i18n.changeLanguage(language);
    }

    // eslint-disable-next-line no-underscore-dangle
    function _signIn(data) {
        return $http(
          api3xRequest({
            method: 'POST',
            url: `${urls.getDomain()}/api/users/signIn`,
            data
          })
        ).then((res) => {
          const sessionUser = adaptUser(res.data);

          if (!validClinicianRoles.includes(sessionUser.role)) {
            // sign out and go to patient portal login
            // or just go to patient portal?
            $window.location = urls.getPatientPortalURL();
            // this return is just for consistency. Page will unload
            return null;
          }

          return sessionUser;
        });
      }

    const User = {
      // User.getUser() needs to be called before any boot-time call that requires authentication
      // It returns a promise and tracks flags so that calls in close proximity don't result in duplicate server calls
      getUser: callOnce(function getUser() {
        // check if there was a deffered request to sign the user out on the server
        return $q(function(resolve, reject) {
          if ($sessionStorage.get('signOutUser')) {
            User.signOut();
            return reject();
          }

          if (!isEmpty(user)) {
            return resolve(user);
          }

          return $http(
            api3xRequest({
              method: 'GET',
              url: `${urls.getUsersApi()}/me`
            })
          )
            .then(response => {
              const { data } = response;
              const userData = isEmpty(data.user) ? data : data.user;

              if (data.is_logged_in === false) {
                clearSession();

                return reject(response);
              }

              // update our temp cached version of the user with the updated fields
              merge(user, adaptUser(userData));
              $sessionStorage.set('user', user);
              $analytics.setUserProperties(user.id);
              setLanguage(user.language);

              return resolve(user);
            })
            .catch(response => {
              const cachedUser = $sessionStorage.get('user');

              if ((response.status < 0 || response.status >= 500) && !isEmpty(cachedUser)) {
                user = cachedUser;
                // $log.debug("Could not retrieve user, loading cached data");
                return resolve(user);
              }

              // $log.debug("Could not retrieve user, also no cached data");
              clearSession();

              return reject(response);
            });
        });
      }),

      updateUser(userUpdates) {
        if (updatingUser) {
          return $q.reject('Update already in progress...');
        }

        updatingUser = true;

        const request = api3xRequest({
          method: 'PATCH',
          url: `${urls.getUsersApi()}/${user.id}`,
          data: userUpdates
        });

        return $http(request)
          .then(function(response) {
            // If the user updated their language preference, set it on the session storage
            // so it's immediately available the next time they refresh.
            const { language } = userUpdates;
            if (language) {
              setLanguage(language);
            }
            // update our temp cached version of the user with the updated fields
            merge(user, userUpdates);

            return response;
          })
          .finally(function() {
            updatingUser = false;
          });
      },

      updatePassword(options) {
        if (updatingUser) {
          return $q.reject('Update already in progress...');
        }

        if (isEmpty(options)) {
          return $q.reject('Nothing to update...');
        }

        const request = api3xRequest({
          method: 'PUT',
          url: `${urls.getUsersApi()}/${user.id}/password`,
          data: options
        });

        updatingUser = true;

        return $http(request)
          .then(res => res.data)
          .finally(function() {
            updatingUser = false;
          });
      },

      toggleFavorite(patientId, enabled) {
        const request = api2xRequest({
          method: 'PUT',
          url: `${urls.getUsersApi()}/${user.id}/favorites`,
          data: {
            enabled: !!enabled,
            uid: patientId
          }
        });

        return $http(request);
      },

      getFavorites() {
        const request = api2xRequest({
          method: 'GET',
          url: `${urls.getUsersApi()}/${user.id}/favorites`
        });

        return $http(request).then(function(response) {
          return response && Array.isArray(response.data) ? response.data : [];
        });
      },

      getEmailPattern() {
        // eslint-disable-next-line no-control-regex
        return /^((([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
      },

      signOut(redirectUrl) {
        if (!redirectUrl) {
          // app root
          redirectUrl = $location.absUrl().replace($location.url(), '/');
        }

        const request = api3xRequest({
          method: 'POST',
          url: `${urls.getDomain()}/api/users/signOut`
        });

        if (signingOut) {
          return $q.reject('Already signing out...');
        }

        signingOut = true;

        /* this request will return 401 when made after cookie expiration */
        return $http(request)
          .catch(noop)
          .then(clearSession)
          .then(() => Auth.logout(redirectUrl))
          .catch(function(response) {
            clearSession();

            // if sign-out fails because of a bad connection to the server, we need to make sure the user
            // gets signed out of the server the next time they reconnect by setting the sessionStorage flag true
            if (!response.status || response.status < 0 || response.status >= 500) {
              $sessionStorage.set('signOutUser', true);
              // $log.debug('Could not sign out user on the server, but will perform on reconnect');
            }
          })
          .finally(function() {
            if (!isEmpty(user)) {
              $location.url('/');
            }
            signingOut = false;
          });
      },

      refreshSession: callOnce(function refreshSession(data) {
        return _signIn(data);
      }),

      signIn: callOnce(function signIn(data) {
        return _signIn(data)
          .then(sessionUser => {
            user = sessionUser;
            $sessionStorage.set('user', user);
            setLanguage(user.language);
            $sessionStorage.set('session_uuid', uuidV4());
            $analytics.setUserProperties(user.id);
            return user;
          })
          .catch(err => {
            // Logger.error('API SIGN IN ERROR', err);
            throw err;
          });
      })
    };

    return User;
  });
