import angular from 'angular';
import app from '../../../../app';
import template from './search-results.html';

const ASC = 'ascending';
const DESC = 'descending';

angular.module(app).component('searchResults', {
  template,
  bindings: {
    $transition$: '<'
  },
  controller: [
    '$state',
    'Patients',
    function searchResultsController($state, Patients) {
      // this will be freshly entered/initialized when search entry changes,
      // but it's debounced in the navbar directive
      const ctrl = this;
      let pagingToken = null;
      let destroyed = false;

      ctrl.loading = true;
      ctrl.patients = [];

      ctrl.columns = [
        {
          value: 'patient-dob',
          mainTitle: 'PATIENTLISTVIEW-HEADER-PATIENT-DOB'
        },
        {
          value: 'rescue-use',
          mainTitle: 'PATIENTLISTVIEW-HEADER-RESCUE-USE',
          subtitleText: 'PATIENTLISTVIEW-SUBHEADER-LAST-7-DAYS'
        },
        {
          value: 'adherence-score',
          mainTitle: 'PATIENTLISTVIEW-HEADER-ADHERENCE',
          subtitleText: 'PATIENTLISTVIEW-SUBHEADER-LAST-7-DAYS'
        },
        {
          value: 'last-sync-rescue',
          mainTitle: 'PATIENTLISTVIEW-HEADER-LAST-SYNC',
          subtitleText: 'PATIENTLISTVIEW-SUBHEADER-LAST-SYNC-RESCUE'
        },
        {
          value: 'last-contact',
          mainTitle: 'PATIENTLISTVIEW-HEADER-LAST-CONTACT',
          subtitleText: 'PATIENTLISTVIEW-SUBHEADER-LAST-CONTACT'
        }
      ];

      ctrl.queryOptions = {
        sortCategory: 'name',
        sortOrder: ASC,
        favorites: false,
        score: 'all',
        query: null,
        activeSensors: null
      };

      function searchPatients(options) {
        ctrl.loading = true;
        ctrl.patients = [];
        ctrl.listMessage = null;

        return Patients.getPatients(options)
          .then((result) => {
            if (destroyed) {
              return;
            }

            const { data, nextPage } = result;

            ctrl.patients = data;

            if (!nextPage) {
              pagingToken = null;
              ctrl.listMessage = 'PATIENTLISTVIEW-END-OF-LIST';
            } else if (nextPage && nextPage !== pagingToken) {
              pagingToken = nextPage;
            } else if (pagingToken === nextPage) {
              // I'm not sure what this case is doing / when it would appear.
              pagingToken = null;
            }
          })
          .catch(function (error) {
            // console.log('searchPatients', error);
            if (!destroyed) {
              ctrl.listMessage = error;
              ctrl.error = true;
            }
          })
          .then(function () {
            if (!destroyed) {
              ctrl.loading = false;
              ctrl.loadNextPage();
            }
          });
      }

      ctrl.setFilter = function setFilter(filter) {
        // why reset these?
        ctrl.queryOptions.sortOrder = ASC;

        if (filter === 'favorites') {
          // ctrl.queryOptions.activeSensors = true;
          ctrl.queryOptions.favorites = !ctrl.queryOptions.favorites;
        }

        return searchPatients(ctrl.queryOptions);
      };

      ctrl.setSort = function setSort(sortCategory) {
        if (ctrl.queryOptions.sortCategory === sortCategory) {
          // reverse direction
          const { sortOrder } = ctrl.queryOptions;
          ctrl.queryOptions.sortOrder = sortOrder === ASC ? DESC : ASC;
        } else if (sortCategory === 'name') {
          // Name sorts should sort default to ascending order (A-Z)
          ctrl.queryOptions.sortCategory = sortCategory;
          ctrl.queryOptions.sortOrder = ASC;
        } else {
          // All other sort categories sort by descending by default
          ctrl.queryOptions.sortCategory = sortCategory;
          ctrl.queryOptions.sortOrder = DESC;
        }

        return searchPatients(ctrl.queryOptions);
      };

      ctrl.loadNextPage = function loadNextPage() {
        if (!pagingToken || ctrl.loading) {
          return;
        }

        ctrl.loading = true;
        ctrl.listMessage = null;

        Patients.getPatients({ pagingToken })
          .then(function (response) {
            if (destroyed) {
              return;
            }
            const { data, nextPage, previousPage } = response;

            ctrl.patients = ctrl.patients.concat(data);

            pagingToken = nextPage && nextPage !== pagingToken ? nextPage : null;
            

            if (previousPage && !pagingToken) {
              ctrl.listMessage = 'PATIENTLISTVIEW-END-OF-LIST';
            }
          })
          .then(function () {
            if (!destroyed) {
              ctrl.loading = false;
            }
          });
      };

      ctrl.showPatientDetails = function (patient) {
        $state.go('patients.patient-detail', {
          userId: patient.patientId,
          filter: patient.disease
        });
      };

      ctrl.$onInit = function init() {
        const params = ctrl.$transition$.params();
        ctrl.queryOptions.query = params.searchQuery;

        searchPatients(ctrl.queryOptions);
      };

      ctrl.$onDestroy = function destroy() {
        destroyed = true;
      };
    }
  ]
});
