import angular from 'angular';
import app from '../../app';

import template from './template.html';

angular.module(app).component('verticallyCenteredContent', {
  template,
  transclude: true,
  bindings: {
    className: '@',
    role: '@'
  }
});
