/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
import logger from '../../utilities/logger';

// API v4.62 groupConfig tags and their parameters
const tags = {
  disease: ['enableAsthma', 'enableCopd'],
  enrollment: [
    'enableExternalIdPrompts',
    'enableHubs',
    'enablePractitionerEnrollment',
    'enableSelfEnrollment',
    'silenceSensors',
    'vimeoIdAsthma',
    'vimeoIdCopd'
  ],
  enrollPrompt: [
    'requireAct',
    'requireCat',
    'requireEthnicity',
    'requireGender',
    'requireMailingAddress',
    'requireRace',
    'requireTimeZone'
  ],
  rtm: ['enableRTM', 'rtmUndownloadedReportCount']
};

// we'll need to build this out to iterate over the attributes when there's more than one
// passed in & maybe other optimizations?
export function addTagParameters(parameters, tagName) {
  const queryElements = {};
  tags[tagName].forEach((attribute) => {
    queryElements[attribute] = parameters[attribute].value;
  });
  // console.log('addTagParameters return queryElements = ', queryElements);
  return queryElements;
}

/*
   expects queryParams to be an object in the shape of
   {
    include: 'parameters',
    tag: ['enrollPrompt', 'enrollment']
   }
   should reconsider the "include" part, probably
 */
export function normalizeGroupConfig(queryParams, config) {
  const { parameters, ...rest } = config;
  let queryElements;
  // console.log('normalizeGC has queryParams = ', queryParams);
  // console.log('normalizeGC has parameters = ', parameters);

  function callSwitch(value) {
    switch (value) {
      case 'rtm':
        queryElements = addTagParameters(parameters, 'rtm');
        break;
      case 'enrollPrompt':
        queryElements = addTagParameters(parameters, 'enrollPrompt');
        // console.log('*** queryElements for enrollPrompt = ', queryElements);
        break;
      case 'enrollment':
        queryElements = addTagParameters(parameters, 'enrollment');
        // console.log('*** queryElements for enrollment = ', queryElements);
        break;
      default:
        // anything we want to do if the query doesn't match anything expected?
        // we're defaulting to returning config as-is, below
        break;
    }
  }

  // if there's params, normalize
  if (queryParams && config.parameters) {
    // console.log('in IF');
    try {
      const queryArray = Object.entries(queryParams);
      // console.log('queryArray iterating through = ', queryArray);
      queryArray.forEach(([key, value]) => {
        // console.log(`key = ${key}; value = ${value}`);
        if (key === 'tag') {
          if (Array.isArray(value)) {
            value.forEach((nestedValue) => {
              // console.log('*** nested Value to switch = ', nestedValue);
              callSwitch(nestedValue);
            });
          } else {
            callSwitch(value);
          }
        }
      });
      const normalizedGroupConfig = { ...queryElements, ...rest };
      // console.log('returning normalizedGroupConfig = ', normalizedGroupConfig);
      return normalizedGroupConfig;
    } catch (error) {
      logger.error('error formatting the include=parameters results: ', error);
    }
  }
  // if there aren't any params, or stuff in the try block didn't work, then just return it
  // console.log('normalizedGroupConfig DID NOT WORK, returning config, ', config);
  return config;
}
