import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import cloneDeep from 'lodash/cloneDeep';
import moment from '../services/moment';

import { DATE_FORMAT } from './constants';

export const sortDates = (a, b) => (a.date > b.date ? 1 : -1);

export const addMetrics = (metrics, tzone) => (d) => {
  d.date = moment(d.date).tz(tzone);

  return metrics.reduce((datum, metric) => {
    datum.values[metric] = datum.values[metric] || 0;
    return datum;
  }, d);
};

export function shouldSkip(state, page) {
  const skip = state.endReached || page < state.pageLevel || state.inProgress;

  return skip;
}

export const isSameDay = (d1, d2) => d1.format(DATE_FORMAT) === d2.format(DATE_FORMAT);

// this was the prior implmentaation...
// can we just compare isoWeek and isoWeekYear?
export const dayWithinWeek = (week) => (day) =>
  week.date.isoWeek() === day.date.isoWeek() &&
  day.date.isBetween(moment(week.date).subtract(1, 'days'), moment(week.date).add(7, 'days'));

export const transitionDuration = 600;

export const graphHeight = 175;
export const graphWidth = 850;

export const graphMargins = {
  top: 5,
  right: 0,
  bottom: 35,
  left: 30
};

export const chartWidth = graphWidth - graphMargins.left - graphMargins.right;
export const chartHeight = graphHeight - graphMargins.top - graphMargins.bottom;

const sensorDates = (dateAttribute) => (sensors) =>
  sensors
    .map((aSensor) => aSensor[dateAttribute])
    .filter(Boolean)
    .sort();

// we want the most recent last sync date,
// eg. if they have 2 sensors, one syced a week ago
// and the other synced yesterday, we want yesterday
export const lastSyncDate = (aMedication) => {
  const sensors = aMedication.sensors || [];
  const dates = sensorDates('lastSyncDate')(sensors).reverse();

  // is there a case where we want the oldest?

  return dates[0];
};

// we want the oldest first sync date,
// eg. if they have 2 sensors, one synced a week ago
// and the other synced yesterday, we want the month ago
export const firstSyncDate = (aMedication) => {
  const sensors = aMedication.sensors || [];
  const dates = sensorDates('firstSyncDate')(sensors);

  return dates[0];
};

export const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

export const trendTypeKey = (type) => (trendName) =>
  `trend${capitalize(type)}${capitalize(trendName)}`;

// eslint-disable-next-line default-param-last
export function appendLeadingDays(arrayToExtend = [], startDate, timeZone) {
  const leadingDate = moment(startDate).tz(timeZone).startOf('day');

  // if our oldest date is before our current oldest date...
  // or we don't have any data
  // (either case would presumably be because the events loaded before the metrics)
  if (!arrayToExtend[0] || leadingDate.isBefore(arrayToExtend[0].date)) {
    // we want to fill in the days, so we either fill before our current oldest day
    // or before tomorrow (so that we include today)
    const currentOldestDate = arrayToExtend[0]
      ? arrayToExtend[0].date
      : moment().add(1, 'day').tz(timeZone).startOf('day');

    // get the number of days to fill
    const dayGap = currentOldestDate.diff(leadingDate, 'days');

    // create them with just a simple object containing the date
    return [...Array(dayGap)]
      .map((_, idx) => ({
        date: leadingDate.clone().add(idx, 'days')
      }))
      .concat(arrayToExtend);
  }

  return arrayToExtend;
}

// eslint-disable-next-line default-param-last
export function structureData(newData = [], existingData = [], metrics, timeZone) {
  if (newData.length === 0) {
    return existingData;
  }

  let currentData = [].concat(existingData);

  const newDays = newData.sort(sortDates).map(addMetrics(metrics, timeZone));

  // If we don't have any data yet, just set what we have. easy peasy.
  if (currentData.length === 0) {
    return newDays;
  }

  // if we *do* have current data, we need to add our new data.
  // There may be an overlap. Probably safest is to add any leading days
  const firstDate = newDays[0].date;

  currentData = appendLeadingDays(currentData, firstDate, timeZone);

  // because our existing data is manipulated by metrics and events calls,
  // there is a chance the start of the new data is after the start of
  // the exiting data, so we want to find where we start placing our data
  const idxOffset = currentData.findIndex((day) => day.date.isSame(firstDate, 'day'));

  // these newDays won't have any gaps, so we can iterate the days
  // and add the appropriate data to our currentDays
  newDays.forEach((day, idx) => {
    currentData[idx + idxOffset].values = day.values;
  });

  return currentData;
}

export function loadPagedData(method, endPage, $q) {
  return function loadDataPage(state, results = []) {
    if (typeof endPage === 'number' && state.pageLevel > endPage) {
      return $q.when({ data: results });
    }

    const args = state.nextArgs;

    return method(args).then((res) => {
      results = results.concat(res.data);

      if (!res.nextPage || (res.nextPage && args.pagingToken === res.nextPage)) {
        // last page
        state.endReached = true;
        state.nextArgs = undefined;

        return $q.resolve({ data: results });
      }

      // eslint-disable-next-line no-plusplus
      if (typeof state.pageLevel === 'number') ++state.pageLevel;

      state.nextArgs = {
        userId: args.userId,
        pagingToken: res.nextPage
      };

      return loadDataPage(state, results);
    });
  };
}

export function hasOwn(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function isPresent(obj) {
  return !isEmpty(obj) && !isNull(obj);
}

export function isMissing(obj) {
  return isEmpty(obj) || isNull(obj);
}

export function isString(aString) {
  return typeof aString === 'string';
}

export function invalidString(aString) {
  return isMissing(aString) || !isString(aString);
}

export function isObject(aValue) {
  return aValue && aValue instanceof Object && !Array.isArray(aValue);
}

// returns true only if a) value passed in is an object (Object, incl. array) and b) it has length
export function isEmptyObject(obj) {
  if (!obj) return false;
  if (typeof obj !== 'object') return false;
  if (Object.keys(obj).length === 0) return true;
  return false;
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(function (key) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

export function deeplyClone(objToClone) {
  if (isPresent(objToClone) && isObject(objToClone)) {
    return cloneDeep(objToClone);
  }
  return objToClone;
}

// used in [].filter to return an array with only unique items, simple comparisons
export function onlyUnique(aValue, index, anArray) {
  return anArray.indexOf(aValue) === index;
}

// used to manually trigger onChange in some react inputs
export function triggerChange(input, dummyValue = '#') {
  const descriptor = Object.getOwnPropertyDescriptor(input, 'value');

  const initialValue = input.value;
  input.value = initialValue + dummyValue;
  delete input.value;
  input.value = initialValue;

  // IE doesn't support Event constructor
  const event = document.createEvent('HTMLEvents');
  event.initEvent('input', true, false);
  input.dispatchEvent(event);

  Object.defineProperty(input, 'value', descriptor);
}

export function noop() {}

export function hasItems(anArray) {
  return Array.isArray(anArray) && anArray.length > 0;
}

export const uuidV4 = (a) =>
  a
    ? // eslint-disable-next-line no-bitwise
      (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuidV4);

export const randomHex = (a) =>
  a
    ? /* eslint-disable-next-line no-bitwise */
      (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : (1e7).toString().replace(/[01]/g, randomHex);

export function isResponseObject(anObject) {
  return isPresent(anObject) && typeof anObject.status === 'number' && Boolean(anObject.xhrStatus);
}

// This is not great, but Good Enough(TM) for now.
// Once we're totally off AngularJS we'll probably abstract around `fetch` more completely.
export function isFetchResponse(anObject) {
  return isPresent(anObject) && typeof anObject.status === 'number' && !isResponseObject(anObject);
}

export function responseForLog(response) {
  const headers = typeof response.headers === 'function' ? response.headers() : response.headers;

  return {
    status: response.status,
    headers,
    statusText: response.statusText,
    data: response.data,
    config: {
      url: response.config.url,
      method: response.config.method,
      headers: response.config.headers
    }
  };
}

export function displayResponseError(response, text) {
  let message = text ? `${text}\n` : '';

  try {
    const { status, statusText, config = {}, data } = response;
    message += `${status} ${statusText}\nURL: ${config.url}`;

    const headers = typeof response.headers === 'function' ? response.headers() : response.headers;
    const type = headers['content-type'] || 'text/plain';
    if (type.match(/\bjson\b/i)) {
      message += `\n\n${JSON.stringify(data, null, 4)}`;
    }
  } catch (_) {
    // nothing
  }

  return message;
}

export function displayInitials(aPerson, country) {
  if (isMissing(aPerson)) {
    return '';
  }

  const localeCountry = aPerson.language ? aPerson.language.split('-')[1] : null;
  country = country || localeCountry;

  // order depends on locale (country?), eg. in Japan family name is first
  switch (country) {
    case 'JP':
      return (aPerson.familyName[0] || '') + (aPerson.givenName[0] || '');

    default:
      return (aPerson.givenName[0] || '') + (aPerson.familyName[0] || '');
  }
}

export function displayName(aPerson, country) {
  if (isMissing(aPerson)) {
    return '';
  }

  const localeCountry = aPerson.language ? aPerson.language.split('-')[1] : null;
  country = country || localeCountry;

  // order depends on locale (country?), eg. in Japan family name is first
  switch (country) {
    case 'JP':
      return `${aPerson.familyName} ${aPerson.givenName}`;

    default:
      return `${aPerson.givenName} ${aPerson.familyName}`;
  }
}
