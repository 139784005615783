import React from 'react';
import classNames from 'classnames';
import { number, string } from 'prop-types';

import './styles.less';

function NotificationCount({ count, labelText }) {
  if (count > 0) {
    return (
      <div
        className={classNames('notification-count', {
          'greater-than-eleven': count > 11
        })}
        aria-live="polite"
        aria-label={labelText}
      >
        <span className="count-span">{count <= 999 ? count : '999+'}</span>
      </div>
    );
  }

  return null;
}

NotificationCount.propTypes = {
  count: number,
  labelText: string.isRequired
};

NotificationCount.defaultProps = {
  count: 0
};

export default NotificationCount;
