import React, { Suspense, useRef } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ModalManager from 'react-overlays/lib/ModalManager';
import { node } from 'prop-types';
import '../../i18n';

import Loading from '../Loading';
import injectAngularService from '../../utilities/injectAngularService';
import { AngularInjectorProvider } from '../../hooks/AngularInjectorContext';
import { ModalManagerProvider } from '../../hooks/useModalManager';

import store from '../../store';

function AngularToReactBridge({ children }) {
  // I don't want to take the chance that this gets
  // swapped out on rerenders. Is that not a concern at this level?
  const manager = useRef(new ModalManager());

  return (
    <ReduxProvider store={store}>
      <ModalManagerProvider manager={manager.current}>
        <AngularInjectorProvider injector={injectAngularService}>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </AngularInjectorProvider>
      </ModalManagerProvider>
    </ReduxProvider>
  );
}

AngularToReactBridge.propTypes = {
  children: node.isRequired
};

export default AngularToReactBridge;
