import angular from 'angular';
import app from '../../app';

import template from './last-contact.html';
import './styles.less';

angular.module(app).component('lastContact', {
  template,
  bindings: {
    contact: '<',
    timeZone: '<'
  }
});
