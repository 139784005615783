import angular from 'angular';
import Keycloak from 'keycloak-js';
import app from '../../app';

import $localStorage from '../../utilities/storage/localStorage';

// tokens are not Base64 encoded, they're base64Url encoded
// base64 methods don't handle unicode well. This should do better.
function parseToken(aToken) {
  let segment = aToken
    .split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  // make sure segment is of correct length
  switch (segment.length % 4) {
    case 0:
      break;
    case 2:
      segment += '==';
      break;
    case 3:
      segment += '=';
      break;
    default:
      throw new Error('Invalid token');
  }

  // handle unicode characters
  try {
    segment = decodeURIComponent(
      atob(segment).replace(/(.)/g, function(m, p) {
        let code = p
          .charCodeAt(0)
          .toString(16)
          .toUpperCase();
        if (code.length < 2) {
          code = `0${code}`;
        }
        return `%${code}`;
      })
    );
  } catch (e) {
    segment = atob(segment);
  }

  return JSON.parse(segment);
}



angular.module(app).service('AuthState', [
  '$cookies',
  'env',
  function($cookies, env) {
    const storageKey = 'invalidAfter';
    const authURL = `${env.get('keycloakDomain')}/auth`;
    const keycloakRealm = 'ph';
    const keycloakClientId = env.get('keycloakClientId');
    const permittedRoles = ['ph_admin', 'ph_physician'];

    const auth = {};

    const kc = new Keycloak({
      url: authURL,
      realm: keycloakRealm,
      clientId: keycloakClientId
    });

    function writeStorage(key, value) {
      $localStorage.set(key, value);
      $cookies.put(key, value, { samesite: 'strict' });
      return value;
    }

    function readStorage(key) {
      return $localStorage.get(key) || $cookies.get(key);
    }

    function getForMode(item) {
      // eslint-disable-next-line no-use-before-define
      switch (AuthState.mode) {
        case 'login':
          return kc[item];

        case 'exchange':
          return auth[item];
        default:
          return null;
      }
    }

    const AuthState =  {
      setTokens(accessToken, refreshToken) {
        auth.tokenParsed = parseToken(accessToken);
        auth.token = accessToken;
        auth.refreshToken = refreshToken;
        auth.subject = auth.tokenParsed.sub;
        auth.realmAccess = auth.tokenParsed.realm_access;
      },

      clear() {
        Object.keys(auth).forEach(key => delete auth[key]);
      },

      get canAccess() {
        const parsed = this.parsedToken;
        if (!parsed) return false;

        // can the access token tell us if we have a fully qualified person?
        // inspect roles, but could we also use email_verified or anything else?
        try {
          // inspect token to see if they're valid
          const { roles: userRoles } = parsed.realm_access;

          // check to see if the user's roles intersect with the allowed roles
          // does it make a difference which direction we check this in?
          return permittedRoles.some(role => userRoles.includes(role));
        } catch (e) {
          return false;
        }
      },

      get keycloak() {
        return kc;
      },

      get token() {
        return getForMode('token');
      },

      get refreshToken() {
        return getForMode('refreshToken');
      },

      get parsedToken() {
        return getForMode('tokenParsed');
      },

      get clientId() {
        return keycloakClientId;
      },

      get mode() {
        return auth.mode;
      },

      set mode(mode) {
        auth.mode = mode;
      },

      get expiration() {
        return readStorage(this.storageKey);
      },

      set expiration(time) {
        writeStorage(this.storageKey, time);
      },

      get storageKey() {
        return storageKey;
      }
    };

    return AuthState;
  }
]);
