/* eslint-disable no-plusplus */
import angular from 'angular';
import intersectionWith from 'lodash/intersectionWith';

import app from '../../app';
import $sessionStorage from '../../utilities/storage/sessionStorage';

import template from './patients-list.html';
import './patients-list.less';

const FILTER_OPTIONS = [
  {
    text: 'ASTHMA',
    value: 'asthma',
    analyticsLabel: 'list_filter_asthma'
  },
  {
    text: 'COPD',
    value: 'copd',
    analyticsLabel: 'list_filter_copd'
  },
  {
    text: 'SENSOR_INACTIVE',
    value: 'sensor-inactive',
    analyticsLabel: 'list_filter_sensor_inactive'
  }
];

angular
  .module(app)
  .directive(
    'patientsList',
    function (Patients, Patient, $timeout, $translatePartialLoader, $state, $ngRedux, Logger) {
      return {
        template,
        restrict: 'EA',
        replace: true,
        scope: {
          careteam: '<',
          listFilter: '<'
        },
        link(scope, element) {
          Logger.debug('patientsList: entering');

          $translatePartialLoader.addPart('directives/patients-list');

          const ASC = 'ascending';
          const DESC = 'descending';

          const subscribe = $ngRedux.connect((state) => ({
            clinicians: state.groupClinicians,
            groupConfig: state.groupConfig
          }));

          const unsubscribe = subscribe(scope);

          let pagingToken = null;

          scope.patients = [];
          scope.filterOptions = [];

          scope.role = $sessionStorage.get('user').role;

          scope.loadingPatients = false;
          scope.loadingMorePatients = false;

          scope.listMessage = '';
          scope.patientListError = false;

          scope.patientListOptions = {
            disease: 'asthma',
            sortCategory: 'rescue-use',
            sortOrder: DESC,
            favorites: false,
            score: 'all',
            activeSensors: null,
            careTeamOptionId: scope.role === 'admin' ? scope.careteam : undefined
          };

          scope.noPatientsFound = false;       

          // LIST FILTER FUNCTIONS
          // Called by the left side filter options to change the list filter
          scope.updateListFilter = function (filter) {
            const careteam = scope.role === 'admin' ? scope.careteam : undefined;

            $state.go('patients', {
              filter,
              careteam
            });
          };

          // TODO: update the name of this to be more reflective of its functionality
          scope.setListFilter = function (filter) {
            // Wait for the current request to finish before starting a new one
            if (scope.loadingPatients || scope.loadingMorePatients) {
              return Promise.resolve();
            }

            // Reset the column sort to defaults
            scope.patientListOptions.sortCategory = 'rescue-use';
            scope.patientListOptions.sortOrder = DESC;
            scope.patientListOptions.score = 'all';

            // Reset the paging token
            pagingToken = null;

            // Only show sensor inactive patients regardless of disease
            if (filter === 'sensor-inactive') {
              scope.patientListOptions.disease = '';
              scope.patientListOptions.activeSensors = false;
              scope.patientListOptions.sortCategory = 'last-sync-rescue';
            } else if (['copd', 'asthma'].indexOf(filter) > -1) {
              // is this a specific disease filter?
              scope.patientListOptions.disease = filter;
              scope.patientListOptions.activeSensors = true;
            } else if (filter === 'favorites') {
              scope.patientListOptions.activeSensors = true;
              scope.patientListOptions.favorites = !scope.patientListOptions.favorites;
              // if listFilter is sensor-inactive, need to include score = 'unknown' so it has the right params to make the call
              if (scope.listFilter === 'sensor-inactive') {
                scope.patientListOptions.activeSensors = false;
              }
            } else {
              scope.patientListOptions.activeSensors = true;
              // set the default filter preferences here
              scope.patientListOptions.disease = 'asthma'; // TODO: get the provider's disease from their user preferences
            }

            // eslint-disable-next-line no-use-before-define
            return getPatients();
          };

          // PATIENT LIST HEADER FUNCTIONS
          scope.setSort = function (sortCategory) {
            // Called by the column headers when the user wishes to alternate the sort by category
            // and to toggle ascending or descending sort
            if (scope.loadingPatients || scope.loadingMorePatients) {
              return Promise.resolve();
            }

            // Reset the paging token
            pagingToken = null;

            // If the user picked the category we are already sorting by, reverse the sort order
            if (sortCategory === scope.patientListOptions.sortCategory) {
              scope.patientListOptions.sortOrder =
                scope.patientListOptions.sortOrder === ASC ? DESC : ASC;
            } else if (sortCategory === 'name') {
              // Only name sorts should sort by ascending order (A-Z)
              scope.patientListOptions.sortCategory = sortCategory;
              scope.patientListOptions.sortOrder = ASC;
            } else {
              // All other sort categories sort by descending by default
              scope.patientListOptions.sortCategory = sortCategory;
              scope.patientListOptions.sortOrder = DESC;
            }

            // eslint-disable-next-line no-use-before-define
            return getPatients();

          };


          // Figure out way to consolidate this function and the getMorePatients function
          function getPatients() {
            scope.loadingPatients = true;
            scope.listMessage = '';
            scope.patientListError = false;
            scope.patients = [];
            scope.noPatientsFound = false;

            // this very closely follows what's in searchResults.js
            return Patients.getPatients(scope.patientListOptions)
              .then(function (results) {
                const { data, nextPage } = results;

                scope.patients = data;

                scope.noPatientsFound = scope.patients.length === 0;

                if (!nextPage) {
                  pagingToken = null;
                  scope.listMessage = 'PATIENTLISTVIEW-END-OF-LIST';
                } else if (nextPage && nextPage !== pagingToken) {
                  pagingToken = nextPage;
                } else if (pagingToken === nextPage) {
                  // I'm not sure what this case is doing / when it would appear.
                  pagingToken = null;
                }

                $timeout(function () {
                  element.find('.patient-list')[0].scrollTop = 0;
                });
              })
              .catch(function (error) {
                scope.listMessage = error;
                scope.patientListError = true;
                // console.log(error)
              })
              .finally(function () {
                scope.loadingPatients = false;
                scope.getMorePatients(); 
              });

          }

          scope.getMorePatients = function () {
            if (!pagingToken || scope.loadingPatients || scope.loadingMorePatients) {
              return;
            }

            scope.loadingMorePatients = true;
            scope.listMessage = '';
            scope.physicianFilterOptions = {pagingToken, careTeamOptionId: scope.patientListOptions.careTeamOptionId};

            Patients.getPatients(scope.physicianFilterOptions)
              .then(function (result) {
                const { data, nextPage } = result;

                scope.patients = scope.patients.concat(data);
                pagingToken = nextPage && nextPage !== pagingToken ? nextPage : null;

                // why not just check pagingToken?
                if (pagingToken == null || !pagingToken) {
                  scope.listMessage = 'PATIENTLISTVIEW-END-OF-LIST';
                }
              })
              .finally(function () {
                scope.loadingMorePatients = false;
              });
          };

          // PATIENT ROW FUNCTIONS
          // Launches the patient modal (baseball card)
          scope.showPatientDetails = function (patient) {
            $state.go('.patient-detail', {
              userId: patient.patientId
            });
          };

          // consider combining with update filter
          scope.getCareTeamOptionsPatients = function (clinicianId) {
            $state.go('patients', {
              filter: scope.listFilter,
              careteam: clinicianId
            });
          };

          // PATIENT LIST INITIALIZE CODE
          // if the user toggled a patient's favorite/bookmark status somewhere, we may
          // have to refresh our list view to sync these changes
          scope.$on('patient-favorite-updated', function () {
            getPatients();
          });

          scope.$on('$destroy', () => {
            Logger.debug('patientsList: leaving');
            unsubscribe();
          });

          // this will run at least once with the initial config
          scope.$watch('groupConfig', (groupConfig) => {
            if (groupConfig) {
              Logger.debug('patientsList: set disease filters');

              const validFilters = groupConfig.diseases.concat('sensor-inactive');

              scope.filterOptions = intersectionWith(
                FILTER_OPTIONS,
                validFilters,
                (option, filter) => option.value === filter
              );
            }
          });

          scope.setListFilter(scope.listFilter);
        }
      };
    }
  );
