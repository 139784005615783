// const COUNTRIES = [
//   ['AR', '54'],
//   ['AT', '43'],
//   ['AU', '61'],
//   ['CA', '1'],
//   ['CH', '41'],
//   ['CZ', '420'],
//   ['DE', '49'],
//   ['DK', '45'],
//   ['ES', '34'],
//   ['FI', '358'],
//   ['FR', '33'],
//   ['GB', '44'],
//   ['GR', '30'],
//   ['IE', '353'],
//   ['IT', '39'],
//   ['JP', '81'],
//   ['KR', '82'],
//   ['MX', '52'],
//   ['NL', '31'],
//   ['NO', '47'],
//   ['PT', '351'],
//   ['RU', '7'],
//   ['SE', '46'],
//   ['US', '1']
// ];

const PHONE_RX = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\d{1,14})$/;

// add display formats?
// prettier-ignore
const VALIDATORS_BY_CODE = {
  '1': /^\d{10}$/,
  '7': null,
  '30': null,
  '31': null,
  '33': null,
  '34': null,
  '39': null,
  '41': null,
  '43': null,
  '44': null,
  '45': null,
  '46': null,
  '47': null,
  '49': null,
  '52': null,
  '54': null,
  '61': null,
  '81': null,
  '82': null,
  '351': null,
  '353': null,
  '358': null,
  '420': null
};

export function formatPhone(phoneNumber) {
  if (typeof phoneNumber !== 'string') {
    throw new Error('Invalid phone number to format');
  }

  return `+${phoneNumber.replace(/[^\d]/g, '')}`;
}

export function isPossiblePhoneNumber(phoneNumber) {
  if (typeof phoneNumber !== 'string') {
    throw new Error('Invalid phone number to test');
  }

  const trimmed = formatPhone(phoneNumber);
  const result = PHONE_RX.exec(trimmed);

  if (!result) return false;

  // eslint-disable-next-line no-unused-vars
  const [_, code, digits] = result;
  const validator = VALIDATORS_BY_CODE[code];

  if (!validator) return true;

  return validator.test(digits);
}
