import injectAngularService from '../../utilities/injectAngularService';
import { makeActionCreator } from '../utilities';

export const SET_CURRENT_PATIENT = 'PATIENT:SET_CURRENT';
export const UNSET_CURRENT_PATIENT = 'PATIENT:UNSET_CURRENT';

export const setCurrentPatient = makeActionCreator(SET_CURRENT_PATIENT, 'patient');
export const unsetCurrentPatient = makeActionCreator(UNSET_CURRENT_PATIENT);

export function loadCurrentPatient(userId) {
  return function (dispatch) {
    const PatientService = injectAngularService('Patient');
    return PatientService.getUserById(userId)
      .then(function (patient) {
        // sort meds list by num of sensors
        patient.plan.medications = patient.plan.medications.sort((m) => -m.sensors.length);
        PatientService.setFollowers(patient);        
        return PatientService.getDefaultCaregiver(patient)
          .then((caregiverData) => {
            patient.primaryCaregiver = caregiverData;
            return patient;
          })
          .catch(() => patient);
      })
      .then((patient) => {
        dispatch(setCurrentPatient(patient));
        return patient;
      });
  };
}
