/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import angular from 'angular';
import app from '../../app';
import { AccessError } from '../../utilities/errors';

// No 'state' parameter found. Please (re)launch the app.
// angular.element(document.body).injector().get('$state').go('patients', { filter: 'asthma' })

function transformFormPOST(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
    .replace(/%20/g, '+');
}

angular.module(app).factory('Auth', [
  '$window',
  '$q',
  '$http',
  'AuthState',
  'Logger',
  'callOnce',
  function ($window, $q, $http, AuthState, Logger, callOnce) {
    const initOptions = {
      checkLoginIframe: false,
      promiseType: 'native'
    };

    function shouldLogout() {
      const logoutTime = Number(AuthState.expiration);

      return logoutTime && logoutTime <= Date.now();
    }

    function manualUpdateToken(minValidity = 5) {
      Logger.debug('Manually update token');
      let doUpdate = minValidity < 0;

      if (!AuthState.refreshToken) {
        throw new Error('No refresh token');
      }

      // do we need to contend with skew??
      const { exp } = AuthState.parsedToken;
      let expiresIn = exp - Math.ceil(new Date().getTime() / 1000);
      if (minValidity) expiresIn -= minValidity;

      doUpdate = doUpdate || expiresIn < 0;

      if (!doUpdate) {
        Logger.debug('Skip update, not expired');
        return $q.resolve(AuthState.token);
      }

      const params = {
        grant_type: 'refresh_token',
        refresh_token: AuthState.refreshToken,
        client_id: AuthState.clientId
      };

      return $http
        .post(AuthState.keycloak.endpoints.token(), params, {
          transformRequest: transformFormPOST,
          headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
          }
        })
        .then((response) => {
          const { access_token, refresh_token } = response.data;
          AuthState.setTokens(access_token, refresh_token);
          return AuthState.token;
        });
    }

    const Auth = {
      login: callOnce(function login() {
        if (AuthState.token) {
          return $q.resolve(AuthState.token);
        }

        const kc = AuthState.keycloak;

        // we want to wrap in a $q promise because that is tied to angular's scope digest/update process
        return $q
          .resolve()
          .then(() => kc.init(initOptions))
          .then(function (authenticated) {
            if (shouldLogout()) {
              AuthState.expiration = 0;
              kc.logout({ redirectUri: kc.createLoginUrl() });
            } else if (!authenticated) {
              kc.login();
            } else {
              AuthState.mode = 'login';
              return kc.token;
            }
          })
          .catch(function (err) {
            Logger.error('Keycloak login error', err);
            $window.location.reload();
          });
      }),

      // do we want to inspect the token here or is that the job of the caller?
      exchangeToken(idToken) {
        const kc = AuthState.keycloak;

        const requestData = {
          client_id: AuthState.clientId,
          grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
          subject_token_type: 'urn:ietf:params:oauth:token-type:jwt',
          subject_token: idToken,
          requested_token_type: 'urn:ietf:params:oauth:token-type:refresh_token'
        };

        return $q
          .resolve()
          .then(() =>
            kc.init(initOptions).catch((err) => {
              Logger.error('Keycloak init error', err);
              $window.location.reload();
            })
          )
          .then(() =>
            $http.post(kc.endpoints.token(), requestData, {
              transformRequest: transformFormPOST,
              headers: {
                'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
              }
            })
          )
          // eslint-disable-next-line arrow-body-style
          .then((response) => {
            // we don't want to have the KC object think we're signed in (to enforce
            // boundary between parts of the application), so we need to set up something
            // to manage tokens ourselves, expired cookies, deal with 401s (or whatever), etc.

            return Auth.initializeWithTokens(response.data);
          })
          .then(() => Auth.updateToken(-1))
          .catch((err) => {
            Logger.error('Token exchange error', err);
            // if err is a response, data is likely null and status -1
            // which may be indiciatve of a CORS error
            // or an opaque error that's interpreted as CORS
            // could also be a request timeout, but likely something
            // tied to a failure to exchange the token (expiration, etc)

            throw err;
          });
      },

      initializeWithTokens(tokens) {
        return $q.resolve().then(() => {
          const { access_token, refresh_token } = tokens;

          AuthState.mode = 'exchange';
          AuthState.setTokens(access_token, refresh_token);

          if (!AuthState.canAccess) {
            AuthState.clear();
            throw new AccessError();
          }

          return AuthState.token;
        });
      },

      updateToken(minValidity = 5) {
        return $q.resolve().then(() => {
          const { mode } = AuthState;

          switch (AuthState.mode) {
            case 'login':
              return AuthState.keycloak.updateToken(minValidity).then(() => AuthState.token);

            case 'exchange':
              return manualUpdateToken(minValidity);

            default:
              throw new Error(`Invalid authentication mode: "${mode}"`);
          }
        });
      },

      logout(url, showLogin = true) {
        const kc = AuthState.keycloak;

        // In most cases we want to redirect to login screen rather than first hitting the app.
        // For the SMART app we'll just want to display a message to start over.
        const redirectUri = showLogin ? kc.createLoginUrl({ redirectUri: url }) : url;

        return kc.logout({ redirectUri });
      },

      constants: {
        INVALID_TOKEN: 'invalid_token'
      }
    };

    return Auth;
  }
]);
