import { node, shape } from 'prop-types';
import React, { createContext, useContext } from 'react';
import ModalManager from 'react-overlays/lib/ModalManager';

const ModalManagerContext = createContext(new ModalManager());

export function ModalManagerProvider({ manager, children }) {
  return <ModalManagerContext.Provider value={manager}>{children}</ModalManagerContext.Provider>;
}

ModalManagerProvider.propTypes = {
  children: node.isRequired,
  manager: shape({}).isRequired
};

export const useModalManager = () => useContext(ModalManagerContext);
