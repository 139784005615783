import angular from 'angular';
import app from '../../app';

import template from './favorite-patient-toggle.html';
import './styles.less';

angular.module(app).component('favoritePatientToggle', {
  template,
  transclude: true,
  bindings: {
    showIcon: '<',
    onToggle: '&'
  },
  controller: [
    '$timeout',
    function favoritePatientController($timeout) {
      const ctrl = this;
      ctrl.showTooltip = false;

      const delay = 2500;
      let timer;

      ctrl.onClick = function onClick() {
        ctrl.onToggle().then(() => {
          ctrl.showTooltip = false;
          $timeout.cancel(timer);
        });
      };

      ctrl.toggleTooltip = function toggleTooltip(show) {
        ctrl.showTooltip = show;

        if (!show) {
          $timeout.cancel(timer);
        } else {
          timer = $timeout(function () {
            ctrl.showTooltip = false;
          }, delay);
        }
      };
    }
  ]
});
