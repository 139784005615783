import angular from 'angular';
import merge from 'lodash/merge';
import app from '../../app';
import { formatQueryParams } from '../../utilities/dataSubmission';

// things we use from 3x not on 4x:
// diseases (now in 4.62)
// providerEnrollmentEnabled (now in 4.62)
// mailingAddressRequired (now in 4.62)
// demographics
// clinicalTrial
// actRequired (now in 4.62)
// catRequired (now in 4.62)
// hubAllowed (now in 4.62)
// asthmaVimeoId (now in 4.62)
// copdVimeoId (now in 4.62)
// medicalIdsRequred
// preemptiveRequired
// strictDoseMedicationList
// medications

angular.module(app).factory('Group', function ($q, $http, urls, callOnce) {
  let groupConfig;
  let medicationList;
  let medicationDict;

  const GroupService = {
    setDefaultGroup: callOnce(function setDefaultGroup(groupId) {
      if (!groupId) {
        return $q.reject('No group id specified');
      }

      if (typeof groupId !== 'string') {
        return $q.reject('Bad Group Id supplied');
      }

      const url = `${urls.getGroupsApi()}/${groupId}/config`;
      const request = merge({ url }, urls.getRequestDefaults());

      return $http(request).then((response) => {
        groupConfig = response.data;
        medicationList = groupConfig.medications;
        medicationDict = {};

        // does this need to be in a try/catch?
        try {
          medicationList.forEach((med) => {
            medicationDict[med.id] = med;
          });
        } catch (e) {
          // nothing
        }

        return response.data;
      });
    }),

    // available on 4x via `${urls.getGroupsApi()}/${group.name}/practitioners`
    // but response is paginated (we'd need to load all pages) and data is structured
    // differently, (separate given and family names), so we may want to consider
    // implications with sorting, etc
    getGroupPhysicians: callOnce(function getGroupPhysicians(group = groupConfig) {
      const url = `${urls.getGroupsApi()}/${group.name}/physicians`;

      // get group physicians via group 2.x api
      const request = merge({ url }, urls.get2xRequestDefaults());

      return $http(request).then((response) => {
        const { physicians = [] } = response.data;

        physicians.sort((a, b) => {
          const aName = a.name;
          const bName = b.name;

          if (aName > bName) return 1;
          if (bName > aName) return -1;

          return 0;
        });

        return physicians;
      });
    }),

    getGroup4x(name, locale, queryParams) {
      let url = `${urls.getGroupsApi()}/${name}/config`;

      if (queryParams) {
        url += `?${formatQueryParams(queryParams)}`;
      }

      const headers = {};
      if (locale) {
        headers['Accept-Language'] = locale;
      }

      return urls.request4x(url, { headers }).then((res) => res.data);
    },

    // this is sent through the application server,
    // since it does not support user auth at this time
    // $http returns the data on a `data` prop of the response object,
    // and the API response has a `data` attribute at the first level
    getSubGroups4x: callOnce(function getSubGroups(name) {
      return $http({ url: `/api/groups/${name}/subgroups` }).then((res) => res.data.data);
    }),

    // this need to still go through the server. Auth is by applications, not users
    addPatientToSubGroup4x(group, subgroup, userId) {
      return $http({
        url: `/api/groups/${group}/subgroups/${subgroup}/patients/${userId}`,
        method: 'POST'
      });
    },

    // this need to still go through the server. Auth is by applications, not users
    removePatientFromSubGroup4x(group, subgroup, userId) {
      return $http({
        url: `/api/groups/${group}/subgroups/${subgroup}/patients/${userId}`,
        method: 'DELETE'
      });
    },

    getRtmReports(groupName) {
      return urls
        .request4x(`${urls.getGroupsApi()}/${groupName}/reports`)
        .then((res) => res.data.data);
    },

    getRtmReportURI(groupName, reportId) {
      return urls
        .request4x(`${urls.getGroupsApi()}/${groupName}/reports/${reportId}`)
        .then((res) => res.data.uri);
    },

    get config() {
      return groupConfig;
    },

    get medicationDict() {
      return medicationDict;
    },

    get medicationList() {
      return medicationList;
    }
  };

  return GroupService;
});
