/* eslint-disable no-param-reassign */
import angular from 'angular';
import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    parent: 'main',
    name: 'notifications.**',
    url: '/notifications',
    lazyLoad($transition$) {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

      return import('../../modules/notifications/notifications.module').then((mod) =>
        $ocLazyLoad.load(mod.NOTIFICATIONS_MODULE)
      );
    }
  });
});
