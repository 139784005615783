/* eslint-disable import/prefer-default-export */
// implementation based on https://github.com/onury/custom-error-test/blob/master/the-one/CustomError.js
import { setProto, defineDefaultProperties, definePrototypeChain } from './errorUtilities';

export function PatientAccessError(message) {
  let err;

  if (setProto) {
    err = new Error(message);
    setProto(err, PatientAccessError.prototype);
  } else {
    err = this;
  }

  return defineDefaultProperties(err, message, PatientAccessError, 'PatientAccessError');
}

definePrototypeChain(PatientAccessError);
