/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';
import { getDisplayName } from './helpers';

const AngularInjectorContext = createContext();

export default AngularInjectorContext;

// eslint-disable-next-line react/prop-types
export function AngularInjectorProvider({ injector, children }) {
  return (
    <AngularInjectorContext.Provider value={injector}>{children}</AngularInjectorContext.Provider>
  );
}

export const useAngularInjector = () => useContext(AngularInjectorContext);

export function withAngularInjector(WrappedComponent) {
  function WrappedWithAngularInjector(props) {
    const injector = useAngularInjector();
    return <WrappedComponent {...props} injector={injector} />;
  }

  WrappedWithAngularInjector.WrappedComponent = WrappedComponent;

  WrappedWithAngularInjector.displayName = `withAngularInjector(${getDisplayName(
    WrappedComponent
  )})`;

  return WrappedWithAngularInjector;
}
