import angular from 'angular';
import app from '../../app';

import template from './loading.html';
import './loading.less';

angular.module(app).component('loading', {
  template,
  controller: [
    '$translatePartialLoader',
    function LoadingController($translatePartialLoader) {
      $translatePartialLoader.addPart('components/loading');
    }
  ]
});
