import angular from 'angular';
import merge from 'lodash/merge';

import app from '../../app';
import moment from '../moment';

import poorStatusImg from '../../../assets/images/no-minify/declineinstatus_poor.svg';
import fairStatusImg from '../../../assets/images/declineinstatus_fair.svg';
import exacerbationPoorImg from '../../../assets/images/predictingexacerbation_poor.svg';
import exacerbarionFairImg from '../../../assets/images/predictingexacerbation_fair.svg';
import adherencePoorImg from '../../../assets/images/pooradherence_poor.svg';
import adherenceFairImg from '../../../assets/images/pooradherence_fair.svg';
import blueDotsIcon from '../../../assets/images/icon-dots-blue.svg';

angular.module(app).factory('Notifications', function NotificationsFactory($http, User, urls) {
  // Service logic
  const Notifications = {
    getNotificationStats: function getNotificationStats() {
      const request = merge(
        {
          method: 'GET',
          url: `${urls.getProviderApi()}/alertstats?unique=${new Date().getTime()}`
        },
        urls.get2xRequestDefaults()
      );

      return $http(request);
    },

    getUserNotificationStats: function getUserNotificationStats(userId) {
      const request = merge(
        {
          method: 'GET',
          url: `${urls.getProviderApi()}/alertstats/${userId}?unique=${new Date().getTime()}`
        },
        urls.get2xRequestDefaults()
      );

      return $http(request);
    },

    extraNotificationProperties: function extraNotificationProperties(notificationItem) {
      if (!notificationItem) return false;

      let notification = {};
      let notificationType = notificationItem;

      if (typeof notificationItem === 'object') {
        notification = notificationItem.alert;
        notificationType = notification.alertType;
      }

      let imageUrl;
      let message = '';
      let custom = false;
      const extraProperties = {};
      const status = notification.asthmaScore || 'unknown';

      const today = moment();
      const notificationCreationDate = moment(notificationItem.alert.dateC);

      // message translation lives in directive/notification-item/i8tn
      switch (notificationType) {
        case 'transition':
          switch (status) {
            case 'poor':
              message = 'SERVICE.NOTIFICATIONS-TRANSITION-POOR';
              imageUrl = poorStatusImg;
              break;
            case 'fair':
              message = 'SERVICE.NOTIFICATIONS-TRANSITION-FAIR';
              imageUrl = fairStatusImg;
              break;
            default:
              message = 'SERVICE.NOTIFICATIONS-TRANSITION';
              imageUrl = poorStatusImg;
              break;
          }
          break;
        case 'atrisk':
          message = 'SERVICE.NOTIFICATIONS-ATRISK';
          switch (status) {
            case 'poor':
              imageUrl = exacerbationPoorImg;
              break;
            case 'fair':
              imageUrl = exacerbarionFairImg;
              break;
            default:
              imageUrl = exacerbarionFairImg;
              break;
          }
          break;
        case 'adherence':
          message = 'SERVICE.NOTIFICATIONS-ADHERENCE';
          switch (status) {
            case 'poor':
              imageUrl = adherencePoorImg;
              break;
            case 'fair':
              imageUrl = adherenceFairImg;
              break;
            default:
              imageUrl = adherenceFairImg;
              break;
          }
          break;
        case 'quietsensor':
          message = 'SERVICE.NOTIFICATIONS-QUIETSENSOR';
          imageUrl = blueDotsIcon;
          break;
        case 'lowbattery':
          message = 'SERVICE.NOTIFICATIONS-LOWBATTERY';
          imageUrl = blueDotsIcon;
          break;
        case 'misseddose':
          custom = true;
          message = notificationItem.msg; // different message
          imageUrl = blueDotsIcon;
          break;
        case 'rescueusage':
          custom = true;
          message = notificationItem.msg;
          imageUrl = blueDotsIcon;
          break;
        case 'rescueVolume':
          custom = true;
          message = notificationItem.msg;
          imageUrl = blueDotsIcon;
          break;
        case 'controllerVolume':
          custom = true;
          message = notificationItem.msg;
          imageUrl = blueDotsIcon;
          break;
        default:
          message = notificationType;
          imageUrl = blueDotsIcon;
          break;
      }

      // if notificationItem arrived today, add keys to extraProperties
      if (notificationCreationDate.isSame(today, 'd')) {
        extraProperties.arrivedToday = true;
        // if it arrived today, calculate how long ago it arrived
        const duration = moment.duration(today.diff(notificationCreationDate));

        if (duration.hours() === 0 && duration.minutes() < 59) {
          // if diff is between 0 and 59 minutes
          if (duration.minutes() === 0 && duration.seconds() < 59) {
            // if diff is between 0 and 59 seconds --> now
            extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-NOW';
          } else if (duration.minutes() === 1) {
            // if diff is equal to 1 minute (ranges from 1:00 and 1:59)
            extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-ABOUT-MINUTE-AGO';
          } else {
            // if diff is between 0 and 59 minutes
            extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-ABOUT-MINUTES-AGO';
            extraProperties.notificationTimeDiff = duration.minutes();
          }
        } else if (duration.hours() === 1) {
          // if diff is equal to 1 hour (ranges from 1:00 and 1:59)
          extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-ABOUT-HOUR-AGO';
        } else {
          // if diff is between 2 - 23 hours
          extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-ABOUT-HOURS-AGO';
          extraProperties.notificationTimeDiff = duration.hours();
        }
      } else {
        // if yesterday then mark yesterday, last week, two weeks ago
        const yesterday = today
          .clone()
          .subtract(1, 'days')
          .startOf('day');
        const withinAWeek = today
          .clone()
          .subtract(7, 'days')
          .startOf('day');

        if (notificationCreationDate.isSame(yesterday, 'd')) {
          // if notification came yesterday
          extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-YESTERDAY';
        } else if (notificationCreationDate.isAfter(withinAWeek)) {
          // if notification came at some point last week
          extraProperties.dateArrivedMessage = 'SERVICE.NOTIFICATIONS-LAST-WEEK';
        }
      }

      extraProperties.imageUrl = imageUrl;
      extraProperties.message = message;
      extraProperties.custom = custom;

      return extraProperties;
    },

    activateAlert(options) {
      options = options || {};
      if (!options.alertId) {
        // console.log("No alertId specified.")
        return;
      }

      const { alertId } = options;

      // eslint-disable-next-line consistent-return
      return User.getUser().then(user => {
        const request = merge(
          {
            method: 'POST',
            url: `${urls.getUsersApi()}/${user.id}/alerts/${alertId}/activate`
          },
          urls.get2xRequestDefaults()
        );

        return $http(request);
      });
    },

    deleteAlert(options) {
      options = options || {};

      const { alertId } = options;

      if (!alertId) {
        // console.log("No alertId specified.")
        return;
      }

      // eslint-disable-next-line consistent-return
      return User.getUser().then(user => {
        const request = merge(
          {
            method: 'DELETE',
            url: `${urls.getUsersApi()}/${user.id}/alerts/${alertId}`
          },
          urls.get2xRequestDefaults()
        );

        return $http(request);
      });
    },

    getNotifications: function getNotifications(options) {
      const params = options.params || {};
      params.unique = new Date().getTime(); // IE cache busting technique, may not be necessary

      return User.getUser().then(user => {
        const request = merge(
          {
            method: 'GET',
            url: `${urls.getUsersApi()}/${user.id}/alerts`,
            params: params || {}
          },
          urls.get2xRequestDefaults()
        );

        return $http(request);
      });
    }
  };

  return Notifications;
});
