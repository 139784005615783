import { hasItems } from '../index';

// Prior implementation assumes an object with a `medication` prop,
// which makes it possible to pass in an usage event, an item on a plan, etc
// which contain medication info as an embedded property.
// Some times want to be able to pass in the medication itself.
// It may make sense to migrate places we use this to pass through the actual medication object.
// If we do so, we should add some logging here to help trace deprecations.
export const isMedType = (aType) => (anItem) => {
  if (anItem.medication) {
    return anItem.medication.type === aType;
  }

  return anItem.type === aType;
};

export const isRescue = isMedType('rescue');

export const isController = isMedType('controller');

export const hasSchedule = (anItem) => hasItems(anItem.usageList);

export const hasSupportedSensors = (medication) => hasItems(medication.sensors);
