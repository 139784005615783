import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import appReducers from './reducers';

const persistConfig = {
  key: 'ph-prov',
  storage,
  whitelist: [
    'enrollmentData',
    'enrollmentMedications',
    'enrollmentSubGroups',
    'copdAssessment',
    'adultAsthmaAssessment',
    'childAsthmaAssessment',
    'hubId'
  ]
};

const persistedReducer = persistReducer(persistConfig, appReducers);

const store = createStore(persistedReducer, applyMiddleware(thunk));

// NOTE: should the line below be removed? I don't think we're using PersistGate
export const persistor = persistStore(store);

export default store;
