import { defaultTimezone } from '../enrollment';

// this appears unused
export const determineCaregiverId = (caregiver) => {
  if (caregiver) {
    return caregiver.data ? caregiver.data.id : caregiver.id;
  }

  return undefined;
};

// make a random 8 character hex (eg. 000000 to ffffff)
const randomSegment = (a) =>
  a
    ? /* eslint-disable-next-line no-bitwise */
      (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : (1e7).toString().replace(/[01]/g, randomSegment);

function baseUser({ user, address }, hasAccess) {
  const { phone, email } = user;
  let userEmail = email;

  if (hasAccess && !userEmail && phone) {
    userEmail = `${phone.slice(1)}+${randomSegment()}@propeller.health`;
  }

  // why was there the `user.email !== ''` check?
  return {
    email: hasAccess && userEmail ? userEmail : undefined,
    familyName: user.familyName,
    givenName: user.givenName,
    language: user.language,
    mailingAddress: address,
    phone: hasAccess ? user.phone : undefined,
    timeZone: defaultTimezone
  };
}

export function buildCaregiver({ user, address, agreements }) {
  return {
    ...baseUser({ user, address }, true),
    role: 'caregiver',
    hubRequired: user.device === 'hub',
    acceptedUserAgreements: agreements || []
  };
}

export function buildPatient({ user, address, agreements, assessments, caregiver }) {
  // if no caregiver, then the patient access their own account.
  // if there is a caregiver, they access if declared
  const hasCaregiver = Boolean(caregiver);
  const hasAccess = !hasCaregiver || user.hasAccess === true;

  let hubRequired;

  // if there's a caregiver, the platform will forward whatever
  // hubRequired is on the caregvier to their associated patients
  if (!hasCaregiver) {
    // user.hubRequired is set by a clinical trial arm.
    // If that is a boolean (vs. undefined), use it directly
    // because it's been set via clinical trial logic
    // otherwise look at the answer to the device question.
    hubRequired = typeof user.hubRequired === 'boolean' ? user.hubRequired : user.device === 'hub';
  }

  // role could be "control"
  return {
    ...baseUser({ user, address }, hasAccess),
    role: user.role || 'patient',
    hubRequired,
    acceptedUserAgreements: agreements,
    act: assessments.act,
    birthDate: user.birthDate,
    caregiverId: hasCaregiver ? caregiver.id : undefined,
    cat: assessments.cat,
    clinicalTrialArmId: user.clinicalTrialArmId,
    disease: user.disease,
    gender: user.gender !== undefined ? user.gender.toLowerCase() : undefined,
    hispanic: user.hispanic || null,
    medicalIds: user.medicalIds,
    preemptive: user.preemptive,
    race: user.race
  };
}

export const buildAddressData = (address, country) => ({
  street: address.street,
  street2: address.street2 !== '' ? address.street2 : undefined,
  city: address.city,
  stateOrRegion: address.stateOrRegion,
  stateCode: address.stateCode,
  country,
  postalCode: address.postalCode
});

export const buildMedUsageList = (doses, schedule) =>
  schedule.map((medTime) => ({
    unitDoses: doses,
    hour: medTime.hour,
    minute: medTime.minute
  }));

export const calculateActResults = (adultAct, childAct) => {
  const quizToCalculate = adultAct.length > 0 ? adultAct : childAct;

  return quizToCalculate.reduce((total, currentAnswer) => total + currentAnswer);
};

/* Returns "a query string suitable for use in a URL", without the leading "?" or "#".
  Expecting queryParams to be an object with single values or the second value to be an array:
  e.g.: {include: 'parameters', tag: 'rtm'} or {include: 'parameters', tag: ['rtm', 'disease']}
  May need to rejigger for other formats, if others arise.
*/
export function formatQueryParams(queryParams) {
  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((nestedValue) => {
        searchParams.append(key, nestedValue);
      });
    } else {
      searchParams.append(key, value);
    }
  });
  return searchParams.toString();
}
