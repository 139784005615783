import angular from 'angular';
import app from '../../app';
import moment from '../moment';
import $sessionStorage from '../../utilities/storage/sessionStorage';
import { defaultLocale, options, fallbacks } from '../locales';

angular
  .module(app)
  .factory('MissingTranslationHandler', function($q, $log) {
    return function(/* part, lang, response */) {
      // $log.error('The "' + part + '/' + lang + '" part was not loaded.');
      // $log.error(response);
      return $q.when({});
    };
  })
  .config(function($translateProvider, $windowProvider) {
    const $window = $windowProvider.$get();
    // Firefox does not have es-us, only es (but it does have other countries),
    // so we cast es to es-us. On the system level, Mac uses the code es-419
    // for US spanish (which is the code for 'Spanish as used in Latin America
    // and the Caribbean'). iOS uses es-xl (and seems to use it for all Latin America regions...)

    const SUBSTITUTIONS = {
      ca: 'ca-ES',
      de: 'de-DE',
      en: 'en-US',
      es: 'es-ES',
      'es-419': 'es-US',
      'es-xl': 'es-US',
      fr: 'fr-FR',
      it: 'it-IT',
      ko: 'ko-KO',
      nl: 'nl-NL',
      pt: 'pt-PT',
      ru: 'ru-RU'
    };

    const allowedLanguages = Object.keys(options);

    function determinePreferredLanguage() {
      const userLang =
        $window.navigator.languages ||
        $window.navigator.language ||
        $window.navigator.browserLanguage ||
        $window.navigator.systemLanguage ||
        $window.navigator.userLanguage;

      if (!userLang) {
        return defaultLocale;
      }

      const userLangs = []
        .concat(userLang)
        .map(lang => {
          lang = lang.replace(/_/g, '-');
          return allowedLanguages.indexOf(lang) > -1 ? lang : SUBSTITUTIONS[lang];
        })
        .filter(i => !!i);

      return userLangs.shift() || defaultLocale;
    }

    // Get the preferredLanguage from the session storage so we can overrule the browserLanguage
    // even before the language preference gets set from the call to get user in the main controller
    const setLanguage = $sessionStorage.get('preferredLanguage');

    const preferredLanguage =
      setLanguage && allowedLanguages.indexOf(setLanguage) > -1
        ? setLanguage
        : determinePreferredLanguage();

    $translateProvider
      .useLoaderCache(true)
      .useSanitizeValueStrategy(null)
      .useLoader('$translatePartialLoader', {
        urlTemplate: '/assets/i18n/{part}/i18n/{lang}.json',
        loadFailureHandler: 'MissingTranslationHandler'
      })
      .registerAvailableLanguageKeys(allowedLanguages, SUBSTITUTIONS)
      .fallbackLanguage(fallbacks(preferredLanguage))
      .preferredLanguage(preferredLanguage)
      .use(preferredLanguage);

    moment.locale(preferredLanguage);
  });
