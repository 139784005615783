import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';

import CircularButton from '../../CircularButton';

import './searchInput.less';

const PATIENTS_ROUTE = 'patients';

function SearchInput() {
  const { t } = useTranslation();
  const router = useRouter();
  const groupConfig = useSelector((state) => state.groupConfig);
  const { state, params } = useCurrentStateAndParams();

  const isSearch = state.name === 'search';
  const initialQuery = isSearch ? params.searchQuery : '';

  const [searchQuery, setSearchQuery] = useState(initialQuery);

  const previousRoute = useRef({ name: PATIENTS_ROUTE });
  const searchTimeout = useRef(null);

  useEffect(() => {
    searchTimeout.current = window.setTimeout(() => {
      const trimmed = searchQuery.trim();

      if (trimmed) {
        if (state.name !== 'search') {
          // params from useCurrentStateAndParams appears to mutate,
          // so use spread operator to store a new object
          previousRoute.current = {
            name: state.name,
            params: { ...params }
          };
        }

        router.stateService.go('search', { searchQuery: trimmed });
      } else if (!trimmed && state.name === 'search') {
        const { name } = previousRoute.current;
        let { params: nextParams } = previousRoute.current;

        // if we load directly onto the search results, we'll have previousRoute
        // set to patients by default, but won't have set params
        // because they depend on group config. So set it now.
        if (name === PATIENTS_ROUTE && (!nextParams || !nextParams.filter)) {
          nextParams = {
            filter: groupConfig.diseases[0]
          };
        }

        router.stateService.go(name, nextParams);
      }
    }, 300);

    // eslint-disable-next-line consistent-return
    return () => {
      if (searchTimeout.current) {
        window.clearTimeout(searchTimeout.current);
      }
    };
  }, [searchQuery]);

  return (
    <FormGroup className="search-input-container">
      <FormControl
        type="search"
        placeholder={t('SEARCH_PATIENT_NAME')}
        className="ph has-transition"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
      <CircularButton
        onClick={() => {
          setSearchQuery('');
        }}
        analyticsPage="navbar"
        analyticsLabel="clear_search"
      >
        <i
          className={classNames('fa', {
            'icon-search': !searchQuery,
            'icon-cancel': Boolean(searchQuery)
          })}
        />
        <span className="sr-only">{t('CANCEL')}</span>
      </CircularButton>
    </FormGroup>
  );
}

export default SearchInput;
