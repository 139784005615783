/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

function useProvideSession(initialSession) {
  const [session, setSession] = useState(initialSession);

  return { session, setSession };
}

export function ProvideSession({ session: initialSession, children }) {
  const session = useProvideSession(initialSession);
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

export const useSession = () => useContext(SessionContext);
