import angular from 'angular';
import app from '../../app';

import template from './copd-rescue-baseline.html';

angular.module(app).component('copdRescueBaseline', {
  template,
  bindings: {
    week: '<',
    baseline: '<'
  },
  controller() {
    const ctrl = this;

    ctrl.$onInit = function init() {
      ctrl.hide = !ctrl.week || ctrl.week.histogram.length < 7;
    };
  }
});
