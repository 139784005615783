import angular from 'angular';
import app from '../../app';

import template from './toggle-button.html';
import './toggle-button.less';

angular.module(app).component('toggleButton', {
  template,
  transclude: true,
  bindings: {
    onClick: '<',
    isPressed: '<',
    elementId: '@'
  }
});
