import angular from 'angular';
import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state('search', {
    parent: 'abstractList',
    url: '/search/:searchQuery',
    params: {
      searchQuery: ''
    },
    views: {
      sidebar: 'searchSidebar',
      list: 'searchResults'
    }
  });
});
