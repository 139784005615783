export const setProto = Object.setPrototypeOf;

export function defineDefaultProperties(instance, message, klass, klassName) {
  Object.defineProperty(instance, 'name', {
    enumerable: false,
    writable: false,
    value: klassName
  });

  if (!setProto) {
    Object.defineProperty(instance, 'message', {
      enumerable: false,
      writable: true,
      value: message
    });

    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, klass);
    } else {
      Object.defineProperty(instance, 'stack', {
        enumerable: false,
        writable: false,
        value: new Error(message).stack
      });
    }
  }

  return instance;
}

export function definePrototypeChain(klass) {
  if (setProto) {
    setProto(klass.prototype, Error.prototype);
  } else {
    klass.prototype = Object.create(Error.prototype, {
      constructor: { value: klass }
    });
  }
}
