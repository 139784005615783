import angular from 'angular';
import isEmpty from 'lodash/isEmpty';

import app from '../../app';

angular
  .module(app)
  .controller(
    'PatientsCtrl',
    function ($scope, $location, $transition$, $translatePartialLoader, $ngRedux, Logger) {
      Logger.debug('PatientsCtrl: entering');
      const subscribe = $ngRedux.connect((state) => ({
        groupConfig: state.groupConfig
      }));

      const unsubscribe = subscribe($scope);

      $scope.$on('$destroy', () => {
        Logger.debug('PatientsCtrl: leaving');
        unsubscribe();
      });

      const validDiseaseFilters = ['asthma', 'copd'];
      const params = $transition$.params();
      $scope.initialFilter = params.filter;
      $scope.careteam = params.careteam;

      // if no filter was set by the url, get the default disease from the group
      // and retry
      if (!$scope.initialFilter) {
        Logger.debug('PatientsCtrl: no list filter, set it');
          if ($scope.groupConfig && !isEmpty($scope.groupConfig)) {
          const diseaseFilter = $scope.groupConfig.diseases[0];

          if (validDiseaseFilters.indexOf(diseaseFilter) >= 0) {
            $location.path(`/${diseaseFilter}`);
          } else {
            $location.path('/sensor-inactive');
          }
        } else {
          $location.path('/sensor-inactive');
        }
      }

      // Initialize code here
      $translatePartialLoader.addPart('views/patients');
    }
  );
