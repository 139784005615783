import angular from 'angular';
import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    parent: 'main',
    name: 'enrollment.**',
    url: '/enrollment',
    lazyLoad($transition$) {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

      return import('../../modules/enrollment/enrollment.module').then((mod) =>
        $ocLazyLoad.load(mod.ENROLLMENT_MODULE)
      );
    }
  });
});
