import angular from 'angular';
import app from '../../app';

import template from './template.html';
import './styles.less';

angular.module(app).component('phButton', {
  template,
  transclude: true,
  bindings: {
    isDisabled: '<',
    onClick: '<',
    size: '@',
    type: '@',
    variant: '@',
  },
  controller: [function PhButton() {
    const ctrl = this;

    ctrl.$onInit = function init() {
      ctrl.type = ctrl.type || 'button';
      ctrl.size = ctrl.size || 'large';
      ctrl.variant = ctrl.variant || 'primary';
      ctrl.isDisabled = ctrl.isDisabled || false;
    };

    ctrl.handleClick = function handleClick(event) {
      if (ctrl.onClick && !ctrl.isDisabled) {
        ctrl.onClick(event);
      }
    };
  }]
});
