import angular from 'angular';
import app from '../../app';

import template from './filter-options.html';
import './filter-options.less';

angular.module(app).component('filterOptions', {
  template,
  transclude: true,
  bindings: {
    options: '<',
    activeFilter: '<',
    analyticsView: '@',
    onClick: '&'
  },
  controller: function FilterOptionsController() {
    const ctrl = this;

    ctrl.update = function(option) {
      ctrl.onClick({ value: option });
    };
  }
});
