import { isResponseObject, noop, responseForLog } from '../index';

// eslint-disable-next-line no-underscore-dangle
const env = { ...window.__env };

function isError(anObject) {
  return anObject instanceof Error;
}

function formatError(anError) {
  // Errors don't have a toJSON method and so can't be serrialized by JSON.stringify
  const errorObject = {};

  Object.getOwnPropertyNames(anError).forEach(function (key) {
    errorObject[key] = anError[key];
  });

  // stack is not always enumberable via getOwnPropertyNames
  // eg. on FF it's on the prototype, not the instance
  if (!errorObject.stack) {
    errorObject.stack = anError.stack;
  }

  return errorObject;
}

function sendLog(content) {
  return window.fetch('/logger', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(content)
  });
}

const logLevel = env.logLevel || 'debug';

const LEVELS = { error: 0, warn: 1, info: 2, debug: 3 };
const LEVEL_KEYS = Object.keys(LEVELS);

const configuredLevel = LEVELS[logLevel];

function log(level) {
  if (!LEVEL_KEYS.includes(level)) {
    level = 'info';
  }

  if (LEVELS[level] > configuredLevel) {
    return noop;
  }

  return function _log(message, info = {}) {
    const { hostname, pathname } = window.location;
    const TLD = hostname.split('.').pop();

    try {
      // there's a lot of inconsistency around things we may want to log from
      if (typeof info === 'string') {
        info = { infoMessage: info };
      } else if (isError(info)) {
        info = { error: formatError(info) };
      } else if (isResponseObject(info)) {
        info = responseForLog(info);
      }

      const content = {
        level,
        message,
        info,
        time: new Date().toISOString(),
        context: {
          host: hostname,
          path: pathname,
          userAgent: window.navigator.userAgent
        }
      };

      if (['localhost', 'biz'].includes(TLD)) {
        const write = window.console[level] || window.console.log;
        write(content);
      }

      return sendLog(content).catch(noop);
    } catch (e) {
      window.console.error('Exception trying to log error on client', e);

      return sendLog({
        level: 'error',
        message: 'Exception trying to log error on client',
        time: new Date().toISOString(),
        info: {
          message,
          info
        },
        context: {
          host: hostname,
          path: pathname,
          userAgent: window.navigator.userAgent
        }
      }).catch(noop);
    }
  };
}

export default {
  error: log('error'),
  warn: log('warn'),
  info: log('info'),
  log: log('info'),
  debug: log('debug')
};
