import storage from '../storage/sessionStorage';
import constants from './constants';

// we want these shared between angular and react instances
let superProps = {};
let userId;

function withSessionUUID(props = {}) {
  return {
    session_uuid: storage.get('session_uuid'),
    ...props
  };
}

// currently exporting an initilaize function so that angular can pass in its $window
// to faciltate overrides during testing, etc.
export default function initialize(window, logger) {
  // keep all of these inside the initialize scope
  // so they have access to the provided window and logger
  function withDefaults(data = {}) {
    const { context = {} } = data;

    return {
      ...data,
      anonymousId: storage.get('anonymousId'),
      userId,
      timestamp: new Date().toISOString(),
      context: {
        ...context,
        ip: '0.0.0.0',
        page: {
          path: window.location.pathname,
          referrer: window.document.referrer,
          search: window.location.search,
          title: window.document.title,
          url: window.location.toString()
        },
        userAgent: window.navigator.userAgent,
        locale: storage.get('preferredLanguage')
      }
    };
  }

  function sendData(method, properties = {}) {
    return window.fetch('/analytics', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        method,
        data: withDefaults(properties)
      })
    });
  }

  function labelForContext(pageId, trackId, labelId) {
    if (!pageId) throw new Error('Missing analytics pageId');
    if (!trackId) throw new Error('Missing analytics eventType');
    if (!labelId) throw new Error('Missing analytics labelId');

    let page;
    let track;
    let label;
    let pageLabel;
    let trackLabel;

    try {
      page = constants.pages[pageId];
      pageLabel = page.label;

      if (!pageLabel) {
        pageLabel = pageId;
        logger.warn('Analytics: No page label found', { pageId, trackId, labelId });
      }
    } catch (e) {
      logger.warn('Analytics: Exception on page label', { pageId, trackId, labelId, error: e });
      pageLabel = pageId;
    }

    try {
      track = page.tracks[trackId];
      trackLabel = track.label;

      if (!trackLabel) {
        trackLabel = trackId;
        logger.warn('Analytics: No track label found', { pageId, trackId, labelId });
      }
    } catch (e) {
      logger.warn('Analytics: Exception on track label', { pageId, trackId, labelId, error: e });
      pageLabel = pageId;
      trackLabel = trackId;
    }

    try {
      label = track.properties.name.values[labelId];

      if (!label) {
        logger.warn('Analytics: No label found for labelId', { pageId, trackId, labelId });
        label = labelId;
      }
      // warn and set default?
    } catch (e) {
      logger.warn('Analytics: Exception on label for labelId', { pageId, trackId, labelId });
      label = labelId;
    }

    return [pageLabel, trackLabel, label].join('-');
  }

  return {
    // https://segment.com/docs/libraries/analytics.js/#page
    // analytics.page([category], [name], [properties], [options], [callback]);
    // TODO : Support optional parameters where the parameter order and type changes their meaning
    // e.g.
    // (string) is (name)
    // (string, string) is (category, name)
    // (string, object) is (name, properties)
    page(path) {
      return sendData('page', {
        name: path,
        properties: withSessionUUID({
          name: path,
          path,
          referrer: window.document.referrer,
          search: '',
          title: window.document.title,
          url: window.location.toString()
        })
      });
    },

    // https://segment.com/docs/libraries/analytics.js/#track
    track(eventName, properties) {
      const {
        action,
        category = 'Event',
        eventType,
        labelId,
        pageId,
        trackId = 'clicked',
        ...rest
      } = properties;

      // eslint-disable-next-line camelcase
      const { viewed_patient_id = 'default' } = superProps;

      let label;

      try {
        label = labelForContext(pageId, trackId, labelId);
      } catch (e) {
        logger.error('Analytics: Error forming label', e);
        label = 'missing';
      }

      return sendData('track', {
        event: eventName,
        properties: withSessionUUID({
          category,
          label,
          action: action || eventType || eventName,
          // eslint-disable-next-line camelcase
          viewed_patient_id,
          ...rest
        })
      });
    },

    // https://segment.com/docs/libraries/analytics.js/#identify
    // analytics.identify([userId], [traits], [options], [callback]);
    identify(id) {
      userId = id;

      return sendData('identify');
    },

    // https://segment.com/docs/libraries/analytics.js/#alias
    // analytics.alias(userId, previousId, options, callback);
    setUserId(id) {
      userId = id;
    },

    setSuperProperties(newProps) {
      superProps = { ...superProps, ...newProps };
    },

    // this can be called by angular internals
    trackException(error, cause) {
      logger.debug('Analytics: trackException', { error, cause });

      const message = cause || error.toString();

      logger.error(message, error);
    }
  };
}
