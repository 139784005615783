import angular from 'angular';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import app from '../../app';
import moment from '../../services/moment';
import { options as localeOptions } from '../../services/locales';
import { phonePlaceholder } from '../../services/countries';

import './settings-profile.less';

angular
  .module(app)
  .controller('SettingsProfileCtrl', function(
    $scope,
    $translatePartialLoader,
    $timeout,
    $ngRedux,
    User
  ) {
    const subscribe = $ngRedux.connect((state) => ({
      groupConfig: state.groupConfig
    }));

    const unsubscribe = subscribe($scope);

    $scope.$on('$destroy', unsubscribe);

    $translatePartialLoader.addPart('views/settings-profile');

    $scope.loading = true;
    $scope.user = {};
    $scope.timeZones = moment.tz.names();
    $scope.updatingProfile = false;
    $scope.phonePlaceholder = phonePlaceholder;
    $scope.errors = {
      error: false,
      type: {
        givenName: false,
        familyName: false,
        email: false,
        phone: false,
        timeZone: false,
        language: false
      }
    };

    $scope.languages = localeOptions;

    $scope.updateProfile = function() {
      if (!$scope.updatingProfile && isEmpty($scope.profileSettings.$error)) {
        $scope.updatingProfile = true;
        $scope.resMsg = '';

        $scope.errors = {
          error: false,
          type: {
            givenName: false,
            familyName: false,
            email: false,
            phone: false,
            timeZone: false,
            language: false
          }
        };

        const scrubbedUser = pick(
          $scope.user,
          'givenName',
          'familyName',
          'email',
          'phone',
          'timeZone',
          'language'
        );

        // if phone is empty, null it
        if (scrubbedUser.phone === '') {
          scrubbedUser.phone = null;
        } else if (scrubbedUser.phone) {
          // remove all spaces and special characters
          scrubbedUser.phone = scrubbedUser.phone.trim().replace(/[+()\-\s]/g, '');
          scrubbedUser.phone = `+${scrubbedUser.phone}`; // append "+" to make it e.164 (yes i got rid of it but can't trust users)
        }

        User.updateUser(scrubbedUser)
          .then(function() {
            $scope.errors.error = false;
            $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-MESSAGE-SUCCESS';
          })
          .then(function() {
            if (!$scope.errors.error) {
              return $timeout(function() {
                $scope.resMsg = '';
              }, 1500);
            }

            return null;
          })
          .catch(function(res) {
            $scope.errors.error = true;
            $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-MESSAGE-ERROR';

            let updateErrorId = null;
            try {
              const key = find(res.data.elements, 'id').id || 'default';
              [ updateErrorId ] = key.split('.');
            } catch (error) {
              updateErrorId = null;
            }

            switch (res.status) {
              case 400:
                switch (updateErrorId) {
                  case 'givenName':
                    $scope.errors.type.givenName = true;
                    break;

                  case 'familyName':
                    $scope.errors.type.familyName = true;
                    break;

                  case 'email':
                    $scope.errors.type.email = true;
                    break;

                  case 'phone':
                    $scope.errors.type.phone = true;
                    break;

                  case 'timeZone':
                    $scope.errors.type.timeZone = true;
                    break;

                  default:
                    $scope.errors.error = true;
                }
                break;
              case 401:
              case 403:
                $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-ERROR-400';
                break;
              case 500:
              default:
                $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-ERROR-500';
                break;
            }
          })
          .finally(function() {
            $scope.updatingProfile = false;
          });
      } else if (
        $scope.profileSettings.$error.phoneNumber &&
        $scope.profileSettings.$error.phoneNumber.length > 0
      ) {
        $scope.errors.error = true;
        $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-MESSAGE-ERROR';
      } else if ($scope.updatingProfile) {
        $scope.errors.error = true;
        $scope.resMsg = 'VIEW-SETTINGS-PROFILE-RES-UPDATING-ERROR';
      }
    };

    // Initialize code here
    User.getUser().then(function(res) {
      $scope.user = res;
      $scope.loading = false;
      // focus on given name
      const el = document.getElementById('profile-settings-given-name');

      if (el) el.focus();
    });
  });
