import angular from 'angular';
import isEmpty from 'lodash/isEmpty';
import app from '../../app';
import { hasOwn } from '../../utilities';
import $sessionStorage from '../../utilities/storage/sessionStorage';

angular.module(app).factory('Patients', function(urls, $q, $http) {
  // Scoped vars here
  // var gettingPatients = false;

  let url = urls.getPatientsApi();

  if (!$sessionStorage.get('patientData')) {
    $sessionStorage.set('patientData', {});
  }

  return {
    getPatients(options) {
      // if (gettingPatients) return $q.reject();

      // gettingPatients = true;
      url = urls.getPatientsApi();
      const params = [];
      const request = urls.getRequestDefaults();
      // var validDiseaseOptions = ['asthma', 'copd'];
      // var validScoreOptions = ['unknown'];

      const sortCategoryToParam = {
        name: 'familyName',
        'date-joined': 'createdDate',
        'rescue-baseline': 'rescueBaselineDelta',
        'rescue-use': 'lastWeekRescueEvents',
        'last-sync': 'syncLastRescue',
        'last-sync-rescue': 'syncLastRescue',
        'last-sync-controller': 'syncLastController',
        'control-score': 'score',
        'adherence-score': 'lastWeekControllerPuffsAdherence',
        favorites: 'favorite',
        'last-contact': 'lastContactDate'
      };

      const sortOrderToParam = {
        ascending: 'asc',
        descending: 'desc'
      };

      // skip the options if the pagingToken is available
      if (options) {
        if(options.pagingToken){
          params.push(`pagingToken=${options.pagingToken}`);
        }
        if (options.score) {
          params.push(`score=${options.score}`);
        }
        if (options.query) {
          params.push(`q=${options.query}`);
        }
        if (options.disease) {
          params.push(`disease=${options.disease}`);
        }
        if (options.favorites) {
          params.push('favorites=true');
        }
        if (options.sortCategory) {
          params.push(`sortBy=${sortCategoryToParam[options.sortCategory]}`);
        }
        if (options.sortOrder) {
          params.push(`sortOrder=${sortOrderToParam[options.sortOrder]}`);
        }
        if (hasOwn(options, 'activeSensors') && typeof options.activeSensors === 'boolean') {
          params.push(`activeSensors=${options.activeSensors}`);
        }
        if ( options.careTeamOptionId ) {
          // if a care team option has been selected add it to the request url
          url = `${url}/${options.careTeamOptionId}`;
        }
      }

      request.withCredentials = true;
      request.method = 'GET';
      request.url = `${url}?${params.join('&')}`;

      return $http(request)
        .then(function(response) {
          const patientData = response.data;

          if (!options || !options.pagingToken) {
            const sessionData = $sessionStorage.get('patientData') || {};
            sessionData[url] = patientData;
            $sessionStorage.set('patientData', sessionData);
          }

          return patientData;
        })
        .catch(function(response) {
          const cachedPatients = $sessionStorage.get('patientData')[url];

          if (response.status === 401) {
            return $q.reject(
              'You have been signed out due to inactivity, please refresh to log back in'
            );
          }

          if (
            (!response.data || !response.status || response.status < 0 || response.status > 500) &&
            cachedPatients
          ) {
            return $q.when(cachedPatients);
          }

          if (isEmpty(cachedPatients)) {
            return $q.reject('Could not retrieve patients');
          }
          return $q.reject(response.statusText);
        })
        .finally(function() {
          // gettingPatients = false;
        });
    }
  };
});
