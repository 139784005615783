/* eslint-disable default-param-last */
import {
  ADD_HUB,
  ENROLLMENT_ADD_TO_SUBGOUP,
  ENROLLMENT_REMOVE_FROM_SUBGROUP,
  RESET_ENROLLMENT_DATA,
  RESET_ENROLLMENT_PATIENT,
  SET_INCLUDE_CAREGIVER,
  SET_SHARE_ACCOUNT,
  UPDATE_ENROLLMENT_DATA,
  UPDATE_MEDICAL_ID
} from '../../actions';
import { mergeDeep } from '../../utilities';

const INITIAL_ENROLLMENT_DATA = Object.freeze({
  patient: { role: 'patient', hasAccess: true },
  caregiver: { include: false },
  clinicians: []
});

export function enrollmentData(state = INITIAL_ENROLLMENT_DATA, action) {
  switch (action.type) {
    case RESET_ENROLLMENT_DATA:
      return INITIAL_ENROLLMENT_DATA;

    case RESET_ENROLLMENT_PATIENT:
      // this is for enrolling multiple patients for a caregiver
      // preserve the CG and their address
      return {
        ...INITIAL_ENROLLMENT_DATA,
        address: state.address,
        caregiver: {
          ...state.caregiver,
          sendSMSLinks: false /* if was true, they've recieved it. if was false, no change */
        }
      };

    case UPDATE_ENROLLMENT_DATA:
      return mergeDeep({}, state, action.data);

    case UPDATE_MEDICAL_ID: {
      const { medicalIds = [] } = state.patient;
      const { key, value } = action;
      const index = medicalIds.findIndex((mId) => mId.key === key);

      if (index > -1) {
        medicalIds[index] = { key, value };
      } else {
        medicalIds.push({ key, value });
      }

      return mergeDeep({}, state, { patient: { medicalIds } });
    }

    case SET_SHARE_ACCOUNT:
      return mergeDeep({}, state, { patient: { hasAccess: action.share } });

    case SET_INCLUDE_CAREGIVER:
      return mergeDeep({}, state, { caregiver: { include: action.include } });

    default:
      return state;
  }
}

export function hubId(state = '', action) {
  switch (action.type) {
    case ADD_HUB:
      return action.hubId;

    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return '';

    default:
      return state;
  }
}

export function enrollmentSubGroups(state = [], action) {
  switch (action.type) {
    case RESET_ENROLLMENT_DATA:
    case RESET_ENROLLMENT_PATIENT:
      return [];

    case ENROLLMENT_ADD_TO_SUBGOUP:
      return state.concat(action.id);

    case ENROLLMENT_REMOVE_FROM_SUBGROUP:
      return state.filter((subgroup) => subgroup !== action.id);

    default:
      return state;
  }
}
