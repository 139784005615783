import angular from 'angular';
import app from '../../app';

import template from './patients.html';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    name: 'patients',
    parent: 'main',
    url: '/{filter:(?:asthma|copd|sensor-inactive|)}?:careteam',
    template,
    controller: 'PatientsCtrl'
  });
});
