import { makeActionCreator } from '../utilities';

export const UPDATE_ENROLLMENT_DATA = 'UPDATE_ENROLLMENT_DATA';
export const RESET_ENROLLMENT_DATA = 'RESET_ENROLLMENT_DATA';
export const RESET_ENROLLMENT_PATIENT = 'RESET_ENROLLMENT_PATIENT';
export const SET_SHARE_ACCOUNT = 'SET_SHARE_ACCOUNT';
export const SET_INCLUDE_CAREGIVER = 'SET_INCLUDE_CAREGIVER';

export const ADD_PLAN_MEDICATION = 'ADD_PLAN_MEDICATION';
export const REMOVE_PLAN_MEDICATION = 'REMOVE_PLAN_MEDICATION';
export const SET_MEDICATION_SCHEDULE = 'SET_MEDICATION_SCHEDULE';
export const SET_MEDICATION_DOSES = 'SET_MEDICATION_DOSES';
export const ADD_MEDICATION_SENSOR = 'ADD_MEDICATION_SENSOR';
export const REMOVE_MEDICATION_SENSOR = 'REMOVE_MEDICATION_SENSOR';
export const MARK_MEDICATION_ADDED = 'MARK_MEDICATION_ADDED';
export const RESET_MEDICATIONS = 'RESET_MEDICATIONS';

export const UPDATE_CAT_RESPONSE = 'UPDATE_CAT_RESPONSE';
export const UPDATE_ADULT_ACT_RESPONSE = 'UPDATE_ADULT_ACT_RESPONSE';
export const UPDATE_CHILD_ACT_RESPONSE = 'UPDATE_CHILD_ACT_RESPONSE';
export const RESET_QUIZ = 'RESET_QUIZ';

export const UPDATE_MEDICAL_ID = 'UPDATE_MEDICAL_ID';

export const ADD_HUB = 'ADD_HUB';

export const ENROLLMENT_ADD_TO_SUBGOUP = 'ENROLLMENT:ADD_TO_SUBGROUP';
export const ENROLLMENT_REMOVE_FROM_SUBGROUP = 'ENROLLMENT:REMOVE_FROM_SUBGROUP';


export const enrollmentAddToSubGroup = makeActionCreator(ENROLLMENT_ADD_TO_SUBGOUP, 'id');
export const enrollmentRemoveFromSubGroup = makeActionCreator(ENROLLMENT_REMOVE_FROM_SUBGROUP, 'id');

export const updateEnrollmentData = makeActionCreator(UPDATE_ENROLLMENT_DATA, 'data');
export const resetEnrollmentData = makeActionCreator(RESET_ENROLLMENT_DATA);
// this one preserves the caregiver
export const resetEnrollmentPatient = makeActionCreator(RESET_ENROLLMENT_PATIENT);
export const setShareAccount = makeActionCreator(SET_SHARE_ACCOUNT, 'share');
export const setIncludeCaregiver = makeActionCreator(SET_INCLUDE_CAREGIVER, 'include');

export const addPlanMedication = makeActionCreator(ADD_PLAN_MEDICATION, 'medId', 'plan');
export const removePlanMedication = makeActionCreator(REMOVE_PLAN_MEDICATION, 'medId');
export const resetMedications = makeActionCreator(RESET_MEDICATIONS);
export const markMedicationAdded = makeActionCreator(MARK_MEDICATION_ADDED, 'medId');

export const addHub = makeActionCreator(ADD_HUB, 'hubId');

export const setMedicationSchedule = makeActionCreator(
  SET_MEDICATION_SCHEDULE,
  'medId',
  'schedule'
);

export const setMedicationDoses = makeActionCreator(SET_MEDICATION_DOSES, 'medId', 'doses');

export const addMedicationSensor = makeActionCreator(
  ADD_MEDICATION_SENSOR,
  'medId',
  'sensor'
);

export const removeMedicationSensor = makeActionCreator(
  REMOVE_MEDICATION_SENSOR,
  'medId',
  'sensor'
);

// the API doesn't enforce that each required medical ID set via SPP has a unique key...
export const updateMedicalId = makeActionCreator(UPDATE_MEDICAL_ID, 'key', 'value');

export const updateCatResponse = makeActionCreator(UPDATE_CAT_RESPONSE, 'value', 'index');
export const updateAdultActResponse = makeActionCreator(
  UPDATE_ADULT_ACT_RESPONSE,
  'value',
  'index'
);
export const updateChildActResponse = makeActionCreator(
  UPDATE_CHILD_ACT_RESPONSE,
  'value',
  'index'
);
export const resetQuiz = makeActionCreator(RESET_QUIZ);