/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bool, func, string, node } from 'prop-types';
import classNames from 'classnames';
import NavItem from 'react-bootstrap/lib/NavItem';
import Link from '../Link';

function NavLink({ analyticsLabel, href, onClick, selected, className, children, ...rest }) {
  return (
    <NavItem
      href={href}
      onClick={onClick}
      className={classNames(className, { selected })}
      componentClass={Link}
      analyticsPage="navbar"
      analyticsLabel={analyticsLabel}
      {...rest}
    >
      {children}
    </NavItem>
  );
}

NavLink.propTypes = {
  analyticsLabel: string.isRequired,
  className: string,
  children: node.isRequired,
  href: string.isRequired,
  onClick: func,
  selected: bool
};

NavLink.defaultProps = {
  className: null,
  onClick: null,
  selected: null
};

export default NavLink;
