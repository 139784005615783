import angular from 'angular';
import merge from 'lodash/merge';
import app from '../../app';

import { hasItems } from '../../utilities';
import {
  buildAddressData,
  buildCaregiver,
  buildPatient,
  calculateActResults,
  buildMedUsageList
} from '../../utilities/dataSubmission';
import { HUB_PREFIX } from '../../utilities/constants';
import { requiresClinicalTrial } from '../../utilities/enrollment';
import { AccountCreationError } from '../../utilities/errors';
import { prefixMac } from '../../utilities/sensors';

import { updateEnrollmentData, markMedicationAdded } from '../../actions';


function stringToError(err) {
  return typeof err === 'string' ? new Error(err) : err;
}

function recipientForSMS(caregiver, patient) {
  if (caregiver.include && caregiver.sendSMSLinks) {
    return { email: caregiver.email, sendSMSLinks: caregiver.sendSMSLinks };
  }

  if (!caregiver.include && patient.sendSMSLinks) {
    return { email: patient.email, sendSMSLinks: patient.sendSMSLinks };
  }

  return null;
}

function handleAccountError(label) {
  return function (error) {
    if (error instanceof Error) {
      return error;
    }

    if (typeof error === 'string') {
      return new Error(error);
    }

    return new AccountCreationError(label, error);
  };
}

angular
  .module(app)
  .factory(
    'AccountCreator',
    function ($q, $http, $ngRedux, urls, Group, Hub, Patient, Plan, Logger) {
      function buildMedRequestChain(patientId) {
        const handleError = handleAccountError('Plan');

        return (promiseChain, medication) =>
          promiseChain.then(() => {
            const options = {
              userId: patientId,
              medId: medication.id,
              sensors:
                medication.sensors !== undefined && medication.sensors.length > 0
                  ? medication.sensors.map(prefixMac)
                  : undefined,
              usageList:
                medication.doses !== undefined && medication.schedule !== undefined
                  ? buildMedUsageList(medication.doses, medication.schedule)
                  : undefined
            };

            return Plan.addPlanMedication(options)
              .then(() => $ngRedux.dispatch(markMedicationAdded(options.medId)))
              .catch((err) => {
                throw handleError(err);
              });
          });
      }

      function markAsCeated(role) {
        return function dispatchUpdate(user) {
          $ngRedux.dispatch(updateEnrollmentData({ [role]: { id: user.id } }));
          return user;
        };
      }

      const AccountCreator = {
        checkEmailAvailability(email) {
          return new Promise(function (resolve, reject) {
            if (!email) {
              reject(new Error('Missing email'));
            } else {
              urls
                .request4x(`/api/emailAvailable`, {
                  method: 'POST',
                  data: { email: email.toLowerCase() }
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
            }
          });
        },

        // eslint-disable-next-line no-control-regex
        emailRegex: /^((([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,

        createUser(data) {
          if (!data) {
            return $q.reject(new Error('createUser: No user specified'));
          }

          if (typeof data !== 'object') {
            return $q.reject(new Error('createUser: Bad user supplied'));
          }

          const request = merge(
            {
              url: urls.getUsersApi(),
              method: 'POST',
              data
            },
            urls.getRequestDefaults()
          );
          return $http(request).then((res) => res.data);
        },

        createCaregiver({ caregiver, address, agreements }) {
          const handleError = handleAccountError('Caregiver');

          return $q((resolve, reject) => {
            // if no caregiver
            if (!caregiver.include) {
              return resolve();
            }

            // caregiver already created
            if (caregiver.id) {
              return resolve(caregiver);
            }

            return this.createUser(buildCaregiver({ user: caregiver, address, agreements }))
              .then(markAsCeated('caregiver'))
              .then(resolve)
              .catch((err) => reject(handleError(err)));
          });
        },

        createPatient({ patient, caregiver, address, agreements, assessments }) {
          const handleError = handleAccountError('Patient');

          return $q((resolve, reject) => {
            // patient already created
            if (patient.id) {
              return resolve(patient);
            }

            return this.createUser(
              buildPatient({
                user: patient,
                address,
                agreements,
                assessments,
                caregiver
              })
            )
              .then(markAsCeated('patient'))
              .then(resolve)
              .catch((err) => reject(handleError(err)));
          });
        },

        addExternalIds(patientId, externalIds) {
          const handleError = handleAccountError('externalIds');

          if (!patientId) {
            return $q.reject(new Error('addExternalIds: No patient specified'));
          }

          if (!hasItems(externalIds)) {
            return $q.resolve();
          }

          return Patient.addExternalIds(patientId, externalIds).catch((err) => {
            throw handleError(err);
          });
        },


        addMedications(patientId, medications) {
          if (!medications) {
            return $q.reject(new Error('addMedications: No medications specified'));
          }

          if (typeof medications !== 'object') {
            return $q.reject(new Error('addMedications: Bad medications supplied'));
          }

          if (!patientId) {
            return $q.reject(new Error('addMedications: No patient specified'));
          }

          if (typeof patientId !== 'string') {
            return $q.reject(new Error('addMedications: Bad patient ID supplied'));
          }

          const medRequestReducer = buildMedRequestChain(patientId);

          return medications
            ? medications.reduce(medRequestReducer, $q.resolve({ ok: true }))
            : $q.resolve();
        },

        addActResponses(answers, score, type, patientId) {
          if (!answers) {
            return $q.reject(new Error('addActResponses: No quiz specified'));
          }

          if (!Array.isArray(answers) && answers.length === 0) {
            return $q.reject(new Error('addActResponses: Bad quiz supplied'));
          }

          if (score === undefined || score === null) {
            return $q.reject(new Error('addActResponses: No score specified'));
          }

          if (Number.isNaN(score)) {
            return $q.reject(new Error('addActResponses: Bad score supplied'));
          }

          if (!type) {
            return $q.reject(new Error('addActResponses: No type specified'));
          }

          if (type !== 'adult' && type !== 'child') {
            return $q.reject(new Error('addActResponses: Bad type supplied'));
          }

          if (!patientId) {
            return $q.reject(new Error('addActResponses: No patient specified'));
          }

          if (typeof patientId !== 'string') {
            return $q.reject(new Error('addActResponses: Bad patient ID supplied'));
          }

          const request = merge(
            {
              url: `${urls.getUsersApi()}/${patientId}/acts`,
              method: 'POST',
              data: {
                answers,
                type,
                score
              }
            },
            urls.getRequestDefaults()
          );

          return $http(request);
        },

        addCatResponses(answers, score, patientId) {
          if (!answers) {
            return $q.reject(new Error('addCatResponses: No quiz specified'));
          }

          if (!Array.isArray(answers)) {
            return $q.reject(new Error('addCatResponses: Bad quiz supplied'));
          }

          if (score === undefined || score === null) {
            return $q.reject(new Error('addCatResponses: No score specified'));
          }

          if (Number.isNaN(score)) {
            return $q.reject(new Error('addCatResponses: Bad score specified'));
          }

          if (!patientId) {
            return $q.reject(new Error('addCatResponses: No patient specified'));
          }

          if (typeof patientId !== 'string') {
            return $q.reject(new Error('addCatResponses: Bad patient ID supplied'));
          }

          const request = merge(
            {
              url: `${urls.getUsersApi()}/${patientId}/cats`,
              method: 'POST',
              data: {
                answers,
                score
              }
            },
            urls.getRequestDefaults()
          );
          return $http(request);
        },

        // how to recover from any errors here?
        addToSubGroups(patientId, groupName, subGroups) {
          const handleError = handleAccountError('Subgroups');

          if (!patientId) {
            return $q.reject(new Error('addToSubGroups: No patient specified'));
          }

          if (!subGroups || subGroups.length === 0) {
            return $q.resolve();
          }

          const promiseChain = subGroups.reduce(
            (chain, subGroupId) =>
              chain.then(() => Group.addPatientToSubGroup4x(groupName, subGroupId, patientId)),
            $q.resolve()
          );

          return promiseChain.catch((err) => {
            throw handleError(err);
          });
        },

        submitQuizData(quizData) {
          const {
            patient,
            patientId,
            copdAssessment,
            adultAsthmaAssessment,
            childAsthmaAssessment,
            assessments
          } = quizData;
          if (Group.config.actRequired === true && patient.disease === 'asthma') {
            const quiz =
              adultAsthmaAssessment.length > 0 ? adultAsthmaAssessment : childAsthmaAssessment;
            const type = adultAsthmaAssessment.length > 0 ? 'adult' : 'child';
            return this.addActResponses(quiz, assessments.act, type, patientId);
          }

          if (Group.config.catRequired === true && patient.disease === 'copd') {
            return this.addCatResponses(copdAssessment, assessments.cat, patientId);
          }

          return $q.resolve();
        },

        requestSMSLinks(caregiver, patient) {
          const options = recipientForSMS(caregiver, patient);

          if (!options) return $q.resolve();

          const { email, sendSMSLinks } = options;

          const data = {
            email,
            sendDownloadAppLinkSms: sendSMSLinks,
            sendSignInLinkEmail: false,
            sendSignInLinkSms: false
          };

          return $http({
            url: '/api/signInLink',
            method: 'POST',
            data
          }).catch((err) => {
            Logger.warn('Error requesting SMS links', stringToError(err));
          });
        },

        /**
         * Basic workflow:
         * First, create caregiver if we need one
         *    If caregiver has an `id`, they've already been created, so continue on
         * Create patient
         *    If patient has an `id`, they've alredy been created, so continue on
         * Add medications to the patient's plan
         *    These need to be added 1 at a time, otherwise the server sends back an error due to write contention
         * The remaining requests are for ACT or CAT, adding a hub, SMS links, and clinician followers
         *
         * For now, tolerating failure of any of the post-medication, because recovery may be difficult
         * But include logging so we can monitor the situation
         */
        submitEnrollmentData(data) {
          const {
            agreements,
            enrollmentData,
            enrollmentMedications,
            enrollmentSubGroups,
            copdAssessment,
            adultAsthmaAssessment,
            childAsthmaAssessment,
            hubId,
            sessionId
          } = data;
          const { caregiver, patient, address, clinicians } = enrollmentData;

          const trialArm = requiresClinicalTrial(Group.config)
            ? Group.config.clinicalTrial.arms.find((arm) => arm.id === patient.clinicalTrialArmId)
            : null;

          const assessments = {
            act:
              Group.config.actRequired === true && patient.disease === 'asthma'
                ? calculateActResults(adultAsthmaAssessment, childAsthmaAssessment)
                : undefined,
            cat:
              Group.config.catRequired === true && patient.disease === 'copd'
                ? copdAssessment.reduce((total, currentAnswer) => total + currentAnswer)
                : undefined
          };

          const formattedAddress = Group.config.mailingAddressRequired
            ? buildAddressData(address, Group.config.country)
            : undefined;

          let patientId;

          return this.createCaregiver({ caregiver, address: formattedAddress, agreements })
            .then((resolvedCaregiver) =>
              this.createPatient({
                patient,
                caregiver: resolvedCaregiver,
                address: formattedAddress,
                agreements,
                assessments
              })
            )
            .then((newPatient) => {
              patientId = newPatient.id;
            })
            .then(() => this.addMedications(patientId, enrollmentMedications))
            .then(() => this.addExternalIds(patientId, patient.externalIds))
            .then(() => this.addToSubGroups(patientId, Group.config.name, enrollmentSubGroups))
            .then(() => {
              const quizData = {
                patient,
                patientId,
                copdAssessment,
                adultAsthmaAssessment,
                childAsthmaAssessment,
                assessments
              };

              return this.submitQuizData(quizData).catch((err) => {
                Logger.warn('Error submitting quiz', stringToError(err));
              });
            })
            .then(() => {
              if (Group.config.hubAllowed === true && hubId !== '') {
                return Hub.submitHub(patientId, HUB_PREFIX + hubId).catch((err) => {
                  Logger.warn('Error submitting hub', stringToError(err));
                });
              }

              return $q.resolve();
            })
            .then(() => this.requestSMSLinks(caregiver, patient))
            .then(() => {
              if (trialArm && trialArm.allowPhysicianFollowers === false) {
                // unfollow at the very end. they need to be a follower
                // prior to this to be authorized to make all the requests
                return Patient.unfollowPhysicianFromPatient({
                  userId: patientId,
                  physicianId: sessionId
                });
              }

              if (clinicians && clinicians.length > 0) {
                return Patient.addPhysicianToPatient({
                  userId: patientId,
                  physicianIds: clinicians
                });
              }

              return $q.resolve();
            })
            .then(() => patientId);
        }
      };

      return AccountCreator;
    }
  );
