import angular from 'angular';
import app from '../../app';

import template from './no-data-message.html';
import './no-data-message.less';

angular.module(app).component('noDataMessage', {
  template,
  bindings: {
    icon: '@',
    text: '@',
    tooltip: '@',
    alt: '@'
  },
  controller: function NoDataMessageController() {
    const ctrl = this;

    ctrl.showNoDataTooltip = false;

    ctrl.$onInit = function init() {
      ctrl.altText = ctrl.alt || '';
    };
  }
});
