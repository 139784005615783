import angular from 'angular';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';

import app from '../../app';
import moment from '../moment';
import { isMissing, isPresent, isString, invalidString } from '../../utilities';
import { formatQueryParams } from '../../utilities/dataSubmission';

angular.module(app).factory('Patient', function ($q, $http, urls, Logger) {
  const fetchData = (request) => $http(request).then((result) => result.data);

  const getAlerts = (uid, params) =>
    merge(
      {
        url: `${urls.getUsersApi()}/${uid}/alerts`,
        method: 'GET',
        params
      },
      urls.get2xRequestDefaults()
    );

  const Patient = {
    addExternalIds(userId, externalIds) {
      if (!userId) {
        return $q.reject('No userId specified');
      }

      if (!externalIds) {
        return $q.reject('No externalIds specified');
      }

      if (!Array.isArray(externalIds)) {
        return $q.reject('externalIds need to be an array');
      }

      // PH API's '/api/users/:userId/externalId seems to have a bug
      // where it applies the external Id to the user in the bearer token,
      // not the user specified in the URL.
      // Note that if that gets addressed, that API also uses different key names
      // in the value, `type` --> `key`, `externalId` --> `value`
      // `urls.request4x` passes the bearer token to the our server so we can validate it.
      return urls.request4x('/api/externalId', {
        method: 'POST',
        data: {
          items: externalIds.map((exId) => ({ ...exId, userId }))
        }
      });
    },

    // This is here for easier cleanup during development, commented out for non-dev
    // deleteExternalId(userId, data) {
    //   const { domain, type, externalId } = data;
    //   const requestData = {
    //     domain,
    //     key: type,
    //     value: externalId
    //   };

    //   const query = Object.keys(requestData)
    //     .map((item) => `${encodeURIComponent(item)}=${encodeURIComponent(requestData[item])}`)
    //     .join('&');

    //   return urls.request4x(`${urls.getUsersApi()}/${userId}/externalId?${query}`, {
    //     method: 'DELETE'
    //   });
    // },

    findByExternalId(externalId) {
      const requiredAttributes = ['domain', 'type', 'externalId'];

      if (!externalId) {
        return $q.reject('No externalId specified');
      }

      const keys = Object.keys(externalId);

      if (keys.length !== requiredAttributes.length) {
        return $q.reject('externalId does not match expected structure');
      }

      if (requiredAttributes.some((requirement) => !keys.includes(requirement))) {
        return $q.reject('externalId does not match expected structure');
      }

      const query = Object.keys(externalId)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(externalId[key])}`)
        .join('&');

      // This request is to the provider portal's server,
      // as the PH API endpoint to look up a patient via externalId
      // only accepts application-level authentication.
      // We will still pass the bearer token to the our server so we can validate it.
      return urls.request4x(`/api/externalId?${query}`).then((res) => res.data);
    },

    getMetrics: function getMetrics(options) {
      if (!options) {
        return $q.reject('No options specified');
      }

      const { userId, ...queryOptions } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      let requestUrl = `${urls.getUsersApi()}/${userId}/metrics`;

      const { pagingToken, name, period, startDate, endDate, medId } = queryOptions;

      // If the user specified a paging token ignore the other options
      if (isString(pagingToken)) {
        requestUrl += `?pagingToken=${pagingToken}`;
      } else {
        requestUrl += '?name=';

        if (isString(name)) {
          requestUrl += name;
        } else if (Array.isArray(name)) {
          requestUrl += name.join('&name=');
        } else {
          return $q.reject('Bad name supplied');
        }

        if (isString(period)) {
          requestUrl += `&period=${period}`;
        }

        if (isString(startDate)) {
          requestUrl += `&startDate=${startDate}`;
        }

        if (isString(endDate)) {
          requestUrl += `&endDate=${endDate}`;
        }

        if (isString(medId)) {
          requestUrl += `&medicationId=${medId}`;
        }
      }

      const request = merge(
        {
          url: requestUrl,
          method: 'GET'
        },
        urls.getRequestDefaults()
      );

      return fetchData(request);
    },

    // We should  not yet request medication metrics on 4x
    // because of pagination/rate limit concerns
    getMetrics4x(options) {
      if (!options) {
        return $q.reject(new Error('No options specified'));
      }

      const { userId, pagingToken, ...queryOptions } = options;

      if (invalidString(userId)) {
        return $q.reject(new Error('Bad userId supplied'));
      }

      // If the user specified a paging token ignore the other options
      const queryString = pagingToken
        ? `pagingToken=${pagingToken}`
        : formatQueryParams(queryOptions);

      return urls
        .request4x(`${urls.getUsersApi()}/${userId}/metrics?${queryString}`, {
          headers: { 'x-ph-api-version': '4.63.0' }
        })
        .then((response) => response.data);
    },

    getPatientReport(userId) {
      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }
      return urls.request4x(`${urls.getUsersApi()}/${userId}/report`).then((res) => res.data);
    },

    getUserById(userId) {
      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      return urls
        .request4x(`${urls.getUsersApi()}/${userId}?include=subgroups`)
        .then((res) => res.data);
    },

    getAlertHistory: function getAlertHistory(options) {
      if (!options) {
        return $q.reject('No options specified');
      }

      const { userId } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      const params = [
        {
          deleted: false,
          acknowledged: false
        },
        {
          deleted: true,
          acknowledged: false
        },
        {
          deleted: false,
          acknowledged: true
        }
      ];

      return (
        $q
          .all(params.map((param) => fetchData(getAlerts(userId, param))))
          // eslint-disable-next-line prefer-spread
          .then((allData) => [].concat.apply([], allData))
          // all data is an array of arrays
          // we could [].concat(...allData), but ultimately babel would
          // transpile to the above plus additional code
          .then((alerts) => sortBy(alerts, (a) => -moment(a.createD, 'MM/DD/YYYY')))
      );
    },

    updatePatient: function updatePatient(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, userUpdates } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      if (isMissing(userUpdates)) {
        return $q.reject('Bad user data');
      }

      return urls.request4x(`${urls.getUsersApi()}/${userId}`, {
        method: 'PATCH',
        data: userUpdates
      });
    },

    unfollowPhysicianFromPatient: function unfollowPhysicianFromPatient(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, physicianId } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      if (invalidString(physicianId)) {
        return $q.reject('Bad physicianId supplied');
      }

      const request = merge(
        {
          method: 'DELETE',
          url: `${urls.getUsersApi()}/${userId}/followers/${physicianId}`
        },
        urls.getRequestDefaults()
      );

      return fetchData(request);
    },

    addPhysicianToPatient: function addPhysicianToPatient(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, physicianIds } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      if (isMissing(physicianIds) || !Array.isArray(physicianIds)) {
        return $q.reject('Bad physicianIds array supplied');
      }

      const request = merge(
        {
          method: 'POST',
          url: `${urls.getUsersApi()}/${userId}/share`,
          data: {
            uid: physicianIds,
            permissions: {
              clinical: 'modify',
              locations: 'modify',
              personal: 'modify',
              sensor: 'modify'
            }
          }
        },
        urls.get2xRequestDefaults()
      );

      return fetchData(request);
    },

    getDefaultCaregiver(patient) {
      const caregiver = patient.caregivers[0];

      if (caregiver && caregiver.id) {
        return Patient.getUserById(caregiver.id);
      }

      return $q.reject('No caregiver for user');
    },

    quizForPatient: function quizForPatient(aPatient) {
      // eslint-disable-next-line no-nested-ternary
      return Patient.hasCOPD(aPatient) ? 'cat' : aPatient.age <= 11 ? 'actChild' : 'actAdult';
    },

    hasCOPD: function hasCOPD(aPatient) {
      return aPatient.disease === 'copd';
    },

    setFollowers(aPatient) {
      const { followers } = aPatient;
      const caregivers = [];
      const physicians = [];

      if (isPresent(followers)) {
        followers.forEach((follower) => {
          if (follower.role === 'caregiver') {
            caregivers.push(follower);
          } else if (follower.role === 'practitioner') {
            physicians.push(follower);
          }
        });
      }

      aPatient.caregivers = caregivers;
      aPatient.physicians = physicians;
    },

    hasFakeEmail(aPatient) {
      const { email, phone } = aPatient;
    
      return (
        (email && email.substring(0, 4) === 'fake' &&
          email.substring(email.length - 20) === '@propellerhealth.com') || 
            (phone && phone.substring(1, phone.length) === email.substring(0, phone.length-1) && 
              (email.split("+")[1]).length === 25 && 
                (email.split('@')[1]) === 'propeller.health')
      );
    }
  };

  return Patient;
});
