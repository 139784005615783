import angular from 'angular';
import app from '../../app';

angular.module(app).config(function ($stateProvider) {
  $stateProvider.state({
    name: 'abstractList',
    parent: 'main',
    component: 'abstractList',
    abstract: true,
    onEnter: [
      '$translatePartialLoader',
      function onEnter($translatePartialLoader) {
        $translatePartialLoader.addPart('directives/patients-list');
      }
    ]
  });
});
