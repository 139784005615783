/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import BsTooltip from 'react-bootstrap/lib/Tooltip';

import './styles.less';

// react-bootstrap passes props for positioning, etc.
function PhTooltip({ children, id, placement, className, ...rest }) {
  return (
    <BsTooltip
      id={id}
      placement={placement}
      className={classNames('ph-tooltip', className)}
      {...rest}
    >
      {children}
    </BsTooltip>
  );
}

PhTooltip.propTypes = {
  children: node.isRequired,
  className: string,
  id: string.isRequired,
  placement: string
};

PhTooltip.defaultProps = {
  className: '',
  placement: null
};

export default PhTooltip;
