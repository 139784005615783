import angular from 'angular';
import app from '../../app';

angular.module(app).service('SMARTClient', function SMARTClient() {
  let smartClient;

  return {
    get() {
      return smartClient;
    },
    set(client) {
      smartClient = client;
    },
    needPatientBanner() {
      if (!smartClient) return true;

      try {
        return smartClient.getState().tokenResponse.need_patient_banner;
      } catch (e) {
        return true;
      }
    }
  };
});
