/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import { bool, func, node, string, oneOf } from 'prop-types';
import classNames from 'classnames';
import analytics from '../../utilities/analytics';

import './circularButton.less';

// I think there's an argument for using rest/spread props here...
// TODO: make a more generic button that this and Button
// (and others?) use, since both are really just stylistic overrides?
function CircularButton({
  analyticsPage,
  analyticsLabel,
  'aria-describedby': ariaDescribedby,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHaspopup,
  children,
  className,
  id,
  isDisabled,
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  onMouseOut,
  onMouseOver,
  type
}) {
  const handleClick = useCallback(
    (e) => {
      if (!isDisabled && onClick) {
        if (analyticsPage && analyticsLabel) {
          analytics.track('click', { pageId: analyticsPage, labelId: analyticsLabel });
        }

        onClick(e);
      }
    },
    [isDisabled, onClick, analyticsPage, analyticsLabel]
  );

  return (
    <button
      aria-describedby={ariaDescribedby}
      aria-disabled={isDisabled}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      className={classNames(`circular-button-2`, className)}
      id={id}
      type={type}
      onBlur={onBlur}
      onClick={handleClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      {children}
    </button>
  );
}

CircularButton.propTypes = {
  analyticsPage: string.isRequired,
  analyticsLabel: string.isRequired,
  'aria-describedby': string,
  'aria-expanded': bool,
  'aria-haspopup': bool,
  children: node.isRequired,
  className: string,
  id: string,
  isDisabled: bool,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onKeyDown: func,
  onMouseEnter: func,
  onMouseLeave: func,
  onMouseOut: func,
  onMouseOver: func,
  type: oneOf(['button', 'submit', 'reset'])
};

CircularButton.defaultProps = {
  'aria-describedby': null,
  'aria-expanded': null,
  'aria-haspopup': null,
  className: null,
  id: null,
  isDisabled: false,
  onClick: null,
  onBlur: null,
  onFocus: null,
  onKeyDown: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onMouseOut: null,
  onMouseOver: null,
  type: 'button'
};

export default CircularButton;
