import {
  DEFAULT_ADULT_AGE,
  CH_ADULT_AGE,
  PT_ADULT_AGE,
  US_ADULT_AGE
} from '../../utilities/constants';

const ADDRESS_FIELDS = {
  street: 'street',
  street2: 'street2',
  city: 'city',
  stateOrRegion: 'stateOrRegion',
  postalCode: 'postalCode'
};

const ADDRESS_LABELS = {
  canton: 'ADDRESS.CANTON',
  city: 'ADDRESS.CITY',
  county: 'ADDRESS.COUNTY',
  districtCity: 'ADDRESS.DISTRICT_CITY',
  eircode: 'ADDRESS.EIRCODE',
  locality: 'ADDRESS.LOCALITY',
  municipality: 'ADDRESS.MUNICIPALITY',
  placeName: 'ADDRESS.PLACE_NAME',
  postalCode: 'ADDRESS.POSTAL_CODE',
  province: 'ADDRESS.PROVINCE',
  state: 'ADDRESS.STATE',
  stateOrRegion: 'ADDRESS.STATE_OR_REGION',
  street1: 'ADDRESS.STREET_LINE_ONE',
  street2: 'ADDRESS.STREET_LINE_TWO',
  territory: 'ADDRESS.TERRITORY',
  zipCode: 'ADDRESS.ZIP_CODE'
};

const FOUR_DIGITS = '^\\d{4}$';
const FIVE_DIGITS = '^\\d{5}$';

export const POSTAL_CODE_RX = {
  AR: '^(([a-zA-Z]\\d{4}[a-zA-Z]{3})|(\\d{4}))$' /* A9999AAA or, older, 9999 */,
  AT: FOUR_DIGITS,
  AU: FOUR_DIGITS,
  CA:
    '^(?!.*[DFIOQUdfioqu])[A-VXYav-xy]\\d[A-Za-z][- ]?\\d[A-Za-z]\\d$' /* A9A 9A9, with optional space or dash, some letters never allowed */,
  CH: FOUR_DIGITS,
  CZ: '^\\d{3}[ -]?\\d{2}$' /* 999 99 */,
  DE: FIVE_DIGITS,
  DK: FOUR_DIGITS,
  ES: FIVE_DIGITS,
  FI: FIVE_DIGITS,
  FR: FIVE_DIGITS,
  GB:
    '^[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}$' /* AA9A 9AA, AA99 9AA, A9A 9AA, A99 9AA */,
  GR: '^\\d{3}[ -]?\\d{2}$' /* 999 99 */,
  IE:
    '^([a-zA-Z]\\d{2}|[Dd]6[Ww]) ?[a-zA-Z0-9]{4}$' /* "The first character is always a letter, followed by 2 numbers (except for D6W)." */,
  IT: FIVE_DIGITS,
  JP: '^\\d{3}([- ]?\\d{4})$' /* 999-9999 */,
  KR: FIVE_DIGITS,
  MX: FIVE_DIGITS,
  NL: '^\\d{4} ?[a-zA-Z]{2}$' /* 9999 AA */,
  NO: FOUR_DIGITS,
  PT: '^\\d{4}[ -]?\\d{3}$' /* 9999 999 */,
  RU: '^\\d{6}$' /* 999999 */,
  SE: '^\\d{3}[ -]?\\d{2}$' /* 999 99 */,
  US: '^\\d{5}([- ]?\\d{4})?$' /* 99999-9999, last 4 optional */
};

const splitOnIndex = (index, sep = ' ') => str => {
  str = str.toUpperCase().replace(/[^A-Z0-9]/g, '');
  return `${str.slice(0, index)}${sep}${str.slice(index)}`;
};

// make sure valid input is properly structured
// the regexes may be tolerant about missing spaces, dashes, casing
export const POSTAL_CODE_SETTERS = {
  AR: value => value.toUpperCase(),
  CA: splitOnIndex(3),
  CZ: splitOnIndex(3),
  GB: splitOnIndex(-3),
  GR: splitOnIndex(3),
  IE: splitOnIndex(3),
  JP: value => splitOnIndex(3, '-')(value),
  NL: splitOnIndex(4),
  PT: splitOnIndex(4),
  US: value => {
    value = value.replace(/[^0-9]/g, '');
    if (value.length === 5) return value;

    return splitOnIndex(5, '-')(value);
  }
};

const COUNTRIES = [
  ['AR', 'Argentina', ''],
  ['AT', 'Austria', ''],
  ['AU', 'Australia', ''],
  ['CA', 'Canada', '1 555 555 5555'],
  ['CH', 'Switzerland', ''],
  ['CZ', 'čeština', ''],
  ['DE', 'Germany', ''],
  ['DK', 'Denmark', ''],
  ['ES', 'Spain', ''],
  ['FI', 'Suomi (FI)', ''],
  ['FR', 'France', ''],
  ['GB', 'United Kingdom', ''],
  ['GR', 'Ελληνικά', ''],
  ['IE', 'Ireland', ''],
  ['IT', 'Italy', ''],
  ['JP', '日本', ''],
  ['KR', '한국', ''],
  ['MX', 'Mexico', ''],
  ['NL', 'Netherlands', ''],
  ['NO', 'norsk bokmål', ''],
  ['PT', 'Portugal', ''],
  ['RU', 'Россия', ''],
  ['SE', 'Sweden', ''],
  ['US', 'United States', '1 555 555 5555']
];

const AU_TERRITORIES = [
  'ACT|Australian Capital Territory',
  'NSW|New South Wales',
  'NT|Northern Territory',
  'QLD|Queensland',
  'SA|South Australia',
  'TAS|Tasmania',
  'VIC|Victoria',
  'WA|Western Australia'
];

const CA_PROVINCES = [
  'AB|Alberta',
  'BC|British Columbia',
  'MB|Manitoba',
  'NB|New Brunswick',
  'NL|Newfoundland and Labrador',
  'NT|Northwest Territories',
  'NS|Nova Scotia',
  'NU|Nunavut',
  'ON|Ontario',
  'PE|Prince Edward Island',
  'QC|Quebec',
  'SK|Saskatchewan',
  'YT|Yukon Territories'
];

const CH_CANTONS = [
  'AG|Aargau',
  'AR|Appenzell AusserRhoden',
  'AI|Appenzell Inner-Rhoden',
  'BL|Basel-Landschaft',
  'BS|Basel-Stadt',
  'BE|Bern',
  'FR|Fribourg',
  'GE|Geneva',
  'GL|Glarus',
  'GR|Grisons',
  'JU|Jura',
  'LU|Luzern',
  'NE|Neuchâtel',
  'NW|Nidwalden',
  'OW|Obwalden',
  'SH|Schaffhausen',
  'SZ|Schwyz',
  'SO|Solothurn',
  'SG|St. Gallen',
  'TG|Thurgau',
  'TI|Ticino',
  'UR|Uri',
  'VS|Valais',
  'VD|Vaud',
  'ZG|Zug',
  'ZE|Zürich'
];

const ES_PROVINCES = [
  'Alava|Álava',
  'Albacete',
  'Alicente',
  'Almeria|Almería',
  'Asturias',
  'Avila|Ávila',
  'Badajoz',
  'Baleares',
  'Barcelona',
  'Burgos',
  'Caceres|Cáceres',
  'Cadiz|Cádiz',
  'Cantabria',
  'Castellon|Castellón',
  'Ceuta',
  'Ciudad Real',
  'Cordoba|Córdoba',
  'La Coruna|La Coruña ',
  'Cuenca',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Guipuzcoa|Guipúzcoa',
  'Huelva',
  'Huesca',
  'Jaen|Jaén',
  'Leon|León',
  'Lleida',
  'Lugo',
  'Madrid',
  'Malaga|Málaga',
  'Melilla',
  'Murcia',
  'Navarre',
  'Orense',
  'Palencia',
  'Las Palmas',
  'Pontevedra',
  'La Rioja',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza'
];

const IE_STATES = [
  'CW|Carlow',
  'CN|Cavan',
  'CE|Clare',
  'CO|Cork',
  'DL|Donegal',
  'D|Dublin',
  'G|Galway',
  'KY|Kerry',
  'KE|Kildare',
  'KK|Kilkenny',
  'LS|Laois',
  'LM|Leitrim',
  'LK|Limerick',
  'LD|Longford',
  'LH|Louth',
  'MO|Mayo',
  'MH|Meath',
  'MN|Monaghan',
  'OY|Offaly',
  'RN|Roscommon',
  'SO|Sligo',
  'TA|Tipperary',
  'WD|Waterford',
  'WH|Westmeath',
  'WX|Wexford',
  'WW|Wicklow'
];

const IT_REGIONS = [
  'AG|Agrigento',
  'AL|Alessandria',
  'AN|Ancona',
  'AO|Aosta',
  'AR|Arezzo',
  'AP|Ascoli Piceno',
  'AT|Asti',
  'AV|Avellino',
  'BA|Bari',
  'BT|Barletta-Andria-Trani',
  'BL|Belluno',
  'BN|Benevento',
  'BG|Bergamo',
  'BI|Biella',
  'BO|Bologna',
  'BZ|Bolzano',
  'BS|Brescia',
  'BR|Brindisi',
  'CA|Cagliari',
  'CL|Caltanissetta',
  'CB|Campobasso',
  'CI|Carbonia-Iglesias',
  'CE|Caserta',
  'CT|Catania',
  'CZ|Catanzaro',
  'CH|Chieti',
  'SCV|Vatican City|Città del Vaticano',
  'CO|Como',
  'CS|Cosenza',
  'CR|Cremona',
  'KR|Crotone',
  'CN|Cuneo',
  'EN|Enna',
  'FM|Fermo',
  'FE|Ferrara',
  'FI|Florence',
  'FG|Foggia',
  'FC|Forlì-Cesena',
  'FR|Frosinone',
  'GE|Genoa',
  'GO|Gorizia',
  'GR|Grosseto',
  'IM|Imperia',
  'IS|Isernia',
  'SP|La Spezia',
  "AQ|L'Aquila",
  'LT|Latina',
  'LE|Lecce',
  'LC|Lecco',
  'LI|Livorno',
  'LO|Lodi',
  'LU|Lucca',
  'MC|Macerata',
  'MN|Mantua',
  'MS|Massa and Carrara|Massa-Carrara',
  'MT|Matera',
  'VS|Medio Campidano',
  'ME|Messina',
  'MI|Milan',
  'MO|Modena',
  'MB|Monza and Brianza|Monza e della Brianza',
  'NA|Naples',
  'NO|Novara',
  'NU|Nuoro',
  'OG|Ogliastra',
  'OT|Olbia-Tempio',
  'OR|Oristano',
  'PD|Padua',
  'PA|Palermo',
  'PR|Parma',
  'PV|Pavia',
  'PG|Perugia',
  'PU|Pesaro and Urbino|Pesaro e Urbino',
  'PE|Pescara',
  'PC|Piacenza',
  'PI|Pisa',
  'PT|Pistoia',
  'PN|Pordenone',
  'PZ|Potenza',
  'PO|Prato',
  'RG|Ragusa',
  'RA|Ravenna',
  'RC|Reggio Calabria',
  'RE|Reggio Emilia',
  'RSM|Repubic of San Marino|Repubblica di San Marino',
  'RI|Rieti',
  'RN|Rimini',
  'RM|Rome',
  'RO|Rovigo',
  'SA|Salerno',
  'SS|Sassari',
  'SV|Savona',
  'SI|Siena',
  'SO|Sondrio',
  'SR|Syracuse',
  'TA|Taranto',
  'TE|Teramo',
  'TR|Terni',
  'TP|Trapani',
  'TN|Trento',
  'TV|Treviso',
  'TS|Trieste',
  'TO|Turin',
  'UD|Udine',
  'VA|Varese',
  'VB|Verbano-Cusio-Ossola',
  'VC|Vercelli',
  'VE|Venice',
  'VR|Verona',
  'VV|Vibo Valentia',
  'VI|Vicenza',
  'VT|Viterbo'
];

const MX_STATES = [
  'AGS|Aguascalientes',
  'BC|Baja California',
  'BCS|Baja California Sur',
  'CAM|Campeche',
  'CHIS|Chiapas',
  'CHIH|Chihuahua',
  'COAH|Coahuila',
  'COL|Colima',
  'CDMX|Federal District|Cuidad de México',
  'DGO|Durango',
  'GTO|Guanajuato',
  'GRO|Guerrero',
  'HGO|Hidalgo',
  'JAL|Jalisco',
  'MEX|Mexico State|Mexico',
  'MICH|Michoacán',
  'MOR|Morelos',
  'NAY|Nayarit',
  'NL|Nuevo León',
  'OAX|Oaxaca',
  'PUE|Puebla',
  'QRO|Querétaro',
  'Q ROO|Quintana Roo',
  'SLP|San Luis Potosí',
  'SIN|Sinaloa',
  'SON|Sonora',
  'TAB|Tabasco',
  'TAMPS|Tamaulipas',
  'TLAX|Tlaxcala',
  'VER|Veracruz',
  'YUC|Yucatán',
  'ZAC|Zacatecas'
];

const KR_PROVINCES = [
  'Busan',
  'Chungcheongbuk-do',
  'Chungcheongnam-do',
  'Daegu',
  'Daejeon',
  'Gangwon-do',
  'Gwangju',
  'Gyeonggi-do',
  'Gyeongsangbuk-do',
  'Gyeongsangnam-do',
  'Incheon',
  'Jeollabuk-do',
  'Jeollanam-do',
  'Jeju Special Self-governing Province',
  'Seoul',
  'Sejong',
  'Ulsan'
];

const US_STATES = [
  'AL|Alabama',
  'AK|Alaska',
  'AZ|Arizona',
  'AR|Arkansas',
  'CA|California',
  'CO|Colorado',
  'CT|Connecticut',
  'DE|Delaware',
  'DC|District Of Columbia',
  'FL|Florida',
  'GA|Georgia',
  'HI|Hawaii',
  'ID|Idaho',
  'IL|Illinois',
  'IN|Indiana',
  'IA|Iowa',
  'KS|Kansas',
  'KY|Kentucky',
  'LA|Louisiana',
  'ME|Maine',
  'MD|Maryland',
  'MA|Massachusetts',
  'MI|Michigan',
  'MN|Minnesota',
  'MS|Mississippi',
  'MO|Missouri',
  'MT|Montana',
  'NE|Nebraska',
  'NV|Nevada',
  'NH|New Hampshire',
  'NJ|New Jersey',
  'NM|New Mexico',
  'NY|New York',
  'NC|North Carolina',
  'ND|North Dakota',
  'OH|Ohio',
  'OK|Oklahoma',
  'OR|Oregon',
  'PA|Pennsylvania',
  'RI|Rhode Island',
  'SC|South Carolina',
  'SD|South Dakota',
  'TN|Tennessee',
  'TX|Texas',
  'UT|Utah',
  'VT|Vermont',
  'VA|Virginia',
  'WA|Washington',
  'WV|West Virginia',
  'WI|Wisconsin',
  'WY|Wyoming'
];

const JP_PREFECTURES = [
  'Aiti',
  'Akita',
  'Aomori',
  'Ehime',
  'Gihu',
  'Gunma',
  'Hirosima',
  'Hokkaidô',
  'Hukui',
  'Hukuoka',
  'Hukusima',
  'Hyôgo',
  'Ibaraki',
  'Isikawa',
  'Iwate',
  'Kagawa',
  'Kagosima',
  'Kanagawa',
  'Kôti',
  'Kumamoto',
  'Kyôto',
  'Mie',
  'Miyagi',
  'Miyazaki',
  'Nagano',
  'Nagasaki',
  'Nara',
  'Niigata',
  'Ôita',
  'Okayama',
  'Okinawa',
  'Ôsaka',
  'Saga',
  'Saitama',
  'Siga',
  'Simane',
  'Sizuoka',
  'Tiba',
  'Totigi',
  'Tokusima',
  'Tôkyô',
  'Tottori',
  'Toyama',
  'Wakayama',
  'Yamagata',
  'Yamaguti',
  'Yamanasi'
];

function structureRegions(aString) {
  // eslint-disable-next-line prefer-const
  let [code, value, displayName] = aString.split('|');
  value = value || code;
  displayName = displayName || value;

  return {
    code,
    value,
    displayName,
    id: value,
    searchString: aString.replace(/\|/g, ' ')
  };
}

/**
 * [
 *     [attr, label, required, inputProps, additionalProps], // lists of attributes for an input
 * ]
 */

// ['MX', 'Mexico', ''],
// ['NL', 'Netherlands', ''],

const ADDRESS_STRUCTURE = {
  AR: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],

    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.AR, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.AR }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true]
  ],

  AT: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.AT, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true, {}]
  ],

  AU: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.placeName, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.state,
      true,
      {},
      {
        options: AU_TERRITORIES.map(structureRegions)
      }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.AU, className: 'input--width-10' }
    ]
  ],

  CA: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.province,
      true,
      {},
      { options: CA_PROVINCES.map(structureRegions) }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.CA, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.CA }
    ]
  ],

  CH: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.CH, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.canton,
      false,
      {},
      { options: CH_CANTONS.map(structureRegions) }
    ]
  ],

  CZ: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],

    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.CZ, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.CZ }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true]
  ],

  DE: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.DE, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true, {}]
  ],

  DK: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.DK, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true, {}]
  ],

  ES: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.ES, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.locality, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.province,
      true,
      {},
      { options: ES_PROVINCES.map(structureRegions) }
    ]
  ],

  FI: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],

    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.FI, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.FI }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true]
  ],

  FR: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.FR, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.locality, true]
  ],

  GB: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.locality, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.GB, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.GB }
    ]
  ],

  GR: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],

    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.GR, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.GR }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true]
  ],

  IE: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.county,
      false,
      {},
      { options: IE_STATES.map(structureRegions) }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.eircode,
      false,
      { pattern: POSTAL_CODE_RX.IE, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.IE }
    ]
  ],

  IT: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.IT, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.province,
      true,
      {},
      { options: IT_REGIONS.map(structureRegions) }
    ]
  ],

  JP: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.state,
      true,
      {},
      {
        options: JP_PREFECTURES.map(structureRegions)
      }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.zipCode,
      true,
      {
        pattern: POSTAL_CODE_RX.JP,
        className: 'input--width-10'
      },
      { setter: POSTAL_CODE_SETTERS.JP }
    ]
  ],

  KR: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.province,
      true,
      {},
      { options: KR_PROVINCES.map(structureRegions) }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.KR, className: 'input--width-10' }
    ]
  ],

  MX: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.MX, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.province,
      false,
      {},
      {
        options: MX_STATES.map(structureRegions)
      }
    ]
  ],

  NL: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      {
        pattern: POSTAL_CODE_RX.NL,
        className: 'input--width-10'
      },
      {
        setter: POSTAL_CODE_SETTERS.NL
      }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true]
  ],

  NO: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],

    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.NO, className: 'input--width-10' },
      { setter: POSTAL_CODE_SETTERS.NO }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.municipality, true]
  ],

  PT: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.locality, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      {
        pattern: POSTAL_CODE_RX.PT,
        className: 'input--width-10'
      },
      { setter: POSTAL_CODE_SETTERS.PT }
    ],
    [ADDRESS_FIELDS.stateOrRegion, ADDRESS_LABELS.districtCity, true]
  ],

  RU: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [ADDRESS_FIELDS.stateOrRegion, ADDRESS_LABELS.stateOrRegion, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      {
        pattern: POSTAL_CODE_RX.RU,
        className: 'input--width-10'
      }
    ]
  ],

  SE: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.postalCode,
      true,
      { pattern: POSTAL_CODE_RX.SE, className: 'input--width-10' }
    ],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true, {}]
  ],

  US: [
    [ADDRESS_FIELDS.street, ADDRESS_LABELS.street1, true],
    [ADDRESS_FIELDS.street2, ADDRESS_LABELS.street2, false],
    [ADDRESS_FIELDS.city, ADDRESS_LABELS.city, true],
    [
      ADDRESS_FIELDS.stateOrRegion,
      ADDRESS_LABELS.state,
      true,
      {},
      {
        options: US_STATES.map(structureRegions)
      }
    ],
    [
      ADDRESS_FIELDS.postalCode,
      ADDRESS_LABELS.zipCode,
      true,
      {
        pattern: POSTAL_CODE_RX.US,
        className: 'input--width-10'
      },
      { setter: POSTAL_CODE_SETTERS.US }
    ]
  ]
};

// ages defined in the localization SRS
// https://asthmapolis.jira.com/wiki/spaces/REG/overview

function setAdultAge(country) {
  switch (country) {
    case 'CH':
      return CH_ADULT_AGE;

    case 'PT':
      return PT_ADULT_AGE;

    case 'US':
      return US_ADULT_AGE;

    default:
      return DEFAULT_ADULT_AGE;
  }
}

export const defaultCountry = 'US';

export const options = COUNTRIES.reduce((accumulator, [code, name, phonePlaceholderString]) => {
  accumulator[code] = {
    name,
    phonePlaceholder: phonePlaceholderString,
    adultAge: setAdultAge(code)
  };

  return accumulator;
}, {});

export function phonePlaceholder(code = defaultCountry) {
  const country = options[code] || {};

  return country.phonePlaceholder || '';
}

// memoized to ignore duplicate calls
export const addressStructure = (function() {
  let country;
  let addressLines;

  return function _addressStructure(aCountry = defaultCountry) {
    if (typeof aCountry !== 'string') {
      throw new Error('Invalid country');
    }

    if (country === aCountry.toUpperCase() && addressLines) {
      return addressLines;
    }

    country = aCountry.toUpperCase();

    const lines = ADDRESS_STRUCTURE[country];

    if (!lines) {
      // eslint-disable-next-line no-console
      console.warn('No address structure found for', country);
      return [];
    }

    addressLines = lines.map(field => {
      const [attribute, label, required, inputProps = {}, additionalProps = {}] = field;

      return {
        attribute,
        label,
        required,
        inputProps,
        additionalProps
      };
    });

    return addressLines;
  };
})();

// there could be variability in address.state, eg. "Wisconsin" vs. "WI"
// If/when we need to support this in other countries we will need to take a deeper look.
// Ultimately we need to set something so it's preselected in the UI.
// How the data coming in looks can vary, and the variation will become more pronunced
// in other countries because constraints on what the platform, or more pointedly SFDC, allows.
export function addressState(state, country) {
  try {
    const addressInfo = addressStructure(country);
    const { options: stateOptions } = addressInfo.find((a) => a.attribute === 'stateOrRegion').additionalProps;

    if (!stateOptions) {
      return state;
    }

    const rx = new RegExp(`^${state}$`, 'i');

    if (state.length === 2) {
      return stateOptions.find((option) => rx.test(option.code)).value;
    }

    return stateOptions.find((option) => rx.test(option.value)).value;
  } catch (_) {
    return undefined;
  }
}

export default {
  defaultCountry,
  options,
  phonePlaceholder
};
