import angular from 'angular';
import find from 'lodash/find';
import intersection from 'lodash/intersection';

import app from '../../app';
import moment from '../moment';

import enrollmentImg from '../../../assets/images/announcement_enrollment.jpg';

angular.module(app).factory('Announcements', function(User, $translatePartialLoader, $log) {
  $translatePartialLoader.addPart('services/Announcements');

  /**
     * An example announcement object:
     * `date` is the cutoff, if the last announceent viewed is before the date on the announcement, we show it
     * We could update logic to have announcements not be displayed if a date is in the future
     * `topic` isn't really used, but could be for analytics
     * `size` is optional, sets the size of the modal. defaults to 'md'
     * `diseases` is optional. If present and not empty, we only show it for groups that overlap the diseases
     * `href` is where to link the button to. If absent, the button just closes the modal
     * We interplolate the person's language preference into `{{LOCALE}}`
     * `includeRider` is to handle some additional text below
     * {
        date: '2019-02-17',
        topic: 'patientReports',
        size: 'lg',
        diseases: ['asthma'],
        title: 'REPORTS-ANNOUNCEMENT-TITLE',
        content: [
          { text: 'REPORTS-ANNOUNCEMENT-1' },
          { text: 'REPORTS-ANNOUNCEMENT-2' }
        ],
        actionText: 'HOW-TO-RUN-REPORT',
        href  : "https://providersupport.propellerhealth.com/hc/{{LOCALE}}/articles/360023107451-How-do-I-view-and-generate-a-patient-report-",
        image : imgReference
      },
     *
     */

  const announcements = [
    {
      date: '2021-01-02',
      size: 'lg',
      topic: 'providerEnrollment',
      title: 'ENROLLMENT_ANNOUNCEMENT_HEADER',
      content: [
        { text: 'ENROLLMENT_ANNOUNCEMENT_CONTENT_1' },
        { text: 'ENROLLMENT_ANNOUNCEMENT_CONTENT_2' }
      ],
      actionText: 'ENROLLMENT_ANNOUNCEMENT_CTA',
      href: 'https://cliniciansupport.propellerhealth.com/s/article/Provider-Portal-Video-Enrollment-With-Sensors?language={{LOCALE}}',
      image: enrollmentImg,
      includeRider: true
    }
  ];

  // if no diseases specified, no restriction && return true.
  // if diseases array is empty, no restriction && return true
  // if diseases array has items, compare against group diseases
  // and return true only if there is an overlap
  const isForDisease = (anAnnouncement, diseases) =>
    !anAnnouncement.diseases ||
    anAnnouncement.diseases.length === 0 ||
    intersection(anAnnouncement.diseases, diseases).length > 0;

  const Announcements = {
    getActiveAnnouncements: function getActiveAnnouncements() {
      return announcements;
    },

    getCurrentAnnouncement: function getCurrentAnnouncement(user, group) {
      const groupDiseases = group.diseases;
      const lastViewed = moment(user.preferences.lastAnnouncement || user.createdDate);

      return find(
        this.getActiveAnnouncements(),
        anAnnouncement =>
          moment(anAnnouncement.date).isAfter(lastViewed) &&
          isForDisease(anAnnouncement, groupDiseases)
      );
    },

    dismiss: function dismiss(announcement) {
      const date = announcement ? announcement.date : new Date();
      User.updateUser({
        preferences: {
          lastAnnouncement: moment(date).toISOString()
        }
      }).catch(err => $log.error(err));
    }
  };

  return Announcements;
});
