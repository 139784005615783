import React, { useState, useRef } from 'react';
import { func, node, string } from 'prop-types';
import classNames from 'classnames';
import BsDropdown from 'react-bootstrap/lib/Dropdown';
import BsOverlay from 'react-bootstrap/lib/Overlay';

import Button from '../Button';
import PhTooltip from '../PhTooltip';

import './styles.less';

function PhDropdown({
  id,
  className,
  toggle,
  toggleComponent: ToggleComponent = Button,
  tooltip,
  children,
  analyticsPage,
  analyticsLabel
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTriggerRef = useRef(null);
  const hasTooltip = Boolean(tooltip);

  return (
    <>
      <BsDropdown id={id} className={classNames('ph-dropdown', className)} componentClass="li">
        <BsDropdown.Toggle
          noCaret
          componentClass={ToggleComponent}
          ref={(button) => {
            // not sure if this is landing in the right place
            tooltipTriggerRef.current = button;
          }}
          aria-describedby={hasTooltip ? `${id}--tooltip` : null}
          onMouseOver={() => hasTooltip && setShowTooltip(true)}
          onMouseOut={() => hasTooltip && setShowTooltip(false)}
          onFocus={() => hasTooltip && setShowTooltip(true)}
          onBlur={() => hasTooltip && setShowTooltip(false)}
          analyticsPage={analyticsPage}
          analyticsLabel={analyticsLabel}
        >
          {toggle}
        </BsDropdown.Toggle>

        <BsDropdown.Menu>{children}</BsDropdown.Menu>
      </BsDropdown>

      {hasTooltip ? (
        <BsOverlay
          placement="bottom"
          target={tooltipTriggerRef.current}
          rootClose
          show={showTooltip}
          onHide={() => setShowTooltip(false)}
        >
          <PhTooltip id={`${id}--tooltip`}>{tooltip}</PhTooltip>
        </BsOverlay>
      ) : null}
    </>
  );
}

PhDropdown.propTypes = {
  analyticsLabel: string.isRequired,
  analyticsPage: string.isRequired,
  children: node.isRequired,
  className: string,
  id: string.isRequired,
  toggle: node.isRequired,
  toggleComponent: func,
  tooltip: node
};

PhDropdown.defaultProps = {
  className: null,
  tooltip: null,
  toggleComponent: Button
};

export default PhDropdown;
