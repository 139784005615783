import moment from '../../services/moment';
import { ADULT_ACT_AGE, DATE_FORMAT } from '../constants';
import { hasItems } from '../index';

export const defaultTimezone = moment.tz.guess();

export function getAge(birthDate) {
  if (!birthDate) throw new Error('No birthDate provided');

  return moment().diff(moment(birthDate, DATE_FORMAT), 'years', true);
}

export function isChild(birthDate, minAdultAge) {
  if (!birthDate) return false;

  if (typeof minAdultAge !== 'number') {
    throw new Error('Invalid second argument. Pass an age as a number');
  }

  const age = getAge(birthDate);

  return age >= 0 && age < minAdultAge;
}

export function showChildACT(birthDate) {
  return isChild(birthDate, ADULT_ACT_AGE);
}

export function requiresClinicalTrial(config) {
  return Boolean(config.clinicalTrial) && hasItems(config.clinicalTrial.arms);
}

function momentError(idx) {
  switch (idx) {
    case 0:
      return 'errors:birthDate.INVALID_YEAR';

    case 1:
      return 'errors:birthDate.INVALID_MONTH';

    case 2:
      return 'errors:birthDate.INVALID_DAY';

    default:
      return 'errors:birthDate.INVALID_DATE';
  }
}

export function errorMessageForDate(date) {
  if (!date) {
    return 'errors:birthDate.valueMissing';
  }

  const dateObj = moment(date, DATE_FORMAT);

  if (!dateObj.isValid()) {
    return momentError(dateObj.invalidAt());
  }

  // do various validity checks
  const age = getAge(date);

  if (age < 0) return 'errors:age.futureDate';
  if (age < 2) return 'errors:age.tooYoung';
  if (age > 140) return 'errors:age.tooOld';

  return null;
}

export function fullBirthDate(elements) {
  const fields = ['year', 'month', 'day'];

  const values = fields.map((field) => elements[`birthDate.${field}`].value);

  if (values.some((value) => !value)) {
    // do no more work if any are empty or otherwise falsey
    return undefined;
  }

  return values.join('-');
}

export function birthDateErrorMessage(form, fullValue) {
  const birthDate = fullValue || fullBirthDate(form.elements);
  return errorMessageForDate(birthDate);
}

export function needsHub(patient, caregiver) {
  return caregiver.include ? caregiver.device === 'hub' : patient.device === 'hub';
}
