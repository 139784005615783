export default {
  "pages": {
    "navbar": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "propeller_logo": "propeller_logo",
                "go_to_my_patients": "go_to_my_patients",
                "show_account_settings_options": "show_account_settings_options",
                "account_settings_dropdown_password_and_account": "account_settings_dropdown_password_and_account",
                "account_settings_dropdown_profile_settings": "account_settings_dropdown_profile_settings",
                "account_settings_dropdown_notification_settings": "account_settings_dropdown_notification_settings",
                "sign_out": "sign_out",
                "show_help_options": "show_help_options",
                "help_dropdown_introductory_guide": "help_dropdown_introductory_guide",
                "help_dropdown_videos_and_webinars": "help_dropdown_videos_and_webinars",
                "help_dropdown_support_center": "help_dropdown_support_center",
                "help_dropdown_faqs": "help_dropdown_faqs",
                "help_dropdown_list_of_medications": "help_dropdown_list_of_medications",
                "help_dropdown_attaching_sensors": "help_dropdown_attaching_sensors",
                "help_dropdown_contact_support": "help_dropdown_contact_support",
                "go_to_notifications": "go_to_notifications",
                "add_patient": "add_patient",
                "clear_search": "clear_search"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "navbar"
    },
    "notification_list": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "show_patient_details": "show_patient_details",
                "resolve_alert": "resolve_alert",
                "reopen_alert": "reopen_alert"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "notification_list"
    },
    "patient_act_cat_score": {
      "label": "patient_act_cat_score"
    },
    "patient_graph": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "two_weeks": "two_weeks",
                "one_month": "one_month",
                "three_months": "three_months",
                "six_months": "six_months",
                "one_year": "one_year",
                "generate_report": "generate_report"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "patient_graph"
    },
    "patient_history_item": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "resolve": "resolve",
                "reopen": "reopen"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "patient_history_item"
    },
    "patient_information": {
      "label": "patient_information"
    },
    "patient_sensor_info_schedule": {
      "label": "patient_sensor_info_schedule"
    },
    "patient_trends": {
      "label": "patient_trends"
    },
    "patient_list": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "sort_by_name": "sort_by_name",
                "patient_names_toggle": "patient_names_toggle",
                "patient_row": "patient_row",
                "sort_by_patientNamesToggle": "sort_by_patientNamesToggle",
                "control-score": "sort_by_control_score",
                "name": "sort_by_name",
                "patient-dob": "sort_by_patient_dob",
                "rescue-baseline  ": "sort_by_rescue_baseline",
                "date-joined": "sort_by_date_joined",
                "rescue-use": "sort_by_rescue_use",
                "adherence-score": "sort_by_adherence_score",
                "last-sync-rescue": "sort_by_last_sync_rescue",
                "last-sync-controller": "sort_by_last_sync_controller",
                "last-contact": "sort_by_last_contact"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "patient_list"
    },
    "patient_search": {
      "label": "patient_search",
      "tracks": {
        "clicked": {
          "label": "clicked",
          "properties": {
            "name": {
              "values": {
                "sort_by_name": "sort_by_name",
                "patient_names_toggle": "patient_names_toggle",
                "patient_row": "patient_row",
                "sort_by_patientNamesToggle": "sort_by_patientNamesToggle",
                "control-score": "sort_by_control_score",
                "name": "sort_by_name",
                "patient-dob": "sort_by_patient_dob",
                "rescue-baseline  ": "sort_by_rescue_baseline",
                "date-joined": "sort_by_date_joined",
                "rescue-use": "sort_by_rescue_use",
                "adherence-score": "sort_by_adherence_score",
                "last-sync-rescue": "sort_by_last_sync_rescue",
                "last-sync-controller": "sort_by_last_sync_controller",
                "last-contact": "sort_by_last_contact"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
        }
      }
    },
    "add_edit_patient_plan": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "show_remove_sensor_modal": "show_remove_sensor_modal",
                "show_new_sensor_modal": "show_new_sensor_modal",
                "show_delete_medication_modal": "show_delete_medication_modal",
                "close_add_edit_medication": "close_add_edit_medication",
                "save_medication": "save_medication",
                "close_add_new_sensor_modal": "close_add_new_sensor_modal",
                "save_new_sensor": "save_new_sensor",
                "close_show_remove_sensor_modal": "close_show_remove_sensor_modal",
                "remove_sensor": "remove_sensor",
                "close_delete_medication_modal": "close_delete_medication_modal",
                "delete_medication": "delete_medication",
                "close_mac_already_used_modal": "close_mac_already_used_modal"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "add_edit_patient_plan"
    },
    "add_hub": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close_add_edit_medication": "close_add_edit_medication",
                "save_medication": "save_medication"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "add_hub"
    },
    "event_details": {
      "label": "event_details"
    },
    "edit_patient_contact": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "add_another_physician": "add_another_physician",
                "close_edit_patient_contact_modal": "close_edit_patient_contact_modal"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "edit_patient_contact"
    },
    "heat_map_view": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close": "close"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "heat_map_view"
    },
    "patient_detail": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "show_more_patient_history": "show_more_patient_history",
                "usage-and-trends": "tab_usage_and_trends",
                "patient-info": "tab_patient_info",
                "notification-history": "tab_notification_history"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        },
        "ping": {
          "properties": {
            "name": {
              "values": {
                "patient_viewed": "patient_viewed",
                "patient_viewed_closed": "patient_viewed_closed"
              },
              "type": "string"
            },
          },
          "label": "ping"
        }
      },
      "label": "patient_detail"
    },
    "popup_blocked_modal": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close_edit_patient_contact_modal": "close_edit_patient_contact_modal"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "popup_blocked_modal"
    },
    "patient_report_requested": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close_edit_patient_contact_modal": "close_edit_patient_contact_modal"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "patient_report_requested"
    },
    "settings_notifications": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "enable_send_daily_digest": "enable_send_daily_digest",
                "disable_send_daily_digest": "disable_send_daily_digest",
                "enable_disable_send_notifications_on_weekends": "enable_disable_send_notifications_on_weekends",
                "disable_send_notifications_on_weekends": "disable_send_notifications_on_weekends"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "settings_notifications"
    },
    "settings_password_and_account": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "submit_new_password": "submit_new_password"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "settings_password_and_account"
    },
    "settings_profile": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "update_profile": "update_profile"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "settings_profile"
    },
    "view_patient_plan": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "edit_medication": "edit_medication",
                "close_view_patient_plan_modal": "close_view_patient_plan_modal",
                "add_medication": "add_medication"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "view_patient_plan"
    },
    "welcome_modal": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close": "close",
                "lets_go": "lets_go"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "welcome_modal"
    },
    "announcement_modal": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "button": "button",
                "close": "close"
              },
              "type": "string"
            },
          },
          "label": "clicked"
        }
      },
      "label": "announcement_modal"
    },
    "contact_information": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close": "close",
                "button": "button"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "contact_information"
    },
    "event_details_modal": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "close": "close"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "event_details_modal"
    },
    "rescue_chart": {
      "tracks": {
        "clicked": {
          "properties": {
            "name": {
              "values": {
                "show_rescue_map_view": "show_rescue_map_view",
                "toggle_night_time": "toggle_night_time",
                "toggle_preemptive": "toggle_preemptive"
              },
              "type": "string"
            },
            "type": {
              "values": {
                "button": "button"
              },
              "type": "string"
            }
          },
          "label": "clicked"
        }
      },
      "label": "rescue_chart"
    }
  }
};
