import angular from 'angular';
import app from '../../app';

import template from './settings-password-and-account.html';

angular.module(app).config(function($stateProvider) {
  $stateProvider.state('settings-password-and-account', {
    parent: 'main',
    url: '/settings/password-and-account',
    template,
    controller: 'SettingsPasswordAndAccountCtrl'
  });
});
