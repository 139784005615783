import React from 'react';
import { func, node, string } from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSref } from '@uirouter/react';
import BsMenuItem from 'react-bootstrap/lib/MenuItem';
import Link from '../Link';

const NavDropdownLink = React.memo(function NavDropdownLink(props) {
  const {
    analyticsPage,
    analyticsLabel,
    children,
    className,
    href,
    onClick,
    onKeyDown,
    onSelect,
    rel,
    target,
    to
  } = props;
  const { href: sref, onClick: srefOnClick } = useSref(to);

  return (
    <BsMenuItem
      className={className}
      componentClass={Link}
      href={href || sref}
      target={target}
      rel={rel}
      onClick={onClick || srefOnClick}
      onKeyDown={onKeyDown}
      onSelect={onSelect}
      analyticsPage={analyticsPage}
      analyticsLabel={analyticsLabel}
    >
      <i className="fa icon-right-dir" aria-hidden="true" />
      {children}
    </BsMenuItem>
  );
});

NavDropdownLink.propTypes = {
  analyticsPage: string.isRequired,
  analyticsLabel: string.isRequired,
  children: node.isRequired,
  className: string,
  onKeyDown: func,
  onSelect: func,
  onClick: func,
  to: string,
  href: string,
  target: string,
  rel: string
};

// useSref needs to take a string and we can't conditionally
// choose not to call it because of hooks rules
NavDropdownLink.defaultProps = {
  className: null,
  href: null,
  onClick: null,
  onKeyDown: null,
  onSelect: null,
  rel: null,
  target: null,
  to: ''
};

export default NavDropdownLink;
