import { useState, useCallback } from 'react';
import useEventListener from '../useEventListener';

export default function useIsOffline() {
  const [isOffline, setIsOffline] = useState(window.navigator.onLine === false);

  // wrap these in useCallback to prevent
  // recreating new functions on every render
  const onlineHandler = useCallback(() => {
    setIsOffline(false);
  }, [setIsOffline]);

  const offlineHandler = useCallback(() => {
    setIsOffline(true);
  }, [setIsOffline]);

  useEventListener('online', onlineHandler, window);
  useEventListener('offline', offlineHandler, window);

  return isOffline;
}
