/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import { bool, func, node, string, oneOf } from 'prop-types';
import classNames from 'classnames';
import analytics from '../../utilities/analytics';

import './styles.less';

// TODO: make a more generic button that this and CircularButton
// (and others?) use, since both are really just stylistic overrides?

// We need to allow the caller to pass in assorted additional properties,
// such as data-* attributes, etc. Hence rest/spread props.
function Button({
  analyticsPage,
  analyticsLabel,
  children,
  className,
  isDisabled,
  onClick,
  size,
  type,
  variant,
  ...rest
}) {
  const handleClick = useCallback(
    (e) => {
      if (!isDisabled && onClick) {
        if (analyticsPage && analyticsLabel) {
          analytics.track('click', {
            action: 'click',
            pageId: analyticsPage,
            labelId: analyticsLabel
          });
        }

        onClick(e);
      }
    },
    [analyticsPage, analyticsLabel, isDisabled, onClick]
  );

  return (
    <button
      aria-disabled={isDisabled}
      className={classNames(`ph-button ph-button--${variant} ph-button--${size}`, className)}
      type={type}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  analyticsPage: string.isRequired,
  analyticsLabel: string.isRequired,
  children: node.isRequired,
  className: string,
  id: string,
  isDisabled: bool,
  onClick: func,
  size: oneOf(['large', 'small', 'xsmall']),
  type: oneOf(['button', 'submit', 'reset']),
  variant: oneOf(['primary', 'secondary', 'danger', 'link'])
};

Button.defaultProps = {
  className: null,
  id: null,
  isDisabled: false,
  onClick: null,
  size: 'large',
  type: 'button',
  variant: 'primary'
};

export default Button;
