import angular from 'angular';
import merge from 'lodash/merge';
import app from '../../app';

/*
  EVENTS SERVICE:
  Service layer for interfacing with the Events api to get and add events
*/

angular.module(app).factory('Events', function($http, $q, urls) {
  const Events = {
    getEvents(options) {
      if (!options) {
        return $q.reject('No options specified');
      }

      const { userId, ...queryOptions } = options;

      if (typeof userId !== 'string') {
        return $q.reject('Bad userId supplied');
      }

      const { source, startDate, endDate, pagingToken } = queryOptions;
      const params = [];

      if (typeof source === 'string') {
        params.push(`source=${source}`);
      }

      if (typeof startDate === 'string') {
        params.push(`startDate=${startDate}`);
      }

      if (typeof endDate === 'string') {
        params.push(`endDate=${endDate}`);
      }

      // If the user specified a paging token ignore the other options
      if (typeof pagingToken === 'string') {
        params.push(`pagingToken=${pagingToken}`);
      }

      const query = params.length > 0 ? `?${params.join('&')}` : '';

      const request = merge(
        {
          url: `${urls.getUsersApi()}/${userId}/events${query}`,
          method: 'GET'
        },
        urls.getRequestDefaults()
      );

      return $http(request).then(response => response.data);
    }
  };

  return Events;
});
