export const ADULT_ACT_AGE = 12;

export const DEFAULT_ADULT_AGE = 16;

export const US_ADULT_AGE = 13;
export const PT_ADULT_AGE = 14;
export const CH_ADULT_AGE = 15;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const PRIVACY_POLICY_URL = 'https://cliniciansupport.propellerhealth.com/s/article/Professional-User-Privacy-Policy-Terms-and-Conditions';
// IE uses different values for some keys
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
// I *think* that React normalizes these across browsers with its virtual events,
// but we need to handle them for angular
export const keys = {
  // modifiers
  shift: 'Shift',

  // whitespace
  enter: 'Enter',
  tab: 'Tab',
  space: ' ',
  spaceBar: 'Spacebar',

  // navigation
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  up: 'Up',
  down: 'Down',
  left: 'Left',
  right: 'Right',

  // editing
  backspace: 'Backspace',

  // ui
  esc: 'Esc',
  escape: 'Escape'
};

export const HUB_PREFIX = 'QUALC001';
