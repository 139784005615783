import moment from '../../services/moment';

export function formatTime(aTime) {
  if (!aTime) {
    return '';
  }

  return moment(aTime, 'H:m').format('LT');
}

export const formatTimeFromParts = ({ hour, minute = 0 }) => formatTime(`${hour}:${minute}`);

export const partsFromTime = (aTime) => {
  const m = moment(aTime, 'LT');

  if (!m.isValid()) {
    return { hour: null, minute: null };
  }

  return {
    hour: m.hour(),
    minute: m.minute()
  };
};

// should this default to 'll'?
export function formatDate(aDate, aFormat = 'DD MMM YYYY') {
  if (!aDate) {
    return '';
  }

  return moment(aDate).format(aFormat);
}
