import angular from 'angular';
import app from '../../app';

import wellControlledSVG from '../../../assets/images/WellControlled.svg';
import notWellControlledSVG from '../../../assets/images/NotWellControlled.svg';
import poorlyControlledSVG from '../../../assets/images/PoorlyControlled.svg';
import learningSVG from '../../../assets/images/learning.svg';
import unknownSVG from '../../../assets/images/unknown.svg';

import template from './asthma-control-score.html';
import './styles.less';

angular.module(app).component('asthmaControlScore', {
  template,
  bindings: {
    score: '<'
  },
  controller() {
    const ctrl = this;

    const condensed = {
      good: {
        src: wellControlledSVG,
        alt: 'PATIENTLISTVIEW-SCORE-good'
      },
      fair: {
        src: notWellControlledSVG,
        alt: 'PATIENTLISTVIEW-SCORE-fair'
      },
      poor: {
        src: poorlyControlledSVG,
        alt: 'PATIENTLISTVIEW-SCORE-poor'
      },
      learning: {
        src: learningSVG,
        alt: 'PATIENTLISTVIEW-SCORE-learning'
      },
      unknown: {
        src: unknownSVG,
        alt: 'PATIENTLISTVIEW-SCORE-unknown'
      }
    };

    ctrl.$onInit = function init() {
      const score = (ctrl.score || 'unknown').toLowerCase();

      ctrl.text = `PATIENTLISTVIEW-SCORE-${score}`;
      ctrl.condensed = condensed[score] || condensed.unknown;
    };
  }
});
