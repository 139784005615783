import angular from 'angular';
import app from '../../app';

const parents = ['patients', 'notifications', 'cerner', 'redox.main'];

const baseState = {
  url: '/patient-detail/:userId',
  lazyLoad($transition$) {
    const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

    return import('../../modules/patient/patient.module').then((mod) =>
      $ocLazyLoad.load(mod.PATIENT_MODULE)
    );
  }
};

angular.module(app).config([
  '$stateRegistryProvider',
  function ($stateRegistry) {
    parents.forEach((parent) => {
      $stateRegistry.register({
        ...baseState,
        name: `${parent}.patient-detail.**`
      });
    });
  }
]);
