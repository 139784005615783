import angular from 'angular';
import merge from 'lodash/merge';

import app from '../../app';

angular.module(app).factory('Hub', function ($q, urls, $http) {
  const QUALCOM_HUB_TYPE = 'QUALCOMM_LIFE_2NET';

  const Hub = {
    validateHub(hub) {
      if (!hub) {
        return $q.reject('No hub to validate');
      }

      const requestUrl = `${urls.getHubsApi()}/validate?externalId=${hub.toUpperCase()}&type=${QUALCOM_HUB_TYPE}`;

      const request = merge(
        {
          url: requestUrl,
          method: 'GET'
        },
        urls.getRequestDefaults()
      );

      return $http(request);
    },

    submitHub(userId, hubId) {
      return urls.request4x(`${urls.getUsersApi()}/${userId}/hubs`, {
        method: 'POST',
        data: {
          externalId: hubId.toUpperCase(),
          type: QUALCOM_HUB_TYPE
        }
      });
    }
  };

  return Hub;
});
