import angular from 'angular';
import app from '../../app';
import { isMissing, invalidString, hasOwn, isEmptyObject } from '../../utilities';
import { formatQueryParams } from '../../utilities/dataSubmission';
import logger from '../../utilities/logger';

angular.module(app).factory('Plan', function (urls, $q, httpRetry500, Group) {
  const Plan = {
    // Adds a new medication to a patient's respiratory plan
    // POST /api/users/{userId}/plan/medication
    addPlanMedication(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, medId, sensors, usageList } = options;

      // require userId
      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      // require medId
      if (invalidString(medId)) {
        return $q.reject('Bad medId supplied');
      }

      // optional sensors array
      if (sensors && !Array.isArray(sensors)) {
        return $q.reject('sensors needs to be in an array');
      }

      // optional usageList array
      if (usageList && !Array.isArray(usageList)) {
        return $q.reject('usageList needs to be in an array');
      }

      const requestUrl = `${urls.getUsersApi()}/${userId}/plan/medication`;

      const request = () =>
        urls.request4x(requestUrl, {
          method: 'POST',
          data: {
            medicationId: medId,
            sensors,
            usageList
          }
        });

      return httpRetry500(request, 5).then((response) => response.data);
    },

    // Deletes a medication from the patient's respiratory plan
    // DELETE /api/users/{userId}/plan/medication/{medId}
    deletePlanMedication(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, medId } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      // require medId
      if (invalidString(medId)) {
        return $q.reject('Bad medId supplied');
      }

      return urls.request4x(`${urls.getUsersApi()}/${userId}/plan/medication/${medId}`, {
        method: 'DELETE'
      });
    },

    // Updates a respiratory plan's medication
    // PATCH /api/users/{userId}/plan/medication/{medId}
    patchPlanMedication(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, medId, sensors, usageList } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      // require medId
      if (invalidString(medId)) {
        return $q.reject('Bad medId supplied');
      }

      // optional sensors array
      if (sensors && !Array.isArray(sensors)) {
        return $q.reject('sensors needs to be in an array');
      }

      // optional usageList array
      if (usageList && !Array.isArray(usageList)) {
        return $q.reject('usageList needs to be in an array');
      }

      const data = {};

      if (sensors) {
        data.sensors = sensors;
      }

      if (usageList) {
        data.usageList = usageList;
      }

      if (isEmptyObject(data)) {
        return $q.resolve('no data to save');
      }

      const requestUrl = `${urls.getUsersApi()}/${userId}/plan/medication/${medId}`;

      return urls
        .request4x(requestUrl, { method: 'PATCH', data })
        .then((response) => response.data)
        .catch((err) => {
          logger.error('Plan service: patch plan error', { error: err });

          throw err;
        });
    },

    // Deletes a sensor from a medication on the patient's respiratory plan
    // DELETE /api/users/{userId}/plan/medication/{medId}/sensor/{mac}
    deletePlanSensor(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      const { userId, medId, mac } = options;

      if (invalidString(userId)) {
        return $q.reject('Bad userId supplied');
      }

      // require medId
      if (invalidString(medId)) {
        return $q.reject('Bad medId supplied');
      }

      // require mac
      if (invalidString(mac)) {
        return $q.reject('Bad mac supplied');
      }

      const requestUrl = `${urls.getUsersApi()}/${userId}/plan/medication/${medId}/sensor/${mac}`;

      return urls
        .request4x(requestUrl, {
          method: 'DELETE'
        })
        .then((response) => response.data);
    },

    getSensorAvailability(options) {
      if (isMissing(options)) {
        return $q.reject('No options specified');
      }

      if (isMissing(options.mac)) {
        return $q.reject('Bad mac supplied');
      }

      // allow legacy options.medId, which is coerced to medicationId
      const allowedParams = ['mac', 'medicationId', 'medId', 'sensorFamily'];

      const queryOptions = allowedParams.reduce((acc, key) => {
        if (hasOwn(options, key)) {
          const queryKey = key === 'medId' ? 'medicationId' : key;
          acc[queryKey] = options[key];
        }
        return acc;
      }, {});

      const requestUrl = `${urls.getUsersApi()}/search/sensor?${formatQueryParams(queryOptions)}`;

      return urls.request4x(requestUrl).then((response) => response.data);
    },

    // does this need other things like disease?
    requiresStrictDosing(medicationId) {
      if (!Group.config) throw new Error('Group service has not been initialized');

      const strictList = Group.config.strictDoseMedicationList;
      return Boolean(strictList) && Boolean(medicationId) && strictList.indexOf(medicationId) > -1;
    },

    // does this need other things like disease?
    requiredDosing(medicationId) {
      const medication = Group.medicationDict[medicationId];

      if (!medication) throw new Error(`Cannot find medication for medicationId "${medicationId}"`);

      const doseRange = medication.doseRanges[0];

      return {
        dosesPerDay: doseRange.administrationsPerDay.min,
        puffsPerDose: doseRange.unitDosesPerAdministration.min
      };
    },

    requiredDoseMessage(medicationId) {
      if (medicationId.indexOf('ultibro_breezhaler') !== -1) {
        return 'DOSE_SCHEDULE_MESSAGE';
      }

      return 'STRICT_SCHEDULE_MESSAGE';
    }
  };

  return Plan;
});
