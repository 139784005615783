/* eslint-disable default-param-last */
import {
  SET_GROUP_CONFIG,
  SET_GROUP_CLINICIANS,
  SET_GROUP_CONFIG_4X,
  SET_SUBGROUPS
} from '../../actions';

export function groupConfig(state = null, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG: {
      return action.groupConfig;
    }

    default:
      return state;
  }
}

export function groupConfig4x(state = null, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG_4X:
      return action.config;

    default:
      return state;
  }
}

export function groupClinicians(state = [], action) {
  switch (action.type) {
    case SET_GROUP_CLINICIANS:
      return action.clinicians;

    default:
      return state;
  }
}

export function groupSubGroups(state = [], action) {
  switch (action.type) {
    case SET_SUBGROUPS:
      return action.subgroups;

    default:
      return state;
  }
}

export function medicationsDictionary(state = {}, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG:
      if (!action.groupConfig || !action.groupConfig.medications) {
        return state;
      }

      return action.groupConfig.medications.reduce((acc, med) => {
        acc[med.id] = med;
        return acc;
      }, {});

    default:
      return state;
  }
}
