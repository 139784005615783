import angular from 'angular';
import app from '../../app';

import template from './template.html';
import './styles.less';

angular.module(app).component('notificationBadge', {
  template,
  bindings: {
    count: '<'
  }
});
