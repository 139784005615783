import angular from 'angular';

import app from '../../app';
import { hasOwn } from '../../utilities';
import template from './adherence-bar.html';
import './adherence-bar.less';

angular.module(app).component('adherenceBar', {
  template,
  bindings: {
    patient: '<'
  },
  controller: function AdherenceBarController() {
    const ctrl = this;

    ctrl.$onInit = function init() {
      const { lastWeekController, sync, event } = ctrl.patient;
      const firstSync = sync?.firstController;
      const firstEvent = event?.firstController;
      const adherence = lastWeekController?.adherence;
      const medications = lastWeekController?.medications || [];

      // Filter out meds without a `puffsPercent` property.
      // They're likely on the plan but not active.
      // Coerce `null` puffsPercent to `0`.
      // Nulls are probably an artifact of sensors that haven't
      // synced yet. At this time we're not displaying anything
      // relevant to that on an individual medication basis.
      ctrl.medications = medications
        .filter((med) => hasOwn(med, 'puffsPercent'))
        .map((med) => ({
          ...med,
          puffsPercent: med.puffsPercent || 0
        }));

      // Currently It appears the medications are in order of
      // when they were added to the plan. I think it makes
      // sense to sort these by name, but we're not sorting
      // other places we display the list. For now, keep the
      // default sorting and consider changing across the board.

      // .sort((a, b) => a.name - b.name);

      const hasMeds = ctrl.medications.length > 0;
      // hasUsages is to work around cases with manually tracked meds.
      // If there's no sensor on controller meds, then firstSync won't exist.
      // Currently firstEvent is also not set if there are no sensored controller meds.
      // Work is queued on the platform to set firstEvent even for manual meds,
      // so we should be able to remove this check in the near future.
      const hasUsages = ctrl.medications.some((med) => Boolean(med.puffsPercent));
      const mayShowMeds = hasMeds && (firstSync || firstEvent || hasUsages);
      const quietSensor = adherence?.interpretation === 'quietSensor';

      ctrl.notApplicable = !mayShowMeds;
      ctrl.inactive = mayShowMeds && quietSensor;
      ctrl.showAdherence = mayShowMeds && !quietSensor;
    };
  }
});
