import angular from 'angular';

import app from '../../app';
import { isResponseObject, responseForLog } from '../../utilities';

const noop = () => {};

function isError(anObject) {
  return anObject instanceof Error;
}

function formatError(anError) {
  // Errors don't have a toJSON method and so can't be serrialized by JSON.stringify
  const errorObject = {};

  Object.getOwnPropertyNames(anError).forEach(function(key) {
    errorObject[key] = anError[key];
  });

  // stack is not always enumberable via getOwnPropertyNames
  // eg. on FF it's on the prototype, not the instance
  if (!errorObject.stack) {
    errorObject.stack = anError.stack;
  }

  return errorObject;
}

angular.module(app).service('Logger', [
  '$window',
  '$log',
  'env',
  function($window, $log, env) {
    const $http = angular.injector(['ng']).get('$http');
    const logLevel = env.get('logLevel') || 'debug';

    const LEVELS = { error: 0, warn: 1, info: 2, debug: 3 };
    const LEVEL_KEYS = Object.keys(LEVELS);

    const configuredLevel = LEVELS[logLevel];

    function log(level) {
      if (!LEVEL_KEYS.includes(level)) {
        level = 'info';
      }

      if (LEVELS[level] > configuredLevel) {
        return noop;
      }

      return function _log(message, info = {}) {
        const { hostname, pathname } = $window.location;
        const TLD = hostname.split('.').pop();

        try {
          // there's a lot of inconsistency around things we may want to log from
          if (typeof info === 'string') {
            info = { infoMessage: info };
          } else if (isError(info)) {
            info = { error: formatError(info) };
          } else if (isResponseObject(info)) {
            info = responseForLog(info);
          }

          const content = {
            level,
            message,
            info,
            time: new Date().toISOString(),
            context: {
              host: hostname,
              path: pathname,
              userAgent: $window.navigator.userAgent
            }
          };

          if (['localhost', 'biz'].includes(TLD)) {
            const write = $log[level] || $log.info;
            write(content);
          }

          return $http.post('/logger', content).catch(noop);
        } catch (e) {
          $log.error('Exception trying to log error on client', e);
          return $http
            .post('/logger', {
              level: 'error',
              message: 'Exception trying to log error on client',
              time: new Date().toISOString(),
              info: {
                message,
                info
              },
              context: {
                host: hostname,
                path: pathname,
                userAgent: $window.navigator.userAgent
              }
            })
            .catch(noop);
        }
      };
    }

    return {
      error: log('error'),
      warn: log('warn'),
      info: log('info'),
      log: log('info'),
      debug: log('debug')
    };
  }
]);
