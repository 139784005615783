import angular from 'angular';
import app from '../../app';

import template from './settings-profile.html';

angular.module(app).config(function($stateProvider) {
  $stateProvider.state('settings-profile', {
    parent: 'main',
    url: '/settings/profile',
    template,
    controller: 'SettingsProfileCtrl'
  });
});
