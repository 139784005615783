import './utilities/polyfills';
import 'jquery/dist/jquery.slim';
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngAria from 'angular-aria';
import ngMessages from 'angular-messages';
import ngInfiniteScroll from 'ng-infinite-scroll';
import ngCookies from 'angular-cookies';
import ngSanitize from 'angular-sanitize';
import { UI_ROUTER_REACT_HYBRID } from '@uirouter/react-hybrid';
import modal from 'angular-ui-bootstrap/src/modal';
import angulartics from 'angulartics';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-partial'; // isn't directly referenced
import ngRedux from 'ng-redux';
import ocLazyLoad from 'oclazyload';

import moment from './services/moment';
import { defaultLocale } from './services/locales';
import store from './store';

import {
  capitalize,
  displayName,
  displayInitials,
  responseForLog,
  trendTypeKey
} from './utilities';
import { isMedType, hasSchedule } from './utilities/medications';
import { formatDate, formatTime } from './utilities/dateTime';

import './app.less';

const MODULE_NAME = 'enterpriseDashboardApp';

angular
  .module(MODULE_NAME, [
    ngAnimate,
    ngAria,
    ngCookies,
    ngMessages,
    ngSanitize,
    ngRedux,
    UI_ROUTER_REACT_HYBRID,
    modal,
    ngInfiniteScroll,
    angulartics,
    ngTranslate,
    ocLazyLoad
  ])
  .factory('$exceptionHandler', [
    'Logger',
    function (Logger) {
      return function exceptionHandler(exception, cause) {
        const message = cause || exception.toString();
        Logger.error(message, exception);
      };
    }
  ])
  .factory('env', function ($window) {
    // eslint-disable-next-line no-underscore-dangle
    const env = { ...$window.__env };

    return {
      get(key) {
        return env && env[key];
      }
    };
  })
  .service('callOnce', [
    '$q',
    function ($q) {
      return function callOnce(promiseFunc) {
        let promise;
        let isCalling = false;

        // this doesn't do any argument checking,
        // so functions wrapped in it called quickly in a row
        // with different arguments will resolve with the first call's result.

        // Really, this should only be used in cases where some artifact (eg ui-router resolves)
        // is nearly guaranteed to cause duplicate parallel calls for the same state.
        return function wrappedPromise(...args) {
          if (!isCalling) {
            isCalling = true;
            promise = $q((resolve, reject) => {
              promiseFunc(...args)
                .then(resolve)
                .catch(reject)
                .then(() => {
                  isCalling = false;
                });
            });
          }

          return promise;
        };
      };
    }
  ])
  .service('httpRetry500', [
    '$q',
    '$timeout',
    function ($q, $timeout) {
      function is5xx(status) {
        return status >= 500 && status <= 599;
      }

      return function httpRetry500(func, limit = 5) {
        if (typeof limit !== 'number') {
          throw new Error('Invalid argument for "times". Requires a number');
        }

        return $q(function (resolve, reject) {
          function attemptRequest(attempts) {
            func()
              .then(resolve)
              .catch((err) => {
                if (is5xx(err.status) && attempts < limit) {
                  $timeout(() => {
                    attemptRequest(attempts + 1);
                  }, attempts * 50);
                } else {
                  reject(err);
                }
              });
          }

          attemptRequest(1);
        });
      };
    }
  ])
  .config(function ($urlServiceProvider, $locationProvider, $httpProvider, $ngReduxProvider) {
    $urlServiceProvider.config.strictMode(false);
    $urlServiceProvider.rules.otherwise('/');
    $locationProvider.html5Mode(true);

    $httpProvider.interceptors.push(function ($rootScope, $q, debounce, Logger) {
      const debouncedReset = debounce(() => {
        $rootScope.$broadcast('resetSessionExpiration');
      }, 1000);

      function isPhAPI(headers) {
        return headers['x-ph-api-version'];
      }

      function notSignIn(url) {
        return !/api\/users\/signIn$/.test(url);
      }

      function notSignOut(url) {
        return !/api\/users\/signOut$/.test(url);
      }

      function validStatus(status) {
        return status >= 200 && status < 400;
      }

      return {
        response(res) {
          const { status, config } = res;
          const { headers, url } = config;
          // a signout request returns 204, but we don't want
          // that to tell other tabs to stay signed in
          if (isPhAPI(headers) && notSignOut(url) && validStatus(status)) {
            debouncedReset();
          }

          return res;
        },

        responseError(res) {
          if (res.status === 401) {
            $rootScope.$broadcast('sessionInvalid');
          } else if (notSignIn(res.config.url)) {
            // not logging request body for now
            // also not logging 401s or sign ins
            Logger.warn('HTTP response error', responseForLog(res));
          }

          return $q.reject(res);
        }
      };
    });

    $ngReduxProvider.provideStore(store);
  })
  .filter('tel', function () {
    return function (tel) {
      // this doesn't seem very robust or valid...
      if (!tel) {
        return '';
      }

      const value = tel.toString().trim().replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
        return tel;
      }

      let country;
      let city;
      let number;

      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;

        case 11: // +CPPP####### -> CCC (PP) ###-####
          // eslint-disable-next-line prefer-destructuring
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;

        case 12: // +CCCPP####### -> CCC (PP) ###-####
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          number = value.slice(5);
          break;

        default:
          return tel;
      }

      if (country === 1) {
        country = '';
      }

      number = `${number.slice(0, 3)}-${number.slice(3)}`;

      return `${country} (${city}) ${number}`.trim();
    };
  })
  .filter('mac', function () {
    return function (mac) {
      if (!mac) {
        return '';
      }

      const l = mac.toString().trim().replace(/:/g, '').length;

      return mac
        .toString()
        .trim()
        .replace(/:/g, '')
        .slice(l - 6);
    };
  })
  .filter('macColons', function () {
    return function (mac) {
      if (!mac) {
        return '';
      }

      if (mac.length !== 6) {
        return '';
      }

      return `${mac.substring(0, 2)}:${mac.substring(2, 4)}:${mac.substring(4)}`;
    };
  })
  .filter('time', function () {
    return formatTime;
  })
  .filter('date', function () {
    return formatDate;
  })
  .filter('dateTime', function () {
    return function (date, timezone) {
      // should this use 'll'?
      return moment(date).tz(timezone).format('DD MMM YYYY');
    };
  })
  .filter('toPascal', function () {
    return function (text) {
      return text
        .toString()
        .split(/[\s-_]/)
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join('');
    };
  })
  .filter('displayName', function () {
    return displayName;
  })
  .filter('displayInitials', function () {
    return displayInitials;
  })
  .filter('capitalize', function () {
    return function (text) {
      if (!text || typeof text !== 'string') {
        return text;
      }

      return capitalize(text);
    };
  })
  .filter('toTrendLabel', function () {
    return function (word, type) {
      return trendTypeKey(type)(word);
    };
  })
  .filter('medsOfType', function ($log) {
    return function (anArray, aType) {
      if (!Array.isArray(anArray)) {
        $log.warn('medsOfType provided with non-array', anArray);
        return [];
      }

      if (typeof aType !== 'string') {
        $log.warn('medsOfType provided with non-string type', aType);
        return anArray;
      }

      return anArray.filter(isMedType(aType));
    };
  })
  .filter('withSchedule', function ($log) {
    return function (anArray) {
      if (!Array.isArray(anArray)) {
        $log.warn('withSchedule provided with non-array', anArray);
        return [];
      }

      return anArray.filter(hasSchedule);
    };
  })
  .filter('withoutSchedule', function ($log) {
    return function (anArray) {
      if (!Array.isArray(anArray)) {
        $log.warn('withoutSchedule provided with non-array', anArray);
        return [];
      }

      return anArray.filter((item) => !hasSchedule(item));
    };
  })
  .filter('trustHtml', ($sce) => (html) => $sce.trustAsHtml(html))
  .service('misc', function ($window, $rootScope, $timeout) {
    $window.addEventListener('online', function () {
      $timeout(function () {
        $rootScope.$broadcast('fetchAll');
      });
    });
  })
  .service('debounce', function ($timeout) {
    return function debounce(func, wait, immediate) {
      let timeout;

      return function debounced(...args) {
        const context = this;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        if (timeout) $timeout.cancel(timeout);

        timeout = $timeout(later, wait);

        if (callNow) func.apply(context, args);
      };
    };
  })
  .directive('documentLanguage', function ($rootScope) {
    return {
      link(scope, element) {
        $rootScope.$on('$translateChangeSuccess', function (event, translationResp) {
          const currentLang = translationResp.language;
          element.prop('lang', currentLang || defaultLocale);
        });
      }
    };
  })
  .constant('GENDER', {
    female: 'Female',
    male: 'Male'
  })
  .constant('USSTATES', {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
  })
  .run([
    '$rootScope',
    '$http',
    '$translate',
    function ($rootScope, $http, $translate) {
      function updateLocaleSettings(lang) {
        moment.locale(lang);
        $http.defaults.headers.common['Accept-Language'] = lang;
      }

      $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
        return $translate.refresh();
      });

      $rootScope.$on('$translateChangeEnd', function () {
        return updateLocaleSettings($translate.use());
      });

      updateLocaleSettings($translate.preferredLanguage());
    }
  ]);

export default MODULE_NAME;
