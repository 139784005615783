/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import angular from 'angular';
import app from '../../app';
import $sessionStorage from '../../utilities/storage/sessionStorage';

angular.module(app).service('urls', function ($window, $cookies, $http, env, Auth, Logger) {
  let domain = '';
  const apiTimeout = 30 * 1000; // time in milleseconds we should wait to abort a request

  if (!$window.location.origin) {
    const { protocol, hostname, port } = $window.location;
    const _port = port ? `:${port}` : '';

    $window.location.origin = `${protocol}//${hostname}${_port}`;
  }

  function setDomain(url) {
    domain = url;
    $sessionStorage.set('apiUrl', url);
  }

  // If the env variable is available, set our domain to it and remember it in case we lose connection to the node
  // server where it comes from
  const apiUrl = env.get('apiUrl');
  if (apiUrl) {
    setDomain(apiUrl);
  } else if (typeof $sessionStorage.get('apiUrl') === 'string') {
    // If the env variable is not available, get the domain from our previously remembered domain.
    domain = $sessionStorage.get('apiUrl');
  } else {
    // Otherwise just use the window's location.origin var to set the domain
    setDomain($window.location.origin);
  }

  if (domain.indexOf('localhost') >= 0) {
    $cookies.put('propellerApiDomain', 'localhost');
  } else {
    // remove the protocol from the domain
    $cookies.put('propellerApiDomain', domain.slice(domain.indexOf('://') + 3, domain.length));
  }

  this.getDomain = function () {
    return domain;
  };

  this.getUsersApi = function () {
    return `${domain}/api/users`;
  };

  this.getPatientsApi = function () {
    return `${domain}/api/patients`;
  };

  this.getGroupsApi = function () {
    return `${domain}/api/groups`;
  };

  this.getMedsApi = function () {
    return `${domain}/api/meds`;
  };

  this.getCardsApi = function () {
    return `${domain}/api/cards`;
  };

  this.getProviderApi = function () {
    return `${domain}/api/provider`;
  };

  this.getMedicationsApi = function () {
    return `${domain}/api/meds`;
  };

  this.getHubsApi = function () {
    return `${domain}/api/hubs`;
  };

  this.getPatientPortalURL = function () {
    const tld = domain.split('.').pop();
    return `https://patient.propellerhealth.${tld}`;
  };

  this.request4x = function (url, options = {}) {
    const { headers = {}, ...restOptions } = options;

    return Auth.updateToken()
      .catch((err) => {
        Logger.error('Token update error', err);
        throw err;
      })
      .then((token) =>
        $http({
          url,
          headers: {
            'x-ph-api-version': '4.74.0',
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...headers
          },
          timeout: apiTimeout,
          ...restOptions
        })
      );
  };

  this.getRequestDefaults = function () {
    return {
      timeout: apiTimeout,
      withCredentials: true,
      headers: {
        'x-ph-api-version': '3.97.0'
      }
    };
  };

  this.get2xRequestDefaults = function () {
    return {
      timeout: apiTimeout,
      withCredentials: true,
      headers: {
        'x-ph-api-version': '2.21.0'
      }
    };
  };
});
